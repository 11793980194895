import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from 'react'
import ModalContext from '../../Context/Modal/ModalContext'
import Carousel from './Carousel'
import ProductContext from '../../Context/Products/ProductsContext'

export default function Modal() {


    const { state, closeModal, open } = useContext(ModalContext)
    const { addProdCurrentOrder } = useContext(ProductContext)
    const [slider, setSlider] = useState(false)

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                setSlider(true)
            }, 200);
            return () => clearTimeout(timer)
        } else if (!open) {
            setSlider(false)
        }
    }, [open])

    const closeAndAdd = (product) => {
        addProdCurrentOrder(product)
        closeModal()
    }



    return (
        <>
            {
                state.selectedProd ?
                    <Transition.Root show={state.open} as={Fragment}>
                        <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={state.open} onClose={closeModal}>
                            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                        {slider ?
                                            <Carousel data={state.selectedProd.images} />
                                            :
                                            null
                                        }
                                        <div>

                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-left text-gray-900">
                                                    {state.selectedProd.price} mxn
                                                </Dialog.Title>
                                                <Dialog.Title as="h3" className="text-lg leading-6  text-left text-gray-900">
                                                    {state.selectedProd.name}
                                                </Dialog.Title>
                                                <div className="mt-5 sm:mt-6 flex flex-wrap content-center ">
                                                    <button
                                                        type="button"
                                                        onClick={() => closeModal()}
                                                        className=" px-4 py-1 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none "
                                                    >
                                                        A la próxima
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className=" rounded-md border border-transparent shadow-sm px-4 mx-2 py-1 bg-yellow-400 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:text-sm"
                                                        onClick={() => closeAndAdd(state.selectedProd)}
                                                    >
                                                        Lo quiero
                                                    </button>
                                                </div>
                                                <div className="mt-2">
                                                    <p className="text-xs text-left text-gray-500">
                                                        {state.selectedProd.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition.Root>
                    :
                    null
            }
        </>
    )
}
