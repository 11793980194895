import { Fragment, useContext, useEffect, useState } from 'react'
import AuthContext from '../../Context/Auth/AuthContext'
import MakePayment from './MakePayment'
import Divider from "../microComponents/Divider"
import DateTime from './DateTime'
import ProductContext from '../../Context/Products/ProductsContext'
import ModalContext from '../../Context/Modal/ModalContext'
import { useQuery } from '@apollo/client'
import { GET_USERS_ADDRESS } from '../../GraphQL/Queries'
import Loader from '../microComponents/Loader'
import ExistingClientData from '../Users/Pro/ExistingClientData'


export default function CheckOut({ total, type, delivery, deleteCart , idCompany}) {
    const [dateF, setDateF] = useState([])
    const [hour, setHour] = useState([])
    const [address, setAddress] = useState({
        listOfAddress: null,
        selected: [{
            id: "",
            city: "",
            colonia: "",
            default: "",
            extras: "",
            fullAdress: "",
            name: "",
            phoneNumber: "",
            postalCode: "",
            state: "",
            street: "",

        }],
        selectedIndex: null
    })
    const { logged, user, newUser } = useContext(AuthContext)
    const { date } = useContext(ProductContext)
    const { switchCard, addressFromDB, setNewAdressCheck, newAddressCheck } = useContext(ModalContext)
    const [costoenvio, setCostoEnvio] = useState(0)
    const { loading, data, refetch } = useQuery(GET_USERS_ADDRESS, { variables: { username: user } })

    const dates = () => {
        const heute = new Date()
        const week = [];

        for (var i = 0; i < 8; i++) {

            if (heute.getDay() !== 0) {
                week.push(
                    new Date(heute)
                )
            }

            heute.setDate(heute.getDate() + 1)
        }

        const weekday = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
        const month = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

        const formatDate = week.map((day, id) => {

            return {
                id: id,
                day: weekday[day.getDay()] + " " + day.getDate() + " " + month[day.getMonth()],
                online: true,
                dayData: day
            }
        })


        if (heute.getHours() + (heute.getMinutes() / 100) >= 17) {
            const late = formatDate.filter(e => e.id !== 0)
            setDateF(late)
        } else {
            setDateF(formatDate)
        }



    }

    const time = () => {

        const heute = new Date()

        const hours = [
            { id: 0, day: "9:30", online: true },
            { id: 1, day: "10:00", online: true },
            { id: 2, day: "10:30", online: true },
            { id: 3, day: "11:00", online: true },
            { id: 4, day: "11:30", online: true },
            { id: 5, day: "12:00", online: true },
            { id: 6, day: "12:30", online: true },
            { id: 7, day: "13:00", online: true },
            { id: 8, day: "13:30", online: true },
            { id: 9, day: "14:00", online: true },
            { id: 10, day: "14:30", online: true },
            { id: 11, day: "15:00", online: true },
            { id: 12, day: "15:30", online: true },
            { id: 13, day: "16:00", online: true },
            { id: 14, day: "16:30", online: true },
        ]

        const saturday = [
            { id: 0, day: "9:30", online: true },
            { id: 1, day: "10:00", online: true },
            { id: 2, day: "10:30", online: true },
            { id: 3, day: "11:00", online: true },
            { id: 4, day: "11:30", online: true },
            { id: 5, day: "12:00", online: true },

        ]


        if (date !== null) {

            if (heute.getDate() === date.dayData.getDate() && heute.getDay() !== 6) {

                if (heute.getHours() + (heute.getMinutes() / 100) >= 17) {
                    const filtered = hours.filter(element => {
                        return parseInt(element.day) > heute.getHours()
                    })
                    setHour(filtered)
                } else {
                    const filtered = hours.filter(element => {
                        return parseInt(element.day) > heute.getHours() + .5
                    })
                    setHour(filtered)
                }
            } else if (date.dayData.getDay() === 6) {

                if (heute.getHours() + (heute.getMinutes() / 100) >= 12) {
                    const filtered = saturday.filter(element => {
                        return parseInt(element.day) > heute.getHours()
                    })
                    setHour(filtered)
                } else {
                    const filtered = saturday.filter(element => {
                        return parseInt(element.day) > heute.getHours() + .5
                    })
                    setHour(filtered)
                }
            } else {
                setHour(hours)
            }
        }
        else {
            const filtered = hours.filter(element => {
                return parseInt(element.day) > heute.getHours()
            })
            setHour(filtered)
        }

    }



    useEffect(() => {
        if (user !== null) {
            refetch()
        }
        if (data !== undefined) {
            const index = data.users[0].location.findIndex(e => e.default === true)
            setAddress({
                listOfAddress: data.users[0].location.map(e => e),
                selected: data.users[0].location[index],
                selectedIndex: index
            })
        }

    }, [data, user])

    useEffect(() => {
        if (type === "product") {
            if (delivery !== undefined) {
                setCostoEnvio(delivery.deliveryPrice)
            }
        }
    }, [])

    useEffect(() => {
        refetch()
        if (newAddressCheck) {
            refetch()
            const timer = setTimeout(() => {
                setNewAdressCheck(false)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [addressFromDB, user, newAddressCheck])



    useEffect(() => {
        dates()
        time()
    }, [date])



    return (
        <>

            {
                !loading ?

                    <div>


                        {
                            logged && !newUser ?
                                <>
                                    {
                                        type === "service" ?
                                            <>
                                                <div className='px-2'>

                                                <DateTime people={dateF} title={"Día de servicio"} type={"date"} />
                                                <DateTime people={hour} title={"Horario de servicio"} type={"time"} />
                                                <Divider />
                                                </div>
                                            </>
                                            :
                                            null
                                            // <>
                                            //     {
                                            //         delivery !== undefined ?
                                            //             <>
                                            //                 <div className="text-sm px-2 flex my-4 flex-row ">
                                            //                     <p className="font-bold text-gray-600">
                                            //                         Tiempo de entrega :
                                            //                     </p>
                                            //                     <span className='text-gray-500 space-x-1 ml-2'> {delivery.timeDelivery.quantity} {delivery.timeDelivery.unit}</span>
                                            //                 </div>
                                            //                 <Divider />
                                            //             </>
                                            //             :

                                            //             null
                                            //     }
                                            // </>

                                    }

                                    {
                                        !loading ?

                                            !idCompany.company ? 
                                            <div
                                                className="flex text-sm px-2 my-4 cursor-pointer"
                                                onClick={() => switchCard("address", address)}>
                                                <div className="mr-2 flex-shrink-0 font-bold">
                                                    <p className="mt-1 font-body text-blue-600 underline">
                                                        Dirección :
                                                    </p>
                                                </div>{

                                                    address.selected === undefined ?

                                                        <p>Cargando ubicaciones</p>
                                                        :

                                                        <div className="overflow-hidden ">
                                                            <p className="mt-1  font-body">
                                                                {address.selected.name} , <span className="text-gray-500">{address.selected.street} , Colonia: {address.selected.colonia}</span>
                                                            </p>
                                                        </div>
                                                }

                                            </div>
                                            :
                                            <div className='px-2 mt-2'>
                                            <p className="text-sm">

                                            Selecciona un cliente
                                            </p>
                                            {/* aqui va el "form" para seleccionar el cliente o para añadir el nuevo */}
                                            <ExistingClientData />
                                                </div>
                                            
                                            :

                                            <p>Cargando ubicaciones</p>
                                    }


                                    {
                                        !idCompany.company ?
                                        <div>
                                            
                                    <div className="flex text-sm px-2 mt-4">
                                        <div className="mr-2 flex-shrink-0 ">
                                            <p className="mt-1 font-body">
                                                Subtotal :
                                            </p>

                                        </div>
                                        <div className="overflow-hidden ">
                                            <p className="mt-1 font-body">
                                                $ {total !== undefined ? total.toLocaleString() : null}
                                            </p>
                                        </div>

                                    </div>
                                    <div className="flex text-sm px-2">
                                        <div className="mr-2 flex-shrink-0 ">
                                            <p className="font-body">
                                                Costo de envío :
                                            </p>

                                        </div>
                                        <div className="overflow-hidden ">
                                            <p className="font-body">
                                                $ {costoenvio}
                                            </p>
                                        </div>

                                    </div>
                                    <div className="flex text-sm px-2 mb-4">
                                        <div className="mr-2 flex-shrink-0 font-bold">
                                            <p className="mt-1 font-body">
                                                Total :
                                            </p>

                                        </div>
                                        <div className="overflow-hidden font-bold">
                                            <p className="mt-1 font-body">
                                                $ {total !== undefined ? (total + costoenvio).toLocaleString() : null}
                                            </p>
                                        </div>

                                    </div>
                                        </div>
                                        : 
                                        null
                                    }


                                    <div className='px-2 w-full'>
                                    <MakePayment total={total + costoenvio} deliveryPrice={costoenvio} address={address} type={type} deleteCart={deleteCart} idCompany={idCompany}/>
                                    </div>

                                </>
                                :

                                null
                        }
                    </div>
                    :
                    <div className='mx-auto py-12'>
                    <Loader></Loader>
                    </div>
            }



        </>


    )
}

