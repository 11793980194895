import { useEffect, useState } from 'react'
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/solid'
import React from 'react'
import { useContext } from 'react'
import ModalContext from '../../Context/Modal/ModalContext'
import { Link } from 'react-router-dom'
import axios from 'axios'
import SERVER_ROUTE from '../../Services/paths'
import Loader from '../microComponents/Loader'


export default function SelectCard() {

    const { setNewCard } = useContext(ModalContext)
    const [cards, setCards] = useState([{
        "loading": true,
        "id": "...",
        "brand": "...",
        "last": "...",
        "expM": "...",
        "expY": "...",
        "noCards": true
    }])


    useEffect(() => {

        const listCards = async () => {

            const config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }

            try {

                const res = await axios.get(SERVER_ROUTE + "/cards", config)
                if (res.data === "new user") {


                } else if (res.data.paymentMethods.data.length === 0) {
                    setCards([{
                        "loading": false,
                        "id": "...",
                        "brand": "...",
                        "last": "...",
                        "expM": "...",
                        "expY": "...",
                        "noCards": true
                    }])

                } else {
                    setCards(res.data.paymentMethods.data.map((e, index) => {
                        return {
                            "idMap": index,
                            "idPm": e.id,
                            "idEntity": res.data.entity._id,
                            "brand": e.card.brand,
                            "last": e.card.last4,
                            "expM": e.card.exp_month,
                            "expY": e.card.exp_year,
                            "noCards": false
                        }
                    }))
                }


            } catch (error) {
                const err = error
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                }

            }

        }
        listCards()
    }, [])




    const { switchCard, closeCardOverlay } = useContext(ModalContext)

    const sendToCheckOut = async (ev) => {

        try {
            setNewCard(true)
            setCards([{
                ...cards,
                "loading": true
            }])
            const res = await axios.put(SERVER_ROUTE + `/cards/${ev.idEntity}`, { "new": ev.idPm, "last4": ev.last, "brand": ev.brand })
            if (res.status === 200) {
                switchCard("checkout")
            }
            // desactivar todo para que no le piquen a nada

        } catch (e) {
            console.log(e)

        }
    }

    return (
        <div className="font-body">
            <div
                onClick={() => switchCard("checkout")}
                className="px-6 pb-6">
                <div className="inline-flex align-middle">
                    <ArrowLeftIcon
                        className="text-gray-400 w-6 mr-2"
                    />
                    <p className="font-body text-sm" >Regresar</p>
                </div>
            </div>





            {
                cards[0].loading ?
                    <div className="text-center my-10">
                        <Loader />
                    </div>
                    :
                    cards[0].noCards ?
                        null
                        :

                    cards.map((e, index) => {
                        return <div
                            key={index}
                            className="px-6 py-2">
                            <button

                                onClick={() => sendToCheckOut(e)}
                                className="border-2 border-gray-100 px-4 py-2 rounded-lg relative block w-full text-left">
                                <p className="font-semibold">{e.brand.charAt(0).toUpperCase() + e.brand.slice(1)}
                                    <span className="text-sm ml-2">
                                        ****{e.last}
                                    </span>
                                </p>
                                <p className="text-sm">Vence el {e.expM}/{e.expY}</p>
                            </button>
                        </div>
                    })


            }

            <div className="px-6">

                <Link to={"/nueva/tarjeta"}>
                    <button
                        type="button"
                        onClick={closeCardOverlay}
                        className="relative block w-full border-2 border-gray-200 border-dashed rounded-lg py-2 text-center hover:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    >
                        <span className="flex justify-center">
                            <PlusIcon className="w-4"></PlusIcon>
                        </span>

                        <span className="mt-2 block text-sm font-medium text-gray-900">
                            <p>
                                Agregar nueva tarjeta
                            </p>
                        </span>
                    </button>

                </Link>
            </div>

        </div>
    )
}


