import axios from 'axios'
import React, { useState, Fragment, useEffect } from 'react'
import COLONIAS_POSTAL from '../../Services/API'
import Alert from '../microComponents/Alert'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XCircleIcon } from '@heroicons/react/solid'
import { useContext } from 'react'
import AuthContext from '../../Context/Auth/AuthContext'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { GET_USERS_ADDRESS } from '../../GraphQL/Queries'
import { SET_DEFAULT_ADDRESS } from '../../GraphQL/Mutations'
import ModalContext from '../../Context/Modal/ModalContext'


export default function AddNewAddress({setNewAddressAdmin , addNewAddressAdmin , setnewAddressCreated}) {

    const { AddNewAddressContext, preUser, user, logged, newUser, newUserTrue } = useContext(AuthContext)
    const { switchCard } = useContext(ModalContext)
    const history = useHistory()
    const [usePreuser, SetUsePreuser] = useState(true)
    const [postalCode, setPostalCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [colonias, setColonias] = useState("")
    const [selected, setSelected] = useState("")
    const [newAddress, setNewAddress] = useState({
        name: "",
        street: "",
        phoneNumber: "",
        postalCode: " ",
        colonia: "",
        city: "",
        state: "",
        extras: "",
        default: false
    })
    const [alert, setAlert] = useState({
        open: false,
        color:"red",
        error: "Ingresa un código postal"
    })

    console.log(newUser)
    const { data } = useQuery(GET_USERS_ADDRESS, { variables: { username: user } })

    const [SetDefault] = useMutation(SET_DEFAULT_ADDRESS)

    useEffect(() => {
        if (!logged) {
            history.push("/")
        }
    }, [logged])


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const getPostalCode = async () => {
        try {
            const data = await axios.get(`${COLONIAS_POSTAL}${postalCode}?token=${process.env.REACT_APP_TOKEN_POSTAL}`)
            setColonias(data.data)
        } catch (error) {
            setAlert({
                open: true,
                error: "Ingresa un código postal válido"
            })
            setColonias("")
        }
    }

    const handleChangesAddress = (ev) => {
        setNewAddress({
            ...newAddress,
            [ev.target.name]: ev.target.value
        })
    }

    const handlePostalCode = (ev) => {
        const number = ev.target.value
        if (number.length > 6) {
            setPostalCode(number.substring(0, 6))
        } else if (number.length < 6) {
            setPostalCode(number)
        }
    }

    const handlePhoneNumber = (ev) => {
        const number = ev.target.value
        if (number.length > 11) {
            setPhoneNumber(number.substring(0, 11))
        } else if (number.length < 11) {
            setPhoneNumber(number)
        }
    }

    useEffect(() => {
        setColonias("")
        setSelected("")
    }, [postalCode])

    function validateCode(ev) {
        ev.preventDefault()

        if (postalCode.length === 0) {
            setAlert({
                open: true,
                color:"red",
                error: "Ingresa un código postal"
            })
        } else {
            setAlert({
                open: false,
                color:"red",
                error: "Ingresa un código postal"
            })
        }

        getPostalCode(postalCode)
    }

    const handleDefault = () => {
        const isOn = newAddress.default
        if (!isOn) {
            setNewAddress({
                ...newAddress,
                default: true
            })
        } else if (isOn) {
            setNewAddress({
                ...newAddress,
                default: false
            })
        }
    }




    const handleSubmit = async (ev) => {
        ev.preventDefault()




        if (selected.length === 0) {
            setAlert({
                open: true,
                color:"red",
                error: "Selecciona una colonia"
            })
        } else if (phoneNumber.length < 10) {
            setAlert({
                open: true,
                color:"red",
                error: "Ingresa Teléfono a 10 digitos"
            })

        } else {
            setAlert({
                open: false,
                color:"red",
                error: "Selecciona una colonia"
            })

            // si es desde Admin que tiene que pasar?

            if(!addNewAddressAdmin){
                if (!newUser) {
                    // Si es usuario con dirección existente se añade directamente
                    // Validación diferente porque al registrase toma como nuevo usuario y solo añade las direcciones 
    
    
                    // Si es desde admin aqui me salto esto
                    
                    if (newAddress.default) {
    
                        const newArrayAddressOffDefault = data.users[0].location.map((e, id) => {
                            return {
                                ...e,
                                default: false
                            }
                        })
                        const sendToDbNewDefault = [
                            ...newArrayAddressOffDefault,
                            {
                                ...newAddress,
                                city: selected.municipio,
                                phoneNumber: phoneNumber,
                                postalCode: postalCode,
                                colonia: selected.asentamiento,
                                state: selected.estado,
                                fullAdress: newAddress.street + " " + selected.asentamiento + " " + postalCode + " " + selected.municipio + " " + selected.estado,
                            }
                        ]
                        try {
    
                            await SetDefault({
                                variables:
                                {
                                    id: data.users[0].id,
                                    address: sendToDbNewDefault
                                }
                            })
                            history.goBack()
                            switchCard("checkout")
    
                        } catch (e) {
                            console.log(e)
                        }
    
                    } else {
                        const newArrayOfAddress = [
                            ...data.users[0].location,
                            {
                                ...newAddress,
                                city: selected.municipio,
                                phoneNumber: phoneNumber,
                                postalCode: postalCode,
                                colonia: selected.asentamiento,
                                state: selected.estado,
                                fullAdress: newAddress.street + " " + selected.asentamiento + " " + postalCode + " " + selected.municipio + " " + selected.estado,
                            }
                        ]
    
                        try {
                            await SetDefault({
                                variables:
                                {
                                    id: data.users[0].id,
                                    address: newArrayOfAddress
                                }
                            })
                            switchCard("checkout")
                            history.goBack()
    
                        } catch (e) {
                            console.log(e)
                        }
                    }
    
                } else {
                    // Si es usuario nuevo pasa esto solo se pone en el contexto pero no se va a la DB
                    AddNewAddressContext({
                        ...newAddress,
                        default: true,
                        phoneNumber: phoneNumber,
                        postalCode: postalCode,
                        colonia: selected.asentamiento,
                        city: selected.municipio,
                        state: selected.estado
                    })
    
                                   
                        newUserTrue(false)
                        history.goBack()
                    
                }

            }else{
                AddNewAddressContext({
                    ...newAddress,
                    default: true,
                    phoneNumber: phoneNumber,
                    postalCode: postalCode,
                    colonia: selected.asentamiento,
                    city: selected.municipio,
                    state: selected.estado
                })

                setNewAddressAdmin(false)
                setnewAddressCreated(true)

            }

            





        }



    }





    useEffect(() => {
        if (usePreuser) {
            if (preUser.name !== undefined) {
                setNewAddress({
                    ...newAddress,
                    name: preUser.name
                })
            }
        } else {
            setNewAddress({
                ...newAddress,
                name: ""
            })
        }
    }, [usePreuser])


    return (
        <div className=" bg-gray-100 px-6 pt-6 pb-32 font-body">
        <span>
            <XCircleIcon 
            onClick={()=>setNewAddressAdmin(false)}
            className="w-4 h-4"></XCircleIcon>
        </span>
            <form action="submit"
                onSubmit={(ev) => handleSubmit(ev)}>
                <div className="bg-white rounded-lg p-6">
                    <div>
                        <div>
                            <h3 className="text-lg leading-6 font-semibold text-gray-900">Agregar un nueva dirección</h3>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">

                            <div className="sm:col-span-6">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Nombre
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={(ev) => handleChangesAddress(ev)}
                                        required
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={newAddress.name}
                                        autoComplete="name"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            {
                                preUser.name ?

                                    <div >
                                        <fieldset>
                                            <div>
                                                <div className="relative flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input
                                                            onClick={(ev) => SetUsePreuser(ev.target.checked)}
                                                            id="nameDefault"
                                                            name="nameDefault"
                                                            type="checkbox"
                                                            checked={usePreuser}
                                                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-xs">
                                                        <label htmlFor="nameDefault" className="font-medium text-gray-700">
                                                            Usar nombre de registro
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    :
                                    null

                            }


                            <div className="sm:col-span-6">
                                <label htmlFor="street" className="block text-sm font-medium text-gray-700">
                                    Calle y número
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={(ev) => handleChangesAddress(ev)}
                                        required
                                        id="street"
                                        name="street"
                                        type="text"
                                        autoComplete="street"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Código postal
                                </label>
                                <div className="mt-1">
                                    <input
                                        required
                                        onChange={(ev) => handlePostalCode(ev)}
                                        type="number"
                                        min="0"
                                        name="codigoPostal"
                                        value={postalCode}
                                        id="codigoPostal"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>

                                {

                                    selected.length === 0 ?

                                <Alert status={alert}></Alert>
                                        :

                                        null
                                }



                                <button
                                    className=" items-center w-full bg-white text-center mt-2 py-2 border border-transparent text-base rounded-md shadow-sm text-black font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 border-2 border-yellow-400"
                                    onClick={(ev) => validateCode(ev)}
                                >
                                    Validar código postal
                                </button>
                            </div>


                            {
                                colonias.length !== 0 ?
                                    <>
                                        <Listbox value={selected} onChange={setSelected}>
                                            {({ open }) => (
                                                <>

                                                    <div className="mt-1 relative">
                                                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                selected ?
                                                                    <span className="block truncate">{selected.asentamiento}</span>
                                                                    :
                                                                    <span className="block truncate">Selecciona una colonia</span>
                                                            }
                                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </span>

                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                                {colonias.map((block, id) => (
                                                                    <Listbox.Option
                                                                        key={id}
                                                                        className={({ active }) =>
                                                                            classNames(
                                                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                                                            )
                                                                        }
                                                                        value={block.response}
                                                                    >
                                                                        {({ selected, active }) => (
                                                                            <>
                                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                    {block.response.asentamiento}
                                                                                </span>

                                                                                {selected ? (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            active ? 'text-white' : 'text-blue-600',
                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                        )}
                                                                                    >
                                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>
                                        <div className="sm:col-span-6">
                                            <label htmlFor="extras" className="block text-sm font-medium text-gray-700">
                                                Indicaciones sobre el domicilio
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(ev) => handleChangesAddress(ev)}
                                                    required
                                                    id="extras"
                                                    name="extras"
                                                    type="text"
                                                    autoComplete="extras"
                                                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null

                            }

                            {
                                selected ?
                                    <div className="sm:col-span-3">
                                        <div className="mt-1">
                                            <input
                                                disabled
                                                type="text"
                                                value={selected.municipio + " , " + selected.estado}
                                                className="shadow-sm bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                            Teléfono
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={(ev) => handlePhoneNumber(ev)}
                                required
                                value={phoneNumber}
                                type="number"
                                name="phoneNumber"
                                id="phoneNumber"
                                autoComplete="phoneNumber"
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        {
                            phoneNumber.length < 10 ?
                                <Alert status={alert}></Alert>
                                :

                                null
                        }
                    </div>



                    <div className="mt-4">
                        <fieldset>
                            <div>
                                <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            onClick={handleDefault}
                                            id="default"
                                            name="default"
                                            type="checkbox"
                                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="ml-3 text-xs">
                                        <label htmlFor="default" className="font-medium text-gray-700">
                                            Usar como dirección predeterminada
                                        </label>

                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>


                </div>


                <div className="flex justify-center md:justify-start mt-4 mb-2">
                    <button
                        type="submit"
                        className="relative w-full md:w-1/4  px-4 py-2 border shadow-sm text-sm font-body rounded-md  bg-yellow-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                    >
                        <p>
                            Agregar dirección
                        </p>

                    </button>
                </div>
            </form>
        </div>
    )
}
