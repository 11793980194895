import { useMutation } from '@apollo/client'
import { CheckIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import React  , { useState , useRef , useEffect} from 'react'
import { UPDATE_REPORT, UPDATE_REPORT_PRODUCTS } from '../../../GraphQL/Mutations'
import CanvasSign from './CanvasSign'
// import CanvasSign from './CanvasSign'

export default function ReportMaker({setReporMaker , order , products , fullorder , setMisOpen , report, reportProduct}) {

  const [radioCheck , setRadioCheck]=useState(null)
  const [radioCheckP , setradioCheckP]=useState(null)
  const [comments , setComments]=useState(null)
  const [commentsP , setCommentsP]=useState(null)
  const [fotos , setFotos] = useState([])
  const [currentServiceId , setCurrentServiceId] = useState()
  const [done, setDone]=useState(0)
  const[countP , setCountP] = useState(0)
  const[countO , setCountO] = useState(0)
  const [orderUpdateable , setOrderUpdeteable] = useState(order.map((e, index)=> { return {"reported":false , "id":index + "abcdefg" , "comment": null , "service":e.service , "photos":[] , "price":e.price}}))

  const [productUpdeteable , setProductsUp] = useState([])
  const hiddenFileInput = useRef();
  const numberOfAct = order.length + products.length
  const [sendingReport , setSendingReport] = useState(false)
  const ROUTE_CLOUD = process.env.REACT_APP_CLOUD_PUBLIC

  const [updateReport] = useMutation(UPDATE_REPORT)
  const [updateReportPro] = useMutation(UPDATE_REPORT_PRODUCTS, {
    onCompleted: ()=>setSendingReport(false)
  })

  
  const goBack = () =>{
    setReporMaker(false)
    setMisOpen(false)
  }


  useEffect(()=>{
    setDone(Number(countP) + Number(countO))
  },[countP, countO])


  useEffect(()=>{
    if(reportProduct === null){

        if(products.length > 0){
            setProductsUp(products.map((product, index)=>{
                return {
                    ...product,
                    "reported":false,
                    "idReport":product.id + index,
                    "delivered":false,
                    "comment":null
                }
        }))
    }

    }else{

        
        setCountP(reportProduct.prodDetail.filter(e=>e.reported === true).length)
        setProductsUp(reportProduct.prodDetail)

    }

  },[products , reportProduct])

 
  const prodPrepareToSend = async (id) =>{

    setSendingReport(true)



    if (radioCheckP !== null){

    
        const prodDetail = await productUpdeteable.map(prod =>{
            if(prod.idReport === id){
                return {
                    ...prod,
                    "reported":true,
                    "delivered": id in radioCheckP ? radioCheckP[id] : prod.delivered,
                    "comment": commentsP !== null && id in commentsP ? commentsP[id] : null
            }
            }else{
                return {...prod}
            }
        })

    setProductsUp(prodDetail)
    if(done < numberOfAct){
        setDone(done + 1 )
    }
    await updateReportPro({variables:{ id:fullorder.id , reporteProd: {prodDetail}}})

}else{
    alert("Selecciona una opción")
}


}


  useEffect(()=>{
    if(report === null){
        setOrderUpdeteable(order.map((e, index)=> { return {"reported":false , "id":index + "abcdefg" , "comment": null , "service":e.service , "photos":[]  , "price":e.price}}))
    }else{
        
        setCountO(report.orderDetail.filter(e=>e.reported === true).length)
        setOrderUpdeteable(report.orderDetail)
        setFotos(report.orderDetail.map(e => { 
                return { 
                    "img": e.photos , 
                    "id": e.id
                
            }
    
        }))
    }   
  },[report , order])

  const  submitReportGraph = async(toTrueif , fotos , id)=>{

      const formData = new FormData();

      try{
          
        let arrOfPhotos = []
        
        
        for (let i = 0; i < fotos[0].files.length; i++) {
            let random = Math.floor(Math.random()*58959)
            let photos = fotos[0].files[i];
            formData.append("file", photos);
            formData.append("upload_preset", "workers");
            formData.append("public_id", `${random}worker${i}`);

            
            await fetch(ROUTE_CLOUD, {
                method: "POST",
                body: formData
            })
            .then((response) => {
                if(response.ok){
                    arrOfPhotos.push("https://res.cloudinary.com/dmvsjredw/image/upload/v1662087803/" + random + "worker" + i + ".jpg")
                }
            })
        }
        
        let orderDetail = await toTrueif.map(e=>{
            if(e.id === id){
                return {
                    ...e,
                    photos : arrOfPhotos
                }
            }else{
                return e
            }
        })  
        setOrderUpdeteable(orderDetail)
        await updateReport({variables:{ id:fullorder.id , reporte: {orderDetail}}})
        
        if(done < numberOfAct){
            setDone(done + 1 )
        }

        setSendingReport(false)
        
    }catch(e){
        console.log(e)
    }

    

  }

  const submitByOne = async (id) =>{


    let selectedPhotos = fotos.filter(file => file.id === id)
    let isThereComment = comments !== null && id in comments

    if(selectedPhotos.length === 0){
        alert("pon una foto perro")
    }else if(radioCheck !== null){
    
        if(!(id in radioCheck)){
            alert("selecciona si o no")
        }else{
            // EXITOO
            try{
                const toTrueif = orderUpdateable.map(e=> { 

                    if(id === e.id){
                        
                        if(isThereComment){
                            return {
                                ...e, 
                                "reported":true,
                                "comment": comments[id]
                            }
                        }
                        return {
                            ...e, 
                            "reported":true
                        }
                    }else{
                        return {
                            ...e
                        }
                    }
                    
                    }
                    )
                
                setOrderUpdeteable(toTrueif)
                setSendingReport(true)    
                submitReportGraph(toTrueif , selectedPhotos , id)
            }catch(e){
                console.log(e)
            }
            
        }
    }else if(radioCheck === null){
        alert("selecciona si o no")
    }else{   

        try{
            const toTrueif = orderUpdateable.map(e=> { 

                if(id === e.id){
                        
                    if(isThereComment){
                        return {
                            ...e, 
                            "reported":true,
                            "comment": comments[id]
                        }
                    }
                    return {
                        ...e, 
                        "reported":true
                    }
                }else{
                    return {
                        ...e
                    }
                }
                
                }
                )
            
            setOrderUpdeteable(toTrueif)
            setSendingReport(true)    
            submitReportGraph(toTrueif , selectedPhotos , id)
        }catch(e){
            console.log(e)
        }

    }
  }

  const handleInputs = (ev) =>{
    setRadioCheck({
          ...radioCheck,
          [ev.target.name]:ev.target.value
      })

  }

  const handleInputProducts = (ev) =>{
    setradioCheckP({
          ...radioCheckP,
          [ev.target.name]:ev.target.value
      })

  }

  const handleComments = (ev) =>{
    setComments({
        ...comments,
        [ev.target.name]:ev.target.value
    })
  }

  const handleCommentsProduct = (ev) =>{
    setCommentsP({
        ...commentsP,
        [ev.target.name]:ev.target.value
    })
  }


  const addPhotos = (id) =>{
    hiddenFileInput.current.click();
    setCurrentServiceId(id)
  }

  
  const handleUploadServices = (ev) =>{
      
      let bol = fotos.map(e=> e.id)

      if(bol.includes(currentServiceId)){
        let filtered = fotos.filter(files => files.id !== currentServiceId)
        setFotos([
            ...filtered,
            {files : ev.target.files , img : Object.values(ev.target.files).map(e=> URL.createObjectURL(e)) , id:currentServiceId }
        ])
    }else{
        setFotos([...fotos, {files : ev.target.files , img : Object.values(ev.target.files).map(e=> URL.createObjectURL(e)) , id:currentServiceId }])
      }


  }


  
  return (
<>
    {/* // regresar a pantalla antererio */}

    <div className="bg-gray-100 px-6 py-2 lg:p-24">
    <p className="my-3 " onClick={()=>goBack()}><ChevronLeftIcon className="h-8"/></p>

    <p className="font-body text-sm">Actividades completadas {done + " / " +numberOfAct}</p>
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
  <div className="bg-blue-600 h-2.5 rounded-full" style={{width: ((done/numberOfAct)*100)+"%"}}></div>
</div>
      
   
    <div className="bg-white shadow-xl overflow-hidden rounded-lg mt-4">
                <ul className="divide-y space-y-3 divide-gray-200">
                    {orderUpdateable.map((order, id) => (
                        <li key={order.id}>

                            {
                                !order.reported ?
                                <div className="flex items-center px-4  sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 py-4">
                                        <div>
                                            <p className="text-sm font-title  truncate">{order.service}</p>
                                                <p className="text-xs font-title text-gray-900"><span className="font-bold">{order.price.toLocaleString()}</span> <span className="text-xxs">mxn</span> </p>
                                            

                                          <div className="font-body text-sm text-gray-500">
                                            <p className="mt-3 text-black">¿Servicio completado?</p>
                                            
                                          <fieldset 
                                          className="mt-4 ">
                                            <div className="flex flex-row sm:items-center">
                                              
                                                <div className="flex items-center">
                                                  <input
                                                    id="no"
                                                    name={order.id}
                                                    onChange={handleInputs}
                                                    type="radio"
                                                    value={true}
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                  />
                                                  <label htmlFor="no" className="ml-3 block text-sm font-medium text-gray-700">
                                                    No
                                                  </label>
                                                </div>

                                                <div className="flex ml-4 items-center">
                                                  <input
                                                  onChange={handleInputs}
                                                    id="si"
                                                    name={order.id}
                                                    type="radio"
                                                    value={false}
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                  />
                                                  <label htmlFor="si" className="ml-3 block text-sm font-medium text-gray-700">
                                                    Si
                                                  </label>
                                                </div>
                                            
                                            </div>
                                          </fieldset>
                                            {

                                                radioCheck !== null &&
                                    
                                                radioCheck[order.id] === "true" &&
                                          <div className="my-3">
                                              <textarea
                                                rows={3}
                                                name={order.id}
                                                required
                                                onChange={(ev)=>handleComments(ev)}
                                                id="comment"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                placeholder={'Cual fue la situación final'}
                                              />
                                            </div>
                                           
                                            }
                                            <>
                                            {
                                                fotos.length > 0 ?

                                                <div className="flex flex-row space-x-4 mt-3 overflow-scroll">

                                                {
                                                fotos.map((file ) => {
                                                    
                                                    if(file.id === order.id){
                                                    return file.img.map(img => {
                                                        return <img className="h-20 w-20 rounded-md" src={img} alt="" />}
                                                        )
                                                    } else{
                                                        return null
                                                    }

                        
                                                })
                                                }
                                                </div>

                                                :
                                                null
                                            }
                                            <button
                                            type="button"
                                            onClick={()=>addPhotos(order.id)}
                                            className="relative my-3 block w-full border-2 border-gray-300 border-dashed rounded-lg  text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                          >
                                            
                                            <span className="mt-2 block text-sm font-medium text-gray-900"> + </span>
                                            <span className="mb-2 block text-sm font-medium text-gray-900"> Subir fotos </span>

                                          </button>
                                          <input type="file"
                                          multiple
                                          onChange={(ev)=>handleUploadServices(ev)}
                                          accept="image/*"
                                            ref={hiddenFileInput}
                                            style={{display:'none'}} 
                                        /> 

                                            </>

                                            <button
                                            type="button"
                                            
                                            onClick={()=>submitByOne(order.id)}
                                            className={`relative my-1 block w-full  rounded-lg  text-center bg-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${sendingReport && "cursor-not-allowed"}`}
                                          >
                                          
                                          
                                          <CheckIcon className="mx-auto py-1 text-white h-8"/>
                                           
                                          
                                          </button>
                                          
                                        </div>


                                        </div>
                                    </div>
                                </div>
                               
                                </div>
                                :

                                <div className="min-w-0 flex-1 px-4 py-4">
                                        <div>
                                            <p className="text-sm font-title  truncate">{order.service}</p>
                                            <p className="text-black font-body text-sm flex flex-row">Partida Guardada <span><CheckIcon className="text-green-500 ml-1 mt-1 h-4 w-4"></CheckIcon></span> </p>
                                            {
                                                order.comment !== null ?

                                                <p className="text-black font-body text-sm flex flex-row"> {order.comment}</p>
                                                
                                                
                                                :

                                                null
                                            }
                                            
                                            
                                          <div className="font-body text-sm text-gray-500">
                                            
                                          
                                            
                                            <>
                                            {
                                                fotos.length > 0 ?

                                                <div className="flex flex-row space-x-4 mt-3 overflow-scroll">

                                                {
                                                fotos.map((file ) => {
                                                    
                                                    if(file.id === order.id){
                                                    return file.img.map((img , indF) => {
                                                        return <img key={indF} className="h-20 w-20 rounded-md opacity-20" src={img} alt="" />}
                                                        )
                                                    } else{
                                                        return null
                                                    }

                        
                                                })
                                                }
                                                </div>

                                                :
                                                null
                                            }
                                           
                                            <button
                                            type="button"
                                            onClick={()=>addPhotos(order.id)}
                                            style={{display:'none'}} 
                                            className="relative my-3 block w-full border-2 border-gray-300 border-dashed rounded-lg  text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                          >
                                            
                                            <span className="mt-2 block text-sm font-medium text-gray-900"> + </span>
                                            <span className="mb-2 block text-sm font-medium text-gray-900"> Subir fotos </span>

                                          </button>
                                          <input type="file"
                                          multiple
                                          onChange={(ev)=>handleUploadServices(ev)}
                                          accept="image/*"
                                            ref={hiddenFileInput}
                                            style={{display:'none'}} 
                                        /> 

                                            </>
                                            <button
                                            type="button"
                                            
                                            onClick={()=>submitByOne(order.id)}
                                            className={`relative my-1 block w-full  rounded-lg  text-center bg-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${sendingReport && "cursor-not-allowed"}`}
                                          >
                                          
                                          {
                                            sendingReport ?
                                            
                                            <svg className="mx-auto my-2 animate-spin h-5 w-5 text-white text-center" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                             </svg>
                                            :
                                          null
                                          }
                                          
                        

                                          </button>

                                           
                                          
                                        </div>


                                        </div>
                                    </div>

                            }
                           
                            
                            
                        </li>
                    ))}
                </ul>

                
            </div>
            
            <div>
            {
                products.length !== 0 ?

                    <div className="bg-white shadow py-4 overflow-hidden rounded-md">
                        <ul className="divide-y divide-gray-200">
                            {productUpdeteable.map((product, id) => (

                                !product.reported?

                                <li key={id}>
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="flex-shrink-0 h-12 w-12 rounded-full shadow ">
                                                <img className="mx-auto mt-4 w-3/4" src={product.images[0].url} alt={product.name} />
                                            </div>
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <p className="text-sm font-title">{product.name}</p>
                                                    
                                                <p className="text-xs font-title text-gray-900"><span className="font-bold">{product.price.toLocaleString()}</span> <span className="text-xxs">mxn</span> </p>

                                                
                                                </div>
                                            </div>

                                              
                                            </div>
                                        </div>
                                            <div className="font-body text-sm text-gray-500 px-10">
                                            <p className="mt-3 text-black">¿Se instaló / entregó el producto?</p>
                                            
                                          <fieldset 
                                          className="mt-4 ">
                                            <div className="flex flex-row sm:items-center">
                                              
                                                <div className="flex items-center">
                                                  <input
                                                    id="no"
                                                    name={product.idReport}
                                                    onChange={handleInputProducts}
                                                    type="radio"
                                                    value={false}
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                  />
                                                  <label htmlFor="no" className="ml-3 block text-sm font-medium text-gray-700">
                                                    No
                                                  </label>
                                                </div>

                                                <div className="flex ml-4 items-center">
                                                  <input
                                                  onChange={handleInputProducts}
                                                    id="si"
                                                    name={product.idReport}
                                                    type="radio"
                                                    value={true}
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                  />
                                                  <label htmlFor="si" className="ml-3 block text-sm font-medium text-gray-700">
                                                    Si
                                                  </label>
                                                </div>
                                            
                                            </div>
                                          </fieldset>
                                            {

                                                radioCheckP !== null &&
                                    
                                                radioCheckP[product.idReport] === "false" &&
                                          <div className="my-3">
                                              <textarea
                                                rows={3}
                                                name={product.idReport}
                                                required
                                                onChange={handleCommentsProduct}
                                                id="comment"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                placeholder={'Cual fue la situación final'}
                                              />
                                            </div>
                                           
                                            }
                                            <>
                                            
                                            

                                            </>

                                            <button
                                            type="button"
                                            
                                            onClick={()=>prodPrepareToSend(product.idReport)}
                                            className={`relative my-1 block w-full  rounded-lg  text-center bg-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${sendingReport && "cursor-not-allowed"}`}
                                          >
                                        
                                          <CheckIcon className="mx-auto py-1 text-white h-8"/>
                                           
                                          
                                          </button>
                                          
                                        </div>
                                    
                            
                                </li>
                                :

                                <li key={id}>
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="flex-shrink-0 h-12 w-12 rounded-full shadow ">
                                                <img className="mx-auto mt-4 w-3/4" src={product.images[0].url} alt={product.name} />
                                            </div>
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <p className="text-sm font-title">{product.name}</p>
                                                    <p className="mt-3 text-black">Partida guardada</p>

                                                
                                                </div>
                                            </div>

                                              
                                            </div>
                                        </div>
                                            
                                            
                                            
                                          
                                           
                                            <>
                                            
                                            
                                           {
                                            sendingReport ?
                                            
                                            <svg className="mx-auto my-2 animate-spin h-5 w-5 text-white text-center" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                             </svg>
                                            :
                                          null
                                          }

                                            </>

                                        
                                    
                            
                                </li>




                                
                            ))}
                        </ul>
                    </div>
                    :

                    null}
           
                                                        
            </div>

            <div>
            <p className="font-body text-md mt-6 mb-2">Firma de conformidad</p>
            <CanvasSign id={fullorder.id} done={done} numberOfAct={numberOfAct}></CanvasSign>
            
            </div>

            </div>
</>
  )
}





















    




