import { gql } from '@apollo/client';



export const ROL_TO_OFFICE = gql`
mutation updateRoleOffice(
  $id:ID!, 
  $addressRegistered:Boolean!,
  $companyName:String!, 
  $name:String!, $rfc:String!, 
  $credit:Boolean!,
  $street:String!, 
  $colonia:String!, 
  $postalCode:String!,
  $city:String!,
  $fullAdress:String!,
  $nameAddress:String!,
  $state:String!,
  $extras:String!,
	$default:Boolean!,
  $phoneNumber:Long!
) {
  updateUser(
    input: {
      where: { id: $id }
      data: { 
        role : "611c0da8d89f847718826db0" , 
        companyName:$companyName , 
        name:$name , 
        rfc:$rfc , 
        credit:$credit ,
        addressRegistered:$addressRegistered,
        location:[
        {
        street:$street  
        colonia:$colonia
        postalCode:$postalCode
        city:$city
        fullAdress:$fullAdress
        name:$nameAddress
        state:$state
        extras:$extras
        default:$default
        phoneNumber:$phoneNumber
        }
      ]
      }
    }
  ) {
    user {
      id
      username
      companyName
      name
      rfc
      email
      role{
        id
        name
      }
    }
  }
}
`;

export const NAME_USER = gql`
mutation updateNameUser(
  $id:ID!, 
  $name:String!,
  $street:String!, 
  $colonia:String!, 
  $postalCode:String!,
  $addressRegistered:Boolean!,
  $city:String!,
  $fullAdress:String!,
  $nameAddress:String!,
  $state:String!,
  $extras:String!,
	$default:Boolean!,
  $phoneNumber:Long!
) {
  updateUser(
    input: { where: { id:$id } data: { 
      name:$name,
      addressRegistered:$addressRegistered,
      location:[
        {
        street:$street, 
        colonia:$colonia,
        postalCode:$postalCode,
        city:$city,
        fullAdress:$fullAdress,
        name:$nameAddress,
        state:$state,
        extras:$extras,
        default:$default,
        phoneNumber:$phoneNumber,
        }
      ]
    }
    }
  ) {
    user {
      id
      name
      location{
        id
      }
    }
  }
}
`;

export const SET_DEFAULT_ADDRESS = gql`
mutation SetDefault($address:[editComponentUsehelpyLocationInput] , $id:ID!){
  updateUser(
    input: { where: { id:$id } data: { 
      location: $address
    	}
    }
  ) {
    user {
      id
      name
      location{
        id
        street
      }
    }
  }
}
`;


export const NEW_CARD = gql`
mutation{
  createCard(input:{data: {lastFour:4544}}){
    card{
      lastFour
    }
  }
}
`;


export const NEW_LEAD = gql`
mutation newLead($email:String! , $request: JSON! , $service:String!) {
  createInteresado(input: { data: {mail:$email , request:$request , servicio:$service}}){
    interesado{
      id
      mail
      request
    }
  }
}
`;

export const ACCEPT_ORDER = gql`
mutation($id:ID!,$eta:Int!, $workers:[ID!] , $company:ID! ){
  updateOrder(input:{
    where:{id:$id}
    data:{
      status:acceptedProject,
      eta :$eta,
      pro_selecteds:$workers,
      pro_worker:$company
    }
  }){
    order{
      id
      orderNumber
      status
      pro_worker{
        name
      }
      pro_selecteds{
        name
      }
    }
  }
}
`;


export const UPDATE_STATUS_ORDER = gql`
mutation updateStatusOrder($id:ID!, $status:ENUM_ORDER_STATUS! , $timeGo:DateTime ,$timeArrived:DateTime, $timeFinish:DateTime ){
  updateOrder(input:{
    where:{id:$id}
    data:{
      status:$status,
      timeGo:$timeGo
      timeArrived:$timeArrived
      timeFinish:$timeFinish
    }
  }){
    order{
      id
      timeGo
      timeArrived
      timeFinish
      status
    }
  }
}
`;

export const UPDATE_DATE_ORDER = gql`
mutation updateDateOrder($id:ID!, $when:DateTime! ){
  updateOrder(input:{
    where:{id:$id}
    data:{
      when:$when,
    }
  }){
  	order{
      id
    }
  }
}
`;

export const UPDATE_REPORT = gql`
mutation updateReport($reporte:JSON! , $id:ID!){
  updateOrder(
    input: {
      where: { id: $id }
      data: { report: $reporte}
    }
  )
  {
    order{
      id
      report
    }
  }
}
`

export const UPDATE_REPORT_PRODUCTS = gql`
mutation updateReportPro($reporteProd:JSON! , $id:ID!){
  updateOrder(
    input: {
      where: { id: $id }
      data: { reportProducts: $reporteProd}
    }
  )
  {
    order{
      id
      reportProducts
    }
  }
}
`
export const SET_SIGNATURE = gql`
mutation setSignature($id:ID! , $signImage:String! $status:ENUM_ORDER_STATUS!, $timeReportUpload:DateTime!){
  updateOrder(
    input: {
      where: { id: $id }
      data: { 
        signatureLinkC: $signImage , 
        status:$status
        timeReportUpload : $timeReportUpload
      }
    }
  )
  {
    order{
      id
      signatureLinkC
      status
    }
  }
}
`

export const SET_REVIEW = gql`
mutation setReviewDb($id:ID! , $rate:Float! $rateComent:String!, $status:ENUM_ORDER_STATUS!){
  updateOrder(
    input: {
      where: { id: $id }
      data: { rate: $rate , rateComent:$rateComent , status:$status}
    }
  )
  {
    order{
      id
      signatureLinkC
      status
    }
  }
}
`


export const SET_CART = gql`
mutation setCart($cart:JSON! , $statusC: ENUM_CART_STATUS!, $idCart: Long!){
  createCart(
    input: {
      data: { cartfull: $cart , status: $statusC ,idCart: $idCart }
    }
  )
  {
    cart{
      cartfull
      id
      status
    }
  }
}

`

export const UPDATE_CART = gql`
mutation updateCart($cart:JSON! ,$id:ID!){
  updateCart(
    input: {
      where:{id:$id}
      data: { 
        cartfull: $cart 
      }
    }
    )
    {
      cart{
        cartfull
        id
        status
      }
    }
  }
`

export const CREATE_USER_FROM_ADMIN = gql`
mutation createUserAdmin($username:String!, $email:String! , 
  $name:String! , $role:ID! ,$phone:Long!, $street:String!, 
	$colonia:String!, $postalCode:String! , $city:String! ,
	$fullAdress: String! , $state:String! , $extras:String!
	){
  createUser(input:{data:{
    username:$username
    email:$email
    name:$name
    role:$role
    password:"abracadabra2020"
    phoneNumber:$phone
    location:[
        {
        street:$street 
        colonia:$colonia
        postalCode:$postalCode
        city:$city
        fullAdress:$fullAdress
        name:$name
        state:$state
        extras:$extras
        default:true
        phoneNumber:$phone
        }
      ]
  }}){
    user{
      id
    }
  }
}
`