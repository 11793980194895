import { Fragment, useContext , useEffect , useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import ModalContext from '../../Context/Modal/ModalContext'
import CheckOut from './CheckOut'
import SelectAddress from './SelectAddress'
import SelectCard from './SelectCard'
import LoginForm from '../landing/LoginForm'
import AuthContext from '../../Context/Auth/AuthContext'



export default function LogCard({ total, type, delivery, deleteCart , idCompany }) {

    const { closeCardOverlay, openCard, logCard } = useContext(ModalContext)
    
    const [companyPro, setCompanyPro] = useState({
        id: null,
        company : false
    })
    
    const {id  , userType} = useContext(AuthContext)
    
    useEffect(()=>{
        if(id !== null ){
            if(userType !== null){
                if(id.users[0].role.name === "CompanyPro"){
                    setCompanyPro({
                        id: id,
                        company : true
                    })
                }
            }
        }
    },[id])
    
    return (
        <Transition.Root show={openCard} as={Fragment}>
            <Dialog as="div" static className="fixed bottom-0  z-20" open={openCard} onClose={closeCardOverlay}>
                <div className="absolute bottom-0  z-20">
                    <Dialog.Overlay className="absolute bottom-0  z-20 " />
                    <div className="fixed bottom-0 inset-x-0  max-w-full flex z-20">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-y-full"
                            enterTo="translate-y-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-y-0"
                            leaveTo="translate-y-full"
                        >
                            <div className="w-screen  max-w-3/4 z-20">
                                <div className="h-full flex flex-col py-6  z-20 md:pb-40 rounded-t-xl  bg-white shadow-lg overflow-hidden">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex  justify-end">
                                            <div className="ml-3 h-7 flex justify-end">
                                                <button
                                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                                                    onClick={closeCardOverlay}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {

                                        {
                                            'checkout': <CheckOut total={total} type={type} delivery={delivery} deleteCart={deleteCart} idCompany={companyPro}/>,
                                            'address': <SelectAddress total={total} />,
                                            'cards': <SelectCard total={total} />,
                                            'login': <LoginForm />,
                                            'default': null
                                        }[logCard]

                                    }



                                </div>
                            </div>

                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 z-10 transition-opacity" />
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
