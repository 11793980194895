import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GET_PROJECTS } from '../../GraphQL/Queries'
import Loader from '../microComponents/Loader'

export default function GridExplore() {

    const { data } = useQuery(GET_PROJECTS)
    const [files, setFiles] = useState(undefined)
    useEffect(() => {
        if (data !== undefined) {
            setFiles(data.projectsDones)
        }
    }, [data])

    const history = useHistory()


    return (
        <>
            {
                files !== undefined ?

                    <ul className="grid px-8 mt-10 md:px-36 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
                        {files.map((file, id) => (
                            <li
                                key={id} className="relative cursor-pointer"
                                onClick={() => file.service !== null ? history.push(`/servicio/${file.service.name}`) : null}
                            >
                                <div className="group block w-full aspect-w-10 aspect-h-10 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                                    {
                                        file.gallery.length !== 0 ?

                                            <img src={file.gallery[0].url} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                                            :
                                            null
                                    }

                                </div>
                                {
                                    file.service !== null ?
                                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{file.service.name}</p>

                                        :
                                        null}

                            </li>
                        ))}
                    </ul>
                    :

                    <div className='py-48 text-center'>

                        <Loader></Loader>
                    </div>
            }
        </>
    )
}

