import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router'
import { GET_HELPYMATIC } from '../../GraphQL/Queries';
import Loader from '../microComponents/Loader';
import DirectService from './DirectService';
import ProjectService from './ProjectService';



export default function Service() {

    const { name } = useParams()
    const [type, setType] = useState("")
    const { loading, data } = useQuery(GET_HELPYMATIC, { variables: { name: name } })

    useEffect(() => {
        if (data !== undefined) {
            setType(data.services[0].type)
        }
    }, [loading, data])


    return (
        <>
            <div className="bg-gray-100 min-h-full p-5">
                {
                    data !== undefined?
                        {
                            'direct': <DirectService data={data} loading={loading} />,
                            'miniService': <DirectService data={data} loading={loading} />,
                            'proyect': <ProjectService data={data} loading={loading} />
                        }[type]
                        :
                        <div className="flex justify-center">
                            <div className="py-32">
                                <Loader></Loader>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}






