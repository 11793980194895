import React from 'react'
import { useState , useContext, useEffect} from 'react'
import {  useLocation } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import AuthContext from '../../../Context/Auth/AuthContext'
import ProductContext from '../../../Context/Products/ProductsContext'
import Order from '../../buyProcess/Order'
import axios from 'axios'
import SERVER_ROUTE from '../../../Services/paths';
import { useHistory } from 'react-router'
import ModalContext from '../../../Context/Modal/ModalContext'
import { useMutation, useQuery } from '@apollo/client'
import { ORDERS_PAID } from '../../../GraphQL/Queries'
import { CREATE_USER_FROM_ADMIN } from '../../../GraphQL/Mutations'
import Loader from '../../microComponents/Loader'

export default function CreateService() {

    const deliveryMethods = [
        { id: 1, title: 'efectivo'},
        { id: 2, title: 'transferencia' },
        { id: 3, title: 'link' },
      ]

      const location = useLocation()
      const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(deliveryMethods[0])
      const [total , setTotal] = useState()
      const dateFake = new Date()
      const [sending, setSending] = useState(false)
      const [dateOrNot, setDateOrNot] = useState(dateFake)
      const {id, address, userSelectedByAdmin} = useContext(AuthContext)
      const {products, orderFastCheckOut , order , productsCheckOut , time , date ,killCarWhenBought,
        emptyTemporalOrder,
        emptyTemporalProducts} = useContext(ProductContext)
      const { closeCardOverlay } = useContext(ModalContext)
      const history = useHistory()
      const {refetch} = useQuery(ORDERS_PAID)
      const [createUserAdmin ] = useMutation(CREATE_USER_FROM_ADMIN)
      const [loading , setLoading] = useState(true)

      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

      const [orderData , setOrderData] = useState({ready:false})

      useEffect(() => {
            if (time !== null && date !== null) {
                const dateTime = new Date(date.dayData.getFullYear(), date.dayData.getMonth(), date.dayData.getDate(), parseInt(time.day, 10), parseInt(time.day.substr(-2)))
                setDateOrNot(dateTime)
        }
    }, [date, time])

     
    useEffect(()=>{

      if(!sending){
        setTotal(location.state.requestData.total)
        
        setTimeout(()=>{
          setLoading(false)
        },1000)
        
        if(location.state.requestData.from.from === "fastCheckOut"){
          setOrderData({prod : products , order: orderFastCheckOut , ready:true})
        }else if (location.state.requestData.from.from === "cartCheckOut"){
          setOrderData({order: order, prod: productsCheckOut , ready:true})
        }
      }

    },[location])
      


      const newOrderToDbFromAdmin = async (ev)=>{
        ev.preventDefault()

        const alphabet = ["gracias", "súper", "amor", "levitacorpus", "lumus", "accio", "feliz", "útil", "esperanza", "respeto", "solución", "felicidad", "amistad", "generosidad", "helpy", "ayuda", "vivir", "potter", "posible", "caridad", "gratidud", "novedad", "snow", "sirius", "lady", "aria", "khaleesi"]

        const letter = alphabet[Math.floor(Math.random() * alphabet.length)]
        const ordNum = Math.floor(Math.random() * (99999 - 10000) + 10000)

        try {
          setSending(true)

          let userCreated 

          if(userSelectedByAdmin.id === undefined){
            
            userCreated = await  createUserAdmin({variables:{
              username: userSelectedByAdmin.username, 
              email: userSelectedByAdmin.username, 
              name: address.name, 
              role: "607e18d7031421066ac8ecea", 
              phone: address.phoneNumber, 
              street: address.street, 
              colonia: address.colonia, 
              postalCode: address.postalCode, 
              city: address.city, 
              fullAdress: address.street + " " + address.colonia+ " " + address.postalCode +" " + address.city +" " + address.state,
              state: address.state,
              extras: address.extras
            }})

          }

          
          
          const config = {
              headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
              }
          }


          const dataToServer = {
              "products": products,
              "user":  userSelectedByAdmin.id !== undefined ? userSelectedByAdmin.id: userCreated.data.createUser.user.id,
              "fastOrder": orderFastCheckOut,
              "cartOrder": order,
              "productOrder": productsCheckOut,
              "order": { products, orderFastCheckOut, order, productsCheckOut },
              "total": total,
              "when": dateOrNot,
              "ordernum": letter + "-" + ordNum,
              "address": {...address , fullAdress :address.street + " " + address.colonia+ " " + address.postalCode +" " + address.city +" " + address.state },
              "zipCode": address.postalCode,
              "who":"admin",
              "company": id.users[0].pro_worker.id,
              "payment": selectedDeliveryMethod.title
          }

          const res = await axios.post(SERVER_ROUTE + "/orders", dataToServer, config)
          
          if(res.status === 200){
            closeCardOverlay()
            killCarWhenBought()
            emptyTemporalOrder()
            emptyTemporalProducts()
            history.push(`/company/dashboard`)
            refetch()
          }
      } catch (e) {
          console.log(e)
      }

      } 
      


  return (

    <>
    {

      loading? 
      <div className="py-48 flex justify-center">

      <Loader></Loader>
      </div>
      
      : 

      <form 
    onSubmit={(ev)=>newOrderToDbFromAdmin(ev)}
    className="space-y-8 divide-y divide-gray-200 md:mt-8 mt-2 md:px-48 px-6">
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
       

        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Usuario
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <p className="mt-1 max-w-2xl text-sm text-gray-500">{userSelectedByAdmin.username}</p>
            </div>
          </div>


        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Dirección
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
            <p className="mt-1 max-w-2xl text-sm text-gray-500">{address.street + ", " + address.colonia + ", " + address.postalCode + ", " + address.city }</p>
            </div>
          </div>

      
    </div>

    <div className="mt-10 border-t border-gray-200 pt-10">
              <RadioGroup value={selectedDeliveryMethod} onChange={setSelectedDeliveryMethod}>
                <RadioGroup.Label className="text-lg font-medium text-gray-900">Método de pago</RadioGroup.Label>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {deliveryMethods.map((deliveryMethod) => (
                    <RadioGroup.Option
                      key={deliveryMethod.id}
                      value={deliveryMethod}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'ring-2 ring-blue-500' : '',
                          'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex-1 flex">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                {deliveryMethod.title}
                              </RadioGroup.Label>
                              
                            </span>
                          </span>
                          {active ? <CheckCircleIcon className="h-5 w-5 text-blue-600" aria-hidden="true" /> : null}
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-blue-500' : 'border-transparent',
                              'absolute -inset-px rounded-lg pointer-events-none'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
             {
  orderData.ready &&
            <Order products={orderData.prod} order={orderData.order} editPrice={{edit:true , from:location.state.requestData.from.from} } setTotal={setTotal}></Order>

} 

    <div className="pt-5">
      <div className="flex justify-end">

      
      <button
                        disabled={sending}
                        type="submit"
                        className="relative w-full  mt-4 px-4 py-2 border shadow-sm text-sm font-body rounded-md  bg-yellow-300  focus:outline-none disabled:bg-yellow-100 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                    >
                        {
                            sending ?
                                <div className="inline-flex">
                                    <div>

                                        <svg className="animate-spin -ml-1 mx-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                    <p>
                                        Juntando todo lo necesario
                                    </p>
                                </div>
                                :
                                <p>
                                Crear servicio
                                </p>
                        }


                    </button>
      </div>
    </div>
  </form>


    }
      </>
    
  )
}


