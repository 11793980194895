import React from 'react'
import { useHistory } from 'react-router-dom'

export default function SuccesDate() {

    const history = useHistory()
    return (
        <>
            <div className="bg-green-400 py-36  h-screen">
                <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-extrabold font-title text-white sm:text-4xl">
                        <span className="block">Cita agendada con éxito.</span>
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-green-100">
                        a un paso menos de mejorar tu espacio
                    </p>
                    <a
                        href="https://api.whatsapp.com/send?phone=5218115000527"
                        className="mt-8 lg:mr-2 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
                    >
                        Platica con un asesor
                    </a>
                    <button
                        onClick={() => history.push("/explorar")}
                        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
                    >
                        Explora proyectos
                    </button>
                </div>
            </div>
        </>
    )
}
