/* eslint-disable import/no-anonymous-default-export */
import { CLOSE_MODAL, OPEN_MODAL, OPEN_CARD_OVERLAY, CLOSE_CARD_OVERLAY, LOG_CARD, SET_NEW_ADDRESS_LOCAL, SET_NEW_CARD, SET_INTERESADO } from "../types";

export default (state, action) => {

    const { payload, type } = action
    switch (type) {
        case OPEN_MODAL:
            return {
                ...state,
                open: true,
                selectedProd: payload
            }
        case CLOSE_MODAL:
            return {
                ...state,
                open: false,
                selectedProd: null
            }
        case OPEN_CARD_OVERLAY:
            return {
                ...state,
                openCard: true,
            }
        case CLOSE_CARD_OVERLAY:
            return {
                ...state,
                openCard: false,
                logCard: "default"
            }
        case LOG_CARD:
            return {
                ...state,
                logCard: payload.data,
                addressFromDB: payload.address,
            }
            case SET_NEW_ADDRESS_LOCAL:
                return {
                    ...state,
                    addressFromDB: payload
                }

        case SET_NEW_CARD:
            return {
                ...state,
                newCard: payload
            }
        case SET_INTERESADO:
            return {
                ...state,
                interesade: payload
            }
        default:
            return state
    }

}