import { GET_SERVICESBYCATEGORY } from '../../GraphQL/Queries'
import { useQuery } from '@apollo/client'
import Loader from '../microComponents/Loader'
import { Link, useParams } from 'react-router-dom'



export default function ServiceAndProductsByCat() {


    const { servicio } = useParams()
    const { data } = useQuery(GET_SERVICESBYCATEGORY, { variables: { category: servicio } })




    return (
        <div className="bg-white">
            <div>
                <div className="border-b border-gray-200">
                    <nav aria-label="Breadcrumb" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <ol className="flex items-center space-x-4 py-4">
                            <li>
                                <div className="flex items-center">
                                    <Link to={"/categorias"}>
                                        <p className="mr-4 text-sm font-medium text-gray-900">
                                            Categorías
                                        </p>
                                    </Link>
                                    <svg
                                        viewBox="0 0 6 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        className="h-5 w-auto text-gray-300"
                                    >
                                        <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
                                    </svg>
                                </div>
                            </li>
                            <li className="text-sm">
                                <p aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                    {servicio}
                                </p>
                            </li>
                        </ol>
                    </nav>
                </div>

                <main className="max-w-2xl mx-auto px-4 lg:max-w-7xl lg:px-8">
                    <div className="border-b font-title border-gray-200 pt-2 pb-4">
                        <h1 className="text-xl font-extrabold tracking-tight text-gray-900">Todo lo que necesitas</h1>
                        <p className="text-sm font-body text-gray-500">
                            Desde poner un foco, hasta hacer una casa!
                        </p>
                    </div>

                    <div className="pt-4 pb-24 lg:grid lg:grid-cols-3 font-body lg:gap-x-8 xl:grid-cols-4">
                        <section aria-labelledby="product-heading" className=" mt-6 lg:mt-0 lg:col-span-4 xl:col-span-4">
                            <h2 id="product-heading" className="sr-only">
                                Products
                            </h2>
                            {
                                data !== undefined ?

                                    <div className="font-body grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4">
                                        {data.services.map((service, id) => (
                                            <div
                                                key={id}
                                                className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden"
                                            >
                                                {
                                                    service.image !== null ?

                                                        <div className='bg-gray-50 p-10'>
                                                            <img className="w-3/4 mx-auto" src={service.image.url} alt={service.name} />
                                                        </div>
                                                        :
                                                        <div className='bg-gray-50 p-10'>

                                                        </div>
                                                }

                                                {/* <div className="aspect-w-2 aspect-h-2 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96">
                                            <img
                                                src={service.imageSrc}
                                                alt={service.imageAlt}
                                                className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                                            />
                                        </div> */}
                                                <div className="flex-1 p-4 space-y-2 flex flex-col">
                                                    <h3 className="sm:text-sm text-xs font-bold text-gray-900">
                                                        <Link to={`/servicio/${service.name}`}>
                                                            <span aria-hidden="true" className="absolute inset-0" />
                                                            {service.name}
                                                        </Link>
                                                    </h3>
                                                    <p className="sm:text-sm text-xs text-gray-500 justify-start truncate">{service.description}</p>
                                                    <div className="flex-1 flex flex-col justify-start">
                                                        <span className='text-xs'>Desde:</span>
                                                        <p className="text-base font-bold my-0 text-gray-900">{service.startingBasePriceInfo} <span className='text-xs font-medium 0'>mxn</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :

                                    <Loader></Loader>
                            }


                        </section>
                    </div>
                </main>


            </div>
        </div>
    )
}
