import React from 'react'
import { Fragment ,useState , useEffect ,useContext} from 'react'
import {
  ClockIcon,
  CurrencyDollarIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  ShoppingBagIcon,
} from '@heroicons/react/solid'
import { useLazyQuery, useMutation } from '@apollo/client'
import AuthContext from '../../../Context/Auth/AuthContext'
import Loader from '../../microComponents/Loader'
import { GET_WORKER_ORDERS } from '../../../GraphQL/Queries'
import { UPDATE_STATUS_ORDER } from '../../../GraphQL/Mutations'
import ModalStatusUpdate from '../../microComponents/ModalStatusUpdate'
import ReportMaker from './ReportMaker'


export default function DashboardWorker() {

  const {id , loadingMagic, logged} = useContext(AuthContext)
  const [getServicesOfWorker , {data , loading }] = useLazyQuery(GET_WORKER_ORDERS)
  const steps = [
    {status: "acceptedProject",mesage:"Antes de salir recuerda revisar el servicio  y la herramienta necesaria", button:"Poner en camino " , translate: "Proyecto Aceptado"},
    {status: "Omw", mesage:"Da clic para notificar al cliente que vas en camino a su domicilio", button :"Inicio de servicio", translate: "En camino"},
    {status: "inProgress", mesage:"Tu trabajo es tu carta de presentación" , button :"Terminar servicio" , translate: "En progreso"}, 
    {status: "reportMaking",mesage:"Esperando llenado de reporte", button :"Subir reporte" , translate: "Hacer reporte"},
    // {status: "building",mesage:""},Cuando es solo producto
    {status: "done", mesage:"Revisa con el cliente el servicio y firma de recibido" , button :"Finalizar servicio" , translate: "Finalizado"},
  ]
  const [misOpen , setMisOpen] = useState(false)
  const [detailSelected , setDetailSelected] = useState({
    cartshop:{
    order: [],
    products: []
  }
  })
  const [successMutation , setSuccess] = useState(false)
  // const heute = new Date()
  const [alert,setAlert] = useState({open: false, error: "null",color:"red"})
  const [currentStatus , setCurrentStatus ]= useState(0)
  const [updatingOrder , setUpdatingOrder] =useState({on:false , data : {updateOrder :{order : {id:null}}}})
  const [reporMaker ,  setReporMaker] = useState(false)


  useEffect(()=>{
    if(logged){
      getServicesOfWorker ({ variables: { id: id.users[0].id } })

    } 
  },[id , logged , getServicesOfWorker , loading])
  
  const success = (data)=>{
    setSuccess(true)
    setAlert({open: true, error: "Oferta aceptada con éxito", color:"green",})
    setMisOpen(false)
    setUpdatingOrder({on:false , id: data.updateOrder.order.id})
}

  const [updateStatusOrder ] = useMutation(UPDATE_STATUS_ORDER , {
    onCompleted : (data) => success(data)
  })



  

  const updateOrderStatus = () =>{
            setMisOpen(false)
            setUpdatingOrder({on:true , id: data.updateOrder.order.id})
            updateStatusOrder({variables:{id:data.updateOrder.order.id , status:steps[currentStatus + 1].status}})

  }

  const openMDetails = (service )=>{
    setMisOpen(true)
    setDetailSelected(service)
    const StepCurrent = steps.findIndex(step => {
      return step.status === service.status;
  })
    setCurrentStatus(StepCurrent)
  } 

  
  return (

    <>
      {
        !reporMaker ?
        <div className=" bg-gray-100">
    {
      !loading && <ModalStatusUpdate isOpen={misOpen} setIsOpen={setMisOpen} order={detailSelected.cartshop.order} products={detailSelected.cartshop.products} fullorder={detailSelected} currentStatus={currentStatus} updateStatusOrder={updateStatusOrder} setCurrentStatus={setCurrentStatus} steps={steps} setUpdatingOrder={setUpdatingOrder} reporMaker={reporMaker}  setReporMaker={setReporMaker}></ModalStatusUpdate>
    }

    {
      !loadingMagic && logged && data !== undefined? 
      <div className="p-5 lg:p-24">

      <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
      {
        
        data.orders.map((appointment) => (
        <li 
        onClick={()=>openMDetails(appointment)}
        key={appointment.id} 
        className={`cursor-pointer relative my-5 pl-5 bg-gray-50 flex space-x-6 py-6 xl:static rounded-lg ${updatingOrder.on&& updatingOrder.id === appointment.id && "animate-pulse"}`}>
          <div className="flex-auto">
            <h3 className="pr-10 font-title text-lg font-semibold text-gray-900 xl:pr-0">{appointment.cartshop.order.length > 0 ? appointment.cartshop.order[0].service : appointment.cartshop.products.length > 0 &&  appointment.cartshop.products[0].name} </h3>
            <h2 className="pr-10  text-gray-900 xl:pr-0">Cliente : {appointment.user.name} </h2>
            <span className="flex flex-row">
            <ClockIcon className="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
            <div >Hora de llegada:  {new Date(appointment.when).toLocaleTimeString('es-MX', {hour:'2-digit',minute:'2-digit'})} - Hora de termino:  {
              new Date(new Date(appointment.when).getTime() + (Number(appointment.eta)*60000)).toLocaleTimeString('es-MX', {hour:'2-digit',minute:'2-digit'}) 
            } 
              </div>
            </span>
            <a className="underline z-10" target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/` + appointment.fullLocation.fullAdress }>

            <span className="flex flex-row">
           
            <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <dd>{appointment.fullLocation.fullAdress}</dd>
            </span>
            </a>
            <h2 className="pr-10  text-gray-900 xl:pr-0">Técnico(s) asignado: {appointment.pro_selecteds.map(pro=>{
              if(appointment.pro_selecteds.length > 1){
                return  pro.name + " , " 
              } else {
                return pro.name
              }
              })} </h2>

                           
            <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
              <div
              className="flex items-start space-x-3 cursor-pointer">
                <dt 
                className="mt-0.5">
                  <span className="sr-only">Estatus</span>
                  <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>Estatus : {steps.map(step=>step.status === appointment.status && step.translate)}</dd>
              </div>
              <div className="flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt className="mt-0.5">
                  <span className="sr-only">Precio</span>
                  <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>Precio:{appointment.total} </dd>
                {/* Mostrar precio unicamente cuando esta en efectivo */}
              </div>
              <div className="flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt className="mt-0.5">
                  <span className="sr-only">Pago</span>
                  <ShoppingBagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>Método de pago : {appointment.paymentMethod}</dd>
              </div>
              
            </dl>


            <div className="mt-4">
          
      <button
        type="button"
        className="inline-flex items-center px-4 mx-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-500 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300"
      >
        {steps.map(step=>step.status === appointment.status && step.button)}
      </button>

   
            </div>
          </div>

          
        </li>
      ))}
    </ol>

       
      

      </div>
    :
      <div className="py-48 text-center">
        <Loader/>
      </div>
    }
    </div>
        :
      <ReportMaker setReporMaker={setReporMaker} order={detailSelected.cartshop.order} products={detailSelected.cartshop.products} fullorder={detailSelected} setMisOpen={setMisOpen} report={detailSelected.report} reportProduct={detailSelected.reportProducts}></ReportMaker>
      }
    </>

    
  )
}
