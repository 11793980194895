import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import AuthContext from '../../Context/Auth/AuthContext'
import ProductContext from '../../Context/Products/ProductsContext'
// import { useQuery } from '@apollo/client'
// import { GET_USER } from '../../GraphQL/Queries'
import ModalContext from '../../Context/Modal/ModalContext'
import MenuFly from './MenuFly'
import Loader from '../microComponents/Loader'

export default function Navbar() {

    const { order, productsCheckOut, emptyTemporalProducts , cartNum} = useContext(ProductContext)
    const { logout, user, logged, loadingMagic, id, newUser , userType} = useContext(AuthContext) 
    const { switchCard, closeCardOverlay, logCard } = useContext(ModalContext)
    const infoUrl = useLocation()
    const [menu, setMenu] = useState(false)
    const [profMenu, setProfMenu] = useState(false)
    const [sum, setSum] = useState(0)
    const [showCat, setShowCat] = useState(true)

    useEffect(() => {
        setSum(productsCheckOut.length + order.length)
    }, [productsCheckOut, order])


    const [companyPro, setCompanyPro] = useState(false)
    const [companyWorker, setCompanyWorker] = useState(false)


    useEffect(() => {
        if (!loadingMagic && logged && !newUser) {

            if (logCard === "address") {
                return
            } else if (logCard === "cards") {
                return
            } else {
                switchCard("checkout")
            }
        }

        if (!loadingMagic && !logged) {
            switchCard("login")
        }

        if (logged && newUser) {
            history.push("/client/registration")
        }

        if (!loadingMagic && logged ) {

            if(userType === "CompanyPro"){
                setCompanyPro(true)
                history.push("/company/dashboard")
            }else if(userType === "CompanyWorker"){
                history.push("/worker/dashboard")
                setCompanyWorker(true)
            }
        }


       

    }, [loadingMagic, logged, newUser, logCard, userType])


    useEffect(() => {
        if (infoUrl.pathname.includes("/categoria/") || infoUrl.pathname.includes("/esentials/")) {
            setShowCat(false)
        } else {
            setShowCat(true)
        }
    }, [infoUrl])



    const history = useHistory()

    const menuButton = () => {
        menu === true ?
            setMenu(false)
            :
            setMenu(true)

    }

    const profileButton = () => {
        profMenu === true ?
            setProfMenu(false)
            :
            setProfMenu(true)

    }

    const login = () => {
        history.push("/login")
        setProfMenu(false)
    }

    const logOut = () => {
        logout()
        setProfMenu(false)
        profileButton()
        closeCardOverlay()
        history.push("/")
    }


    return (
        <>

            <nav className="bg-white shadow">
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="relative flex justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">

                            <button
                                onClick={menuButton}
                                type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
                                <span className="sr-only">Menu</span>
                                {
                                    menu ? <svg className=" h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg> :
                                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                        </svg>

                                }
                            </button>
                        </div>
                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="flex-shrink-0 flex items-center"
                                onClick={() => emptyTemporalProducts()}>
                                <Link to="/">
                                    <img className="block lg:hidden h-8 w-auto" src="https://res.cloudinary.com/nein/image/upload/v1642617519/logo_p1usnu.svg" alt="helpymx logo" />
                                    <img className="hidden lg:block h-10 w-auto" src="https://res.cloudinary.com/nein/image/upload/v1642617519/logo_p1usnu.svg" alt="helpymx logo" />
                                </Link>
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                {/* <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" --> */}
                                {
                                    showCat ?
                                        <div className='relative z-10'>
                                            <MenuFly clase={"w-max -left-40"}></MenuFly>
                                        </div>
                                        :
                                        null
                                }
                                {/* <Link to={"/helpySelection"}>
                                <p className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                                        productos
                                </p>
                                </Link> */}

                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <Link to={`/compra/carrito/${cartNum}`}>
                                <button
                                    onClick={() => emptyTemporalProducts()}
                                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span className="rounded-full bg-naranja absolute top-3 text-white text-xs w-4 h-4">{sum}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                </button>
                            </Link>

                            {/* <!-- Profile dropdown --> */}
                            <div className="ml-3 relative">
                                <div>
                                    <button
                                        onClick={profileButton}
                                        type="button" className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                        <span className="sr-only">Open user menu</span>
                                        <img className="h-8 w-8 rounded-full" src="https://res.cloudinary.com/nein/image/upload/v1642617546/avatar_mo7meh.gif" alt="" />
                                    </button>
                                </div>
                                {
                                    profMenu ?

                                        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 z-50 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">

                                            <>
                                                {
                                                    loadingMagic ?
                                                        <div className='px-4 py-4'>
                                                        <Loader></Loader>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                !logged ?
                                                    <p
                                            onClick={login}
                                                        className="block px-4 py-2 text-sm cursor-pointer text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-0">Iniciar sesión</p>
                                                    :

                                                    <>
                                                        <>
                                                            {
                                                                user ?

                                                                    <p className="block cursor-pointer px-4 py-2 text-sm text-gray-700">{user}</p>

                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                        {
                                                            !companyPro && !companyWorker ? 
                                                            <Link onClick={profileButton} to={`/client/dashboard/${id.users[0].id}`}>
                                                            <p className="block cursor-pointer px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Historial</p>
                                                        </Link>
                                                            :

                                                            companyPro? 

                                                            <Link onClick={profileButton} to={`/company/dashboard`}>
                                                            <p className="block cursor-pointer px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Agenda</p>
                                                        </Link>
                                                            :

                                                            companyWorker &&
                                                            <Link onClick={profileButton} to={`/worker/dashboard`}>
                                                            <p className="block cursor-pointer px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Servicios</p>
                                                        </Link>

                                                        }
                                                        <p
                                            onClick={() => logOut()}
                                                            className="block cursor-pointer px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Cerrar sesión</p>

                                                    </>

                                            }
                                                        </>
                                                }

                                            </>

                                        </div>

                                        :

                                        null
                                }

                            </div>
                        </div>
                    </div>
                </div>

                {
                    menu ?
                        <div className="sm:hidden" id="mobile-menu">
                            <div className="pt-2 pb-4 space-y-1">
                                {/* <Link to={"/helpySelection"}>
                                    <p className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">helpy esentials</p>
                                </Link> */}

                            </div>
                        </div>
                        : null
                }

                <div className='relative z-10 sm:hidden '>
                    {
                        showCat ?
                            <div className='relative z-50'>
                                <MenuFly></MenuFly>
                            </div>
                            :
                            null
                    }
                </div>
                {
                companyPro&&
                <div className="w-full h-16 bg-red-500">
                    <p className="text-center">** MODO ADMINISTRADOR **</p>
                </div> 
                
            }


            </nav>

            {/* NAV DE INICIO DE SESIÓN */}
            {/* <nav className="bg-white fixed bottom-0 w-full p-2 md:hidden drop-shadow-2xl">
                <div>
                    <div className=" flex justify-center text-gray-500">
                        <div className="grid grid-cols-4 gap-12 ">
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 mx-auto"
                                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                                <p className="text-center">Inicio</p>
                            </div>
                            <div >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <p className="text-center">Buscar</p>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                </svg>
                                <p className="text-center">Pedidos</p>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <p className="text-center">Perfil</p>
                            </div>


                        </div>
                    </div>
                </div>

            </nav > */}
           
        </>
    )
}
