import React, { useEffect, useState } from 'react'
import Searchbar from './Searchbar'
import LogCard from '../buyProcess/LogCard.jsx'
import CirclesCat from '../microComponents/CirclesCat'
import Promo from '../microComponents/Promo'
import SliderProducts from './SliderProducts'
import CtaSection from './CtaSection'
import Slider4Products from './Slider4Products'
import MisPromos from './MisPromos'
import SliderServices from './SliderServices'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'


export default function HeroHome() {

    const history = useHistory()


    const [size, setsize] = useState(window.innerWidth)
    const [items, setItems] = useState(0)

    useEffect(() => {
        window.addEventListener("resize", function () {
            setsize(window.innerWidth)
        });

        if (size > 639 && size < 767) {
            setItems(2)
        } else if (size < 639) {
            setItems(1)
        } else if (size > 767) {
            setItems(5)
        }

    }, [size])


    return (
        <>

            <LogCard></LogCard>

            <div className="relative bg-white overflow-hidden">
                <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
                    <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                        <div className="md:max-w-2xl md:mx-auto lg:col-span-6 text-left">
                            <h1>

                                <span className="mt-1 block text-3xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl flex">
                                    <span className="block font-title text-gray-900 mr-2">Tu hogar es</span>
                                    <span className="block font-title text-naranja">amor</span>
                                </span>
                            </h1>
                            <p className="mt-1 font-body text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                helpy te ayuda desde colgar un cuadro, hasta remodelar la casa
                            </p>
                            <div className="mt-8 sm:max-w-lg w-xl text-left lg:mx-0">

                            <div className="flex flex-col-reverse sm:flex-row sm:space-x-4">

                            <button
                    onClick={() => history.push("/explorar")}
                    className="py-3 mt-6 md:mt-0 px-6 border border-transparent text-base font-title font-bold rounded-md text-gray-100 bg-naranja shadow-sm hover:bg-gray-900 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto text-sm">
                    Explora
                </button>
                                {/* SEARCHBAR */}
                                <Searchbar />
                            </div>

                            </div>
                        </div>
                        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

                            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">


                                <img className="w-full rounded-md" src="https://images.unsplash.com/photo-1616046229478-9901c5536a45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80" alt="" />
                                <div className="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
                                </div>

                            </div>
                        </div>
                    </div>
                </main>

                <CirclesCat items={items}></CirclesCat>
                <Slider4Products items={items}></Slider4Products>
                <MisPromos></MisPromos>
                <SliderProducts items={items}></SliderProducts>
                {/* <CtaSection></CtaSection> */}
                <Promo></Promo>
                <SliderServices items={items}></SliderServices>




            </div>


        </>
    )
}
