import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GET_MONTH_PROMO } from '../../GraphQL/Queries'

const heute = new Date()

export default function Promo() {

    const [promo, setPromos] = useState([
        {
            name: "data.promomensuals[0].cta1",
            title: "data.promomensuals[0].title1",
            imageSrc: "data.promomensuals[0].firstphoto.url",
            imageAlt: "data.promomensuals[0].title1"
        },
        {
            name: "data.promomensuals[0].cta1",
            title: "data.promomensuals[0].title1",
            imageSrc: "data.promomensuals[0].firstphoto.url",
            imageAlt: "data.promomensuals[0].title1"
        },
        {
            name: "data.promomensuals[0].cta1",
            title: "data.promomensuals[0].title1",
            imageSrc: "data.promomensuals[0].firstphoto.url",
            imageAlt: "data.promomensuals[0].title1"
        },
    ])

    const { data } = useQuery(GET_MONTH_PROMO, { variables: { month: heute.getMonth() + 1 } })

    useEffect(() => {
        if (data !== undefined) {
            setPromos([
                {
                    name: data.promomensuals[0].cta1,
                    title: data.promomensuals[0].title1,
                    imageSrc: data.promomensuals[0].firstphoto.url,
                    imageAlt: data.promomensuals[0].title1
                },
                {
                    name: data.promomensuals[0].cta2,
                    title: data.promomensuals[0].title2,
                    imageSrc: data.promomensuals[0].secphoto.url,
                    imageAlt: data.promomensuals[0].title2
                },
                {
                    name: data.promomensuals[0].cta3,
                    title: data.promomensuals[0].title3,
                    imageSrc: data.promomensuals[0].thirdphoto.url,
                    imageAlt: data.promomensuals[0].title3
                },
            ])
        }
    }, [data])



    return (
        <>
            {
                data !== undefined ?
                    <div className="relative bg-white">
                        {/* Background image and overlap */}
                        <div aria-hidden="true" className="hidden absolute inset-0 sm:flex sm:flex-col">
                            <div className="flex-1 relative w-full bg-gray-800">
                                <div className="absolute inset-0 overflow-hidden">
                                    <img
                                        src={data.promomensuals[0].backPhoto.url}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                </div>
                                <div className="absolute inset-0 bg-gray-900 opacity-50" />
                            </div>
                            <div className="w-full bg-white h-32 md:h-40 lg:h-48" />
                        </div>

                        <div className="relative max-w-3xl mx-auto pb-96 px-4 text-center sm:pb-0 sm:px-6 lg:px-8">
                            {/* Background image and overlap */}
                            <div aria-hidden="true" className="absolute inset-0 flex flex-col sm:hidden">
                                <div className="flex-1 relative w-full bg-gray-800">
                                    <div className="absolute inset-0 overflow-hidden">
                                        <img
                                            src={data.promomensuals[0].backPhoto.url}
                                            alt=""
                                            className="w-full h-full object-center object-cover"
                                        />
                                    </div>
                                    <div className="absolute inset-0 bg-gray-900 opacity-50" />
                                </div>
                                <div className="w-full bg-white h-48" />
                            </div>
                            <div className="relative py-32 font-title">
                                <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">{data.promomensuals[0].titulo}</h1>
                                <div className="mt-4 sm:mt-6">
                                    <p

                                        className="inline-block bg-indigo-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-indigo-700"
                                    >
                                        {data.promomensuals[0].mainCta}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <section aria-labelledby="collection-heading" className="-mt-96 relative sm:mt-0 font-body">
                            <h2 id="collection-heading" className="sr-only">
                                Collections
                            </h2>
                            <div className="max-w-md mx-auto grid grid-cols-1 gap-y-6 px-4 sm:max-w-7xl sm:px-6 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 lg:px-8 lg:gap-x-8">
                                {promo.map((collection, id) => (
                                    <div
                                        key={id}
                                        className="group relative h-96 bg-white rounded-lg shadow-xl sm:h-auto sm:aspect-w-4 sm:aspect-h-5"
                                    >
                                        <Link to={`categoria/${collection.title}`}>
                                        <div>
                                            <div aria-hidden="true" className="absolute inset-0 rounded-lg overflow-hidden">
                                                <div className="absolute inset-0 overflow-hidden group-hover:opacity-75">
                                                    <img
                                                            src={collection.imageSrc}
                                                        alt={collection.imageAlt}
                                                        className="w-full h-full object-center object-cover"
                                                        />
                                                </div>
                                                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                                            </div>
                                            <div className="absolute inset-0 rounded-lg p-6 flex items-end">
                                                <div>
                                                    <p aria-hidden="true" className="text-sm text-white">
                                                        {collection.title}
                                                    </p>
                                                    <h3 className="mt-1 font-semibold text-white">
                                                        <p>
                                                            <span className="absolute inset-0" />
                                                            {collection.name}
                                                        </p>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                    :

                    null
            }




        </>
    )
}

