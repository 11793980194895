import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react"

import SwiperCore, {
    Keyboard,
    Mousewheel,
    Scrollbar,

} from 'swiper/core';
import { useQuery } from '@apollo/client';
import { GET_LIST_TWO } from '../../GraphQL/Queries';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

SwiperCore.use([Scrollbar, Mousewheel, Keyboard]);





export default function SliderProducts({ items }) {

    const { data, loading } = useQuery(GET_LIST_TWO)

    return (
        <div className="bg-white">
            <div className="max-w-7xl font-body mx-auto py-8 px-4 overflow-hidden sm:py-24 sm:px-6 lg:px-8">
                <div className="">
                    <div className='py-4'>
                        <h1 className='font-title text-xl font-bold '>Esta temporada, tu espacio mejora</h1>
                        <p className='font-body text-gray-600'>Y tú mejoras también</p>
                    </div>
                    <Swiper
                        scrollbar={true}
                        loop={true}
                        spaceBetween={50}
                        slidesPerView={1.5 + items}
                        mousewheel={true}
                        keyboard={true}

                    >
                        {
                            !loading && data !== undefined ?

                                data.listTwos.map((service, id) => (
                                    <SwiperSlide key={id}>

                                    {
                                        console.log(service)
                                    }
                                        {/* <Link to={`servicio/${service.service.name}`}>
                                            <div className="group text-sm cursor-pointer	">

                                                <div className="flex-shrink-0">
                                                    <div className={`flex-shrink-0 h-40 w-40 rounded-lg ${service.service.categories[0].color} group-hover:opacity-75`}>
                                                        <div className='w-full h-full flex justify-center items-center'>
                                                            <img
                                                        src={service.service.image.url}
                                                        alt={service.service.name}
                                                                className="w-3/4"
                                                    />
                                                </div>
                                                    </div>
                                                </div>


                                                <h3 className="mt-4 font-medium text-gray-900 truncate">{service.service.name}</h3>
                                                <div className='flex flex-row justify-start'>
                                                    <p className='text-xs mr-1 leading-loose'>desde: </p>
                                                    <p className="font-medium text-lg text-gray-900 ">{service.service.startingBasePriceInfo}  </p>
                                                    <p className='text-xs ml-1 leading-loose'>mxn </p>
                                                    <ChevronRightIcon className='w-6 h-6 text-blue-400 group-hover:text-blue-500' />
                                                </div>
                                            </div>
                                        </Link> */}
                                    </SwiperSlide>
                                ))
                                :

                                [1, 2, 3, 4, 5].map((service, id) => (
                                    <SwiperSlide key={id}>

                                        <div className="group text-sm cursor-pointer	">
                                            <div className={`w-full w-full aspect-w-1 aspect-h-1  rounded-lg bg-gray-200 group-hover:opacity-75`}>
                                                <span className='w-full h-full'>

                                                </span>
                                            </div>

                                        </div>
                                    </SwiperSlide>
                                ))
                        }
                    </Swiper>


                </div>
            </div>

        </div>
    )
}




