import { useMutation, useQuery } from '@apollo/client'
import { StarIcon } from '@heroicons/react/solid'
import {useState , useEffect} from 'react'
import { useParams , useHistory} from 'react-router'
import { SET_REVIEW } from '../../../GraphQL/Mutations'
import { GET_RATES } from '../../../GraphQL/Queries'

export default function RateService() {


    const {id} = useParams()
    const history = useHistory()
    
    const {data, loading} = useQuery(GET_RATES,{variables:{id:id}})

    
    const [review , setReview] = useState(0)
    const [statusDb , setstatusDb] = useState("ratePending")
    const [commment , setCommment] = useState("")
    const [loadingPost , setLoading] = useState(false)
    const [info  , setInfo] = useState({
      pro_selecteds: [{name : " "}],
      pro_worker: {
        name:" "
      }
    })
    
    const succes = () =>{
      setLoading(false)
      history.push("/")
    }


    const [setReviewDb] = useMutation(SET_REVIEW ,{
      onCompleted : () => succes()
    })
    

    const rateService= () =>{
      if(review !== 0 && commment !== ""){
        setLoading(true)
      setReviewDb({variables:{id:id , rate:review ,  rateComent:commment , status:"done"}})
      }else{
        alert("Completa calificación y comentario")
      }


      
    }


    useEffect(()=>{
        if(data !== undefined && !loading){

            setstatusDb(data.order.status)

            setInfo({
                pro_selecteds: data.order.pro_selecteds,
                pro_worker: {
                    name: data.order.pro_worker.name
                }
            })


        }
    },[data , loading])

      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

  return (
    <div className="bg-white">
      <div className=" py-16 px-4 sm:py-24 sm:px-12">
        <div className="mt-6 space-y-10 ">
        
        {
          statusDb === "ratePending" ? 

          <>

          <div>
        <h2 className="text-center font-title text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
           Califica el servicio: 
        </h2>

        {
           

        <p className="font-body text-lg text-center">Servicio realizado por : {info.pro_selecteds.map((e, id)=>{
            if(id +1 < info.pro_selecteds.length){
                
                return e.name + " , "
            }else{
                return e.name 
            }
        })}</p>
        }
         
        </div>

        <div className="flex justify-center">



        <div className="flex items-center">
                    {[0, 1, 2, 3, 4].map((rating , id) => (
                      <StarIcon
                        key={id}
                        onClick={()=>setReview(rating +1)}
                        className={classNames(
                          review > rating ? 'text-yellow-400' : 'text-gray-200',
                          'h-16 w-16 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
        </div>




                  <div>

      <label htmlFor="comment" className="block text-md mt-12 font-medium text-gray-700">
        Comentarios del servicio
      </label>
      <div className="mt-1">
        <textarea
          rows={4}
          name="comment"
          onChange={(ev)=>setCommment(ev.target.value)}
          id="comment"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>

             
<button type="button" onClick={rateService} className={`relative my-3 block w-full  rounded-lg  text-center bg-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${loadingPost && "cursor-not-allowed"}`}>

{
  loadingPost ?
                                            
                                            <svg className="mx-auto my-2 animate-spin h-5 w-5 text-white text-center" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                             </svg>
                                            :
                                          
        <p className="text-white py-2 text-xl font-body">Calificar</p>
                                          }
    </button>
       

    

    <p className="font-body text-sm text-gray-700 text-center">Empresa a cargo del servicio : {info.pro_worker.name}</p>

          </>
          : 
          <h2 className="text-center mt-auto font-title text-2xl font-bold text-gray-900 sm:text-4xl">
           Servicio finalizado 
        </h2>
          
        }
        
          
        </div>
      </div>
    </div>
              
  )
}



