import { useMutation } from '@apollo/client'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import axios from 'axios'
import {useRef , useState} from 'react'
import SignaturePad from 'react-signature-canvas'
import { SET_SIGNATURE } from '../../../GraphQL/Mutations'
import "../../../sigCanvas.css"
import { useHistory } from 'react-router'


export default function CanvasSign({id ,done ,numberOfAct}) {

  const history = useHistory()
  const sigCanvas = useRef()
  const[image , setImage] = useState(null)
  const[sigDone , setSigDone] = useState(false)
  const[sending , setSending] = useState(false)

  const doneReport = ()=>{
    setSigDone(true)
    setSending(false)
    history.push(`/rateservice/${id}`)
  }

  const ROUTE_CLOUD = process.env.REACT_APP_CLOUD_PUBLIC
  const [setSignature] = useMutation(SET_SIGNATURE ,{
    onCompleted: () => doneReport()
  })

  const SaveSignature = () =>{
    if(done !== numberOfAct){
      alert("Completa el reporte")
    }else{
      setImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/jpg"))
    }
  }

  const ClearSignature = () =>{
    sigCanvas.current.clear()
    setImage(null)
  }

  
  const sentToCloudinary = async() =>{
    setSending(true)

    const formData = new FormData();
    let timeStamp = new Date()

    try{
          let random = Math.floor(Math.random()*898989)
          let photos = image;
          formData.append("file", photos);
          formData.append("upload_preset", "workers");
          formData.append("public_id", `${random}signature`);

          await fetch(ROUTE_CLOUD, {
            method: "POST",
            body: formData
        })
        .then((response) => {
            if(response.ok){
              setSignature({variables:{id:id , signImage: "https://res.cloudinary.com/dmvsjredw/image/upload/v1662087803/" + random + "signature.jpg" , status :"ratePending" , timeReportUpload: timeStamp}})
            }
        })
        
          
  }catch(e){
      console.log(e)
  }
  }

  return (
    <div>
    {
      image === null ? 
      <>

      <SignaturePad 
      ref={sigCanvas}
      canvasProps={{
        className:"signatureCanvas"
      }} />

      <button type="button" onClick={SaveSignature} className={`relative my-1 block w-full  rounded-lg  text-center bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
        <CheckIcon className="mx-auto py-1 text-white h-8"/>
      </button>
      </>

      :
      <>
      <div className="relative">
      {
        !sigDone ?
      <button type="button" onClick={ClearSignature} className={`w-10  rounded-tr-lg  absolute top-0 right-0 text-center bg-red-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
        <XIcon className="mx-auto py-1 text-white h-8"/>
      </button>
      :null
      }

      
      </div>
      <div className={`${!sigDone ? "bg-white" : "bg-red-500"} rounded-md p-8`}>
      <SignaturePad 
      ref={sigCanvas}
      canvasProps={{
        className:"hiddenCanvas"
      }} />
      <img src={image} alt="firma"></img>

      

      </div>
      {
        !sigDone ? 

        !sending?
        
        <button type="button" onClick={sentToCloudinary} className={`relative mt-4  py-1 block w-full  rounded-lg  text-center bg-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
        <p className="text-white text-md font-body">Guardar reporte</p>
      </button>
      :

      <button type="button" className={`disabled relative mt-4  py-3 block w-full  rounded-lg  text-center bg-green-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-not-allowed`}>
      <svg className="mx-auto my-2 animate-spin h-5 w-5 text-white text-center" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                             </svg>
      </button>

      
      :
      
       null
      }
     

      <p className="font-body text-xs mt-4">Se recibe el trabajo enumerado en la parte de arriba, dudas y aclaraciones en soporte@helpy.mx. Términos y condiciones de los servicios se pueden encontrar en helpy.mx en cada servicio individual o en terminos y condiciones generales</p>
      
      </>

    }
      
    </div>
  )
}


 