import React, { useContext, useEffect, useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
// import { useMutation } from '@apollo/client';
// import { NEW_CARD } from '../../GraphQL/Mutations';
import axios from 'axios'
import SERVER_ROUTE from '../../Services/paths';
import AuthContext from '../../Context/Auth/AuthContext';
import { useHistory } from 'react-router';
import ModalContext from '../../Context/Modal/ModalContext';


export default function AddNewCard() {
    const stripe = useStripe();
    const elements = useElements();
    const { logged, loadingMagic } = useContext(AuthContext)
    const { setNewCard } = useContext(ModalContext)
    const history = useHistory()


    const [setUpIntent, setSetupIntent] = useState({
        errorMessage: false,
        success: false,
        loading: false
    })



    useEffect(() => {
        if (!logged && !loadingMagic) {
            history.push("/")
        }
    }, [logged])

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSetupIntent({
            ...setUpIntent,
            loading: true
        })

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.log("no ha cargado")
            return;
        }




        const config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }

        try {
            const res = await axios.post(SERVER_ROUTE + "/cards", null, config)

            const result = await stripe.confirmCardSetup(res.data.client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            });

            if (result.error) {
                // Display result.error.message in your UI.
                setSetupIntent({
                    ...setUpIntent,
                    loading: false,
                    errorMessage: result.error.message
                })
            } else {
                // The setup has succeeded. Display a success message and send
                // result.setupIntent.payment_method to your server to save the
                // card to a Customer
                setNewCard(true)
                setSetupIntent({
                    ...setUpIntent,
                    loading: false,
                    errorMessage: false,
                    success: "Targeta agregada correctamente"
                })
            }
        } catch (e) {
            setSetupIntent({
                ...setUpIntent,
                errorMessage: "Tiempo exedido, refresca el sitio"
            })
            console.log(e)
        }

    }



    const backToPayment = () => {
        history.goBack()
    }


    return (
        <>
            <div className="bg-gray-100 h-screen  p-5 font-body">
                <div className="bg-white  sm:w-3/4   mx-auto overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6 ">


                        <form onSubmit={handleSubmit}>
                            <CardElement />
                            {setUpIntent.errorMessage ?

                                <div className="items-center w-full text-center mt-8 py-2 border border-transparent text-md  rounded-md shadow-sm text-white bg-red-400 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400" >{setUpIntent.errorMessage}

                                </div>
                                :
                                null
                            }

                            {
                                setUpIntent.loading ?
                                    <div className="items-center  w-full text-center mt-8 py-2 border border-transparent text-base  rounded-md shadow-sm text-white bg-yellow-400 hover:bg-yellow-400 opacity-75 cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"  >
                                        <span className="inline-flex">
                                            <p className="mr-2">
                                                Revisando
                                            </p>
                                            <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </span>

                                    </div>

                                    :




                                        setUpIntent.success ?
                                            <>
                                            <div className="items-center w-full text-center mt-8 py-2 border border-transparent text-md  rounded-md shadow-sm text-white bg-green-400 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400" >Tarjeta guardada
                                                <img className="w-20 mx-auto text-center" src="../img/checked.gif" alt="" />
                                            </div>

                                                <button
                                                    onClick={backToPayment}
                                                    className="items-center w-full text-center mt-8 py-2 border border-transparent text-base  rounded-md shadow-sm text-white bg-yellow-400 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400" >Regresar</button>
                                            </>

                                            :


                                            <button className="items-center w-full text-center mt-8 py-2 border border-transparent text-base  rounded-md shadow-sm text-white bg-yellow-400 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400" disabled={!stripe}>Guardar Tarjeta</button>
                            }

                        </form>
                    </div>
                </div>
            </div>

        </>


    );
}
