import React from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import ProductContext from '../../Context/Products/ProductsContext'
import { useContext } from 'react'
import { useEffect } from 'react'






export default function DateTime({ people, title, type }) {


    const { timeSelected, dateSelected } = useContext(ProductContext)





    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [selected, setSelected] = useState(null)


    useEffect(() => {
        if (type === "date") {
            dateSelected(selected)
            timeSelected(null)

        } else if (type === "time") {
            timeSelected(selected)
        }
    }, [selected])



    return (

        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <Listbox.Label className="block text-sm font-body mt-2">{title} : </Listbox.Label>
                    <div className="mt-1 relative">
                        <Listbox.Button className="relative w-full  bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-orange-400 focus:border-orange-400 sm:text-sm">
                            {
                                selected === null ?
                                    <div className="">

                                        <div className="flex items-center">
                                            {
                                                type === "date" ?
                                                    <span className="ml-3 block truncate">Selecciona dia</span>
                                                    :
                                                    <span className="ml-3 block truncate">Selecciona horario</span>
                                            }
                                        </div>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </div>
                                    :

                                    <div>

                                        <div className="flex items-center">
                                            <span
                                                aria-label={selected.online ? 'Online' : 'Offline'}
                                                className={classNames(
                                                    selected.online ? 'bg-green-400' : 'bg-gray-200',
                                                    'flex-shrink-0 inline-block h-2 w-2 rounded-full'
                                                )}
                                            />
                                            <span className="ml-3 block truncate">{selected.day}</span>
                                        </div>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </div>
                            }

                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                static
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            >
                                {people.map((person) => (
                                    <Listbox.Option
                                        key={person.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-orange-400' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <span
                                                        className={classNames(
                                                            person.online ? 'bg-green-400' : 'bg-gray-200',
                                                            'flex-shrink-0 inline-block h-2 w-2 rounded-full'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    <span
                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                    >
                                                        {person.day}
                                                        <span className="sr-only"> is {person.online ? 'online' : 'offline'}</span>
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-orange-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>


    )

}





