import React from 'react'
import {Fragment, useContext, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Order from '../buyProcess/Order'
import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_CHALANES, ORDERS_PAID} from '../../GraphQL/Queries'
import AuthContext from '../../Context/Auth/AuthContext'
import {ClockIcon} from '@heroicons/react/solid'
import Alert from './Alert'
import {ACCEPT_ORDER} from '../../GraphQL/Mutations'
export default function ModalDash({isOpen, setIsOpen, order, products, fullorder}) {

    const {id} = useContext(AuthContext)
    const totalFromOrder = order.reduce((a, b) => a + Number(b.price), 0) + products.reduce((a,b)=> a + Number(b.price),0)
    const [getChalanes, {
            data,
            loading : loadingQuery
        }
    ] = useLazyQuery(GET_CHALANES)

    const [updateOrder , { loading : loadingMutation}] = useMutation(ACCEPT_ORDER , {
        onCompleted: () => success(),
        update(cache,{data}){
            const {orders} = cache.readQuery({
                query: ORDERS_PAID
            });

            cache.writeQuery({
                query: ORDERS_PAID,
                data:{
                    orders: orders.filter(order=> order.id !== fullorder.idOrder)
                }
            })
        }

    })
    const [successMutation , setSuccess] = useState(false)
    
    const success = ()=>{
        setSuccess(true)
        setAlert({open: true, error: "Oferta aceptada con éxito", color:"green",})
        setTimeout(()=>{
            setIsOpen(false)
        }, 800)
    }

    useEffect(() => {

        id !== null && getChalanes({
            variables: {name: id.users[0].pro_worker.name}
        })

    }, [id])

    const [dataCheck, setdataCheck] = useState([])
    const [minutes, setMinutes] = useState(0)
    const [alert,setAlert] = useState({open: false, error: "null",color:"red"})

    const selectChalan = (ev, id) => {
        if (ev.target.checked) {
            setdataCheck([
                ...dataCheck,
                id.id
            ])

        } else {
            setdataCheck(dataCheck.filter((option) => option.id !== id))
        }
    }

    const postOrder = (ev) => {
        ev.preventDefault()

        if (dataCheck.length === 0 || minutes <= 0) {
            setAlert({open: true, error: "Selecciona almenos un técnico o pon un tiempo correcto", color:"red",})
        } else {
            updateOrder({
                variables: {
                    id: fullorder.idOrder,
                    eta: Number(minutes),
                    workers: dataCheck,
                    company: id.users[0].pro_worker.id
                }
            })

        }

    }

    const handleMinutes = (ev) => {
        setMinutes(ev.target.value)
    }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={isOpen}
                onClose={setIsOpen}>
                <div
                    className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay
                            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-3/4 md:w-1/2">

                            <div>

                                <div className="mt-3 font-title text-center sm:mt-5 ">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg  leading-6 font-medium text-left text-gray-900">
                                        Detalles de orden :
                                    </Dialog.Title>

                                    <div className=" text-left mt-2">
                                        <Order order={order} products={products}></Order>
                                    </div>

                                    <div className="text-left ">

                                        <form onSubmit={(ev) => postOrder(ev)}>
                                            <div className="grid grid-cols-2 gap-4 px-2">

                                                <div>
                                                    <Dialog.Title
                                                        as="h3"
                                                        className="text-md leading-6 font-medium text-left mt-4 text-gray-900">
                                                        Asignar técnicos(s) :
                                                    </Dialog.Title>
                                                   
                                                    {!loadingQuery && data !== undefined
                                                        ? <fieldset className="space-y-2">

                                                                {data
                                                                    .users
                                                                    .map((chalan, id) => {

                                                                        return <div key={id} className="relative mt-2 flex items-start">
                                                                            <div className="flex items-center h-5 mt-1">

                                                                                <input
                                                                                    onClick={(ev) => selectChalan(ev, chalan)}
                                                                                    id={chalan.name}
                                                                                    value={chalan.id}
                                                                                    aria-describedby="comments-description"
                                                                                    name={chalan.name}
                                                                                    type="checkbox"
                                                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                                                            </div>
                                                                            <div className="ml-3 text-sm">
                                                                                <label htmlFor={chalan.name} className="font-medium text-gray-700">
                                                                                    {chalan.name}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    })
}
                                                            </fieldset>
                                                        : <p className="my-3">cargando..</p>
}
                                                </div>
                                                <div >
                                                    <Dialog.Title
                                                        as="h3"
                                                        className="text-md leading-6 mt-4 font-medium text-left text-gray-900">
                                                        Tiempo estimado:
                                                    </Dialog.Title>
                                                    <div className="mt-1 relative rounded-md  w-3/4 shadow-sm">
                                                        <div
                                                            className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                                                            <ClockIcon className="text-gray-400 h-5"></ClockIcon>
                                                        </div>
                                                        <input
                                                            onChange={(ev) => handleMinutes(ev)}
                                                            type="number"
                                                            name="eta"
                                                            id="eta"
                                                            required
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 w-full block pl-10 sm:text-sm border-gray-300 rounded-md"
                                                            placeholder="120"/>
                                                        <div
                                                            className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                min
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="relative mt-4">
                                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div className="w-full border-t border-gray-300"/>
                                                </div>
                                                <div className="relative flex justify-center">
                                                    <span className="px-2 bg-white text-sm text-gray-500">Desglose</span>
                                                </div>
                                            </div>

                                            <div >

                                                <div className="text-xs">

                                                    <p>Total : {totalFromOrder}
                                                        <span className="text-xxs">mxn</span>
                                                    </p>
                                                    <p>Comisión : {totalFromOrder * .25}
                                                        <span className="text-xxs">mxn (25%)
                                                        </span>
                                                    </p>
                                                    <p>IVA: {(totalFromOrder * .25) * .16}
                                                        <span className="text-xxs">mxn</span>
                                                    </p>
                                                    <p className="text-sm">Recibes:
                                                        <span className="text-green-600 font-bold">
                                                            {((totalFromOrder) - ((totalFromOrder * .25) * .16) - (totalFromOrder * .25)).toFixed(2)}</span>
                                                        <span className="text-xs">mxn</span>
                                                    </p>
                                                    <p className="text-xxs my-2">Soy responsable de pagar los impuestos por los ingresos recibidos</p>
                                                </div>
                                            </div>
                                            <Alert status={alert}></Alert>
                                            
                                            {
                                            loadingMutation || successMutation ? 
                                            
                                            <button
                                                        type="button"
                                                        disabled
                                                        className="cursor-not-allowed px-4 py-3 border border-gray-300 shadow-sm w-full text-base font-medium rounded-md text-gray-700 bg-yellow-300 hover:bg-yellow-200 focus:outline-none ">
                                                        enviando ...
                                                    </button>
                                                : <button
                                                    type="submit"
                                                    className=" px-4 py-3 border border-gray-300 shadow-sm w-full text-base font-medium rounded-md text-gray-700 bg-yellow-300 hover:bg-yellow-200 focus:outline-none ">
                                                    Aceptar trabajo
                                                </button>
}

                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
