import { gql } from '@apollo/client';


export const GET_SERVICES = gql`
query services{
services{
  name
  keyword
}
}
`;

export const GET_SERVICES_FEATURES = gql`
query getFeatures($name:String!){
	services(where :{name:$name}){
    name
    id
    disclaimer
    description
    feature{
      listOfFeatures{
        list
      }
    }
  }
}
`;


export const GET_PRODUCTS_NAMES = gql`
query products{
  products{
    name
    id
    sub_category{
      subcat
    }
  }
}
`;

export const GET_PRODUCTS_NAMES_V2 = gql`
query products{
  products(start:100 ,limit: 200){
    name
    id
    sub_category{
      subcat
    }
  }
}
`;


export const GET_USER = gql`
query findoneUser($username:String!){
  users(where:{ username:$username }){
    username
    name
    pro_worker{
      name
      id
    }
    id
    rfc
    role{
      name
    }
    location{
      street
    }
  }
}
`;

export const GET_CATEGORIES = gql`
{
  categories{
    category
    image{
      url
    }
  }
}
`;



export const GET_PRODUCTS = gql`
{
  products{
    name
    subCategory
    timeDelivery{
      unit
      quantity
    }
    deliveryPrice
    id
    price
    images{
      url
    }
  }
}
`;

export const GET_PRODUCT_BY_ID = gql`
query getProductbyId ($id:ID!){
  product(id:$id){
    name
    price
    sub_category{
      subcat
    }
    timeDelivery{
      unit
      quantity
    }
    deliveryPrice
    description
     variantes{
      price
      extra
      title
      image{
        url
      }
    }
    images{
      url
    }
  }
}
`;



export const GET_HELPYMATIC = gql`
query Ser($name:String!){
	services(where :{name:$name}){
    id
    type
    name
    faq{
      question
      answer
    }
    gallery{
      url
    }
    image{
      url
    }
    basePrice
    startingBasePriceInfo
    description
    disclaimer
    categories{
      category
    }
    helpymatic{
      id
      type
      question
      priceInput
      priceDiscountInput
      overThisDiscount
      boolDependent
      priceDependent
      correctAnswerToShow
      answers{
        description
        option
        price
        image{
          url
        }
      }
      
    }
  }
}
`;


export const GET_RELATEDPRODUCTS = gql`
query relProdToService($name:String!){
	services(where :{name:$name}){
    products{
      id
      price
      name
      description 
      images{
        url
      }
    }
    
  }
}
`;

export const GET_SERVICESBYCATEGORY = gql`
query categoryServices($category:[String!]!){
	services(where:{categories:{category:$category}}){
    name
    image{
      url
    }
    description
    startingBasePriceInfo
    categories{
      color
      category
    }
  }
}
`;




export const GET_USERS_ADDRESS = gql`
query findAddress($username:String!){
  users(where:{ username:$username }){
    name
    id
    location{
      id
      street
      colonia
        postalCode
        city
        fullAdress
        name
        state
        extras
        default
        phoneNumber
    }
  }
}
`;


export const DIE_PM = gql`
query getPaymentMethod($id:ID!){
  user(id:$id){
    id
   card{
    stripeClient
     dpmid
     last4
     brand
   }
 }
 }
`;


export const ORDER_USER_CHECK = gql`
query orderInfo($orderNumber:String!){
  orders(where:{orderNumber:$orderNumber}){
    user{
      username
    }
  }
}

`;


export const ORDER_DATA = gql`
query orderData($orderNumber:String!){
  orders(where:{orderNumber:$orderNumber}){
    cartshop
    total
    status
    when
    deliveryPrice
    typeOfOrder
    fullLocation
    user{
      id
    }
  }
}

`;

export const ORDER_REPORT = gql`
query orderData($orderNumber:String!){
  orders(where:{orderNumber:$orderNumber}){
    reportProducts
    report
    signatureLinkC
    user{
      id
    }
  }
}

`;


export const USER_ORDERS = gql`
query clientsOrders($id:ID!){
  users(where:{id:$id}){
    name
    orders(sort: "createdAt:desc"){
      cartshop
      orderNumber
      status
      dateOrdered
      typeOfOrder
    }
  }
}

`;



export const GET_MONTH_PROMO = gql`
query getPromo($month: Int!){
  promomensuals(where:{month:$month}){
    titulo
    title1
    title2
    title3
    cta1
    cta2
    cta3
    mainCta
    backPhoto{
      url
    }
    firstphoto{
      url
    }
    secphoto{
      url
    }
    thirdphoto{
      url
    }
  }
}

`;

export const GET_PROJECTS_DONE = gql`
query getIt($name: String!){
  services(where:{name: $name}){
    projects_dones{
      client
      stars
      review
      gallery{
        url
      }
    }
    
  }
}
`;


export const GET_CRITICAL = gql`
query getCritical($name:String!){
  checklists(where:{service: {name:$name}}){
    service{
      name
    }
    checklist{
      criticalRoute
    }
  }
}
`;

export const GET_SERVICESBYCATANDTYPE = gql`
query miniServices($category:[String!]! , $type: String!){
	services(
    where:{
    categories:{category:$category},
    type:$type
    },
  ){
    name
    categories{
      category
      color
    }
    type
    image{
      url
    }
    
  }
}
`;



export const GET_PRODUCTS_RELATED_SUBCAT = gql`
query productsRelatedCat($subCategory:String!){
  products(where:{sub_category: {subcat:$subCategory}}){
    name
    id
    price
    images{
      url
    }
  }
}
`;

export const GET_FOUR_MOST_VIEWED = gql`
query mostViewed{
  mostVieweds(limit:4){
    service{
      name
    }
    imageSrc{
      url
    }
    startingPrice
    catchFrase
  }
}
`;


export const GET_LIST_ONE = gql`
query showcaseOne{
  listOnes{
    service{
      categories{
        color
      }
      name
      image{
        url
      }
      startingBasePriceInfo
    }
  }
}
`;


export const GET_LIST_TWO = gql`
query showcaseTwo{
  listTwos{
    service{
      categories{
        color
      }
      name
      image{
        url
      }
      startingBasePriceInfo
    }
  }
}
`;

export const GET_LIST_THREE = gql`
query showcaseThree{
  listThrees{
    product{
      name
      id
      price
      sub_category{
        subcat
      }
      timeDelivery{
        unit
        quantity
      }
      images{
        url
      }
    }
  }
}
`;

export const GET_SUBCAT = gql`
query subcats{
  subCategories{
    subcat
    image{
      url
    }
  }
}
`;

export const GET_HELPY_SELECT = gql`
query selections{
  helpyselections(limit:1){
    title
    catch
    imageOne{
      url
    }
    imageTwo{
      url
    }
     imageThree{
      url
    }
     imageFour{
      url
    }
     imageFive{
      url
    }
     imageSix{
      url
    }
     imageSeven{
      url
    }
    button
    ctaOne
    description
    ctaFinal
    subtitleFinal
    linkCta
    linkFinal
    products{
      id
      sub_category{
        subcat
      }
      name
      images{
        url
      }
      price
    }
  }
}
`;

export const GET_PROJECTS = gql`query{
  projectsDones{
    gallery{
      url
    }
    service{
      name
    }
  }
}`


export const ORDERS_PAID = gql`
query ordersPaid{
  orders(where: {status : ["paid" , "paymentPending"]}){
    id
    status
    when
    total
    fullLocation
    cartshop
    orderNumber
    user{
      name
    }
  }
}
`;


export const GET_CHALANES = gql`
query getChalanes($name:String!){
  users(where:{
   pro_worker:{name:$name},
   role:{name:"CompanyWorker"}
 }){
  id
  name
  role{
      name
  }
}
 }
`;


export const GET_ORDERS_TODO = gql`
query getSerToDo($id:ID! , $initWhen:DateTime! , $finitWhen: DateTime!){
  orders(where:{
    status:["acceptedProject", "done" , "cancel" , "Omw" , "building", "inProgress"], 
    pro_worker:{id:$id},
    when_gt:$initWhen,
    when_lt:$finitWhen
  }
  ){
    id
    status
    fullLocation
    cartshop
    total
    when
    eta
    paymentMethod
    pro_worker{
      name
      id
    }
    pro_selecteds{
      name
    }
    user{
      name
    }
  }
}
`;



export const GET_CLIENTS = gql`
query getClients{
  users(where:{
   role:{name:["Home", "Office"]}
 }){
  id
  username
  name
	location{
    street
    state
    extras
    colonia
    phoneNumber
    postalCode
    city
    fullAdress
    phoneNumber
  }
}
 }
`;

export const GET_CARTS = gql`
query findOneCart($idCart:Long!){
  carts(where:{ idCart:$idCart }){
  cartfull
  status
  id
  }
}
`

export const GET_RATES = gql`
query getRate($id:ID!){
  order(id:$id){
    status
    pro_selecteds{
      name
    }
    pro_worker{
      name
    }
    rate
    rateComent
  }
}
`


export const GET_WORKER_ORDERS = gql`
query getServicesOfWorker($id:ID!){
  orders(where:{
    status:["acceptedProject", "Omw" , "building", "inProgress", "reportMaking"], 
    pro_selecteds:{id:$id}
  }
  ){
    id
    status
    fullLocation
    cartshop
    orderNumber
    total
    report
    when
    reportProducts
    eta
    paymentMethod
    user{
      name
    }
    pro_selecteds{
      name
      id
    }
  }
}
`;


   
    
    
    
    
    
   