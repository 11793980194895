import React from 'react';

export default function AvisoPrivacidad() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose font-title mx-auto">
                    <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            AVISO DE PRIVACIDAD
                        </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        La política de privacidad de HELPY EL PULPO, S.A de C.V. en lo sucesivo HELPY ® así como los procedimientos para gestionar la información de clientes (usuarios) se ha creado comprendiendo y considerando que las tecnologías de la información están evolucionando y los métodos de negocio en Internet continúan desarrollándose para satisfacer las necesidades y oportunidades de las tecnologías incipientes.                     </p>
                </div>
                <div className="mt-6 px-24 text-gray-500 space-y-12 font-body mx-auto">
                    <p>
                        Como resultado, la política y procedimientos de HELPY ® están sujetos a posibles cambios, los cuales serán publicados en nuestra página de internet y en nuestras oficinas.

                        En cumplimiento con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares así como su Reglamento, en lo sucesivo la Ley y Reglamento, HELPY ®, hace de su conocimiento que protege y salvaguarda sus datos personales para evitar daños, pérdidas, destrucción, robo, extravío alteración así como el tratamiento no autorizado de los mismos, haciendo de su conocimiento los términos y condiciones del Aviso de Privacidad de Datos Personales (“Aviso de Privacidad”)
                    </p>
                    <p>
                        En la prestación de servicios a sus clientes (usuarios), HELPY ® adquiere, almacena, y transmite informes de comunicación, los cuales se consideran privados. HELPY ® no solicita ni almacena datos personales sensibles.

                        ** Datos personales sensibles son aquellos datos que afectan la esfera más íntima del titular o cuya utilización indebida puede dar origen a discriminación o conlleve un riesgo grave para el titular. Aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.
                    </p>


                    <h2>DATOS PERSONALES QUE RECABAMOS:</h2>
                    <p>
                        Al momento de contratar nuestros servicios, registrarse como usuarios o formar parte de nuestro equipo de proveedores de servicios, o en caso de que los datos sean obtenidos a través de cualquier medio lícito, se recabará lo siguiente: Datos de identificación: nombre completo, edad, fecha de nacimiento, domicilio, Clave Única de Registro de Población, direcciones de correos electrónicos, números telefónicos, nombre de usuario y contraseña, fotografía y huellas dactilares, nickname (sobrenombre), datos de redes sociales. Datos Financieros: nombre del tarjetahabiente, Información de tarjetas bancarias (débito y/o crédito / nacionales o internacionales) (últimos cuatro dígitos) y medios de pago tales como PAYPAL®, fecha de expiración (en caso de transacciones en línea). Datos de registros e información de empleados y prestadores de servicio, escolaridad, historial laboral, y nacionalidad, fotografía, número de seguridad social, registro patronal del IMSS. Registro de proveedores, prestadores de servicios y/o clientes de la empresa. Datos de Facturación para emisión de comprobantes fiscales digitales: nombre o razón o denominación o social, datos de inscripción ante el Registro Federal de Contribuyentes, domicilio fiscal, características conforme a la versión más actual de la factura del sistema autorizado por el SAT, información fiscal, seguimiento y cobranza, así como la información respecto de las cuentas bancarias, forma y método de pago. La información personal que proporciones a HELPY ® promueve la comunicación entre ofertantes y demandantes de los servicios y productos, además permite a nuestros usuarios de nuestra página web el poder recibir un enlace entre profesionales y clientes con mayor experiencia en el uso y servicios que ofrecemos, incluidos los servicios llave en mano.
                    </p>

                    <h2>FINALIDAD DE NUESTRO AVISO DE PRIVACIDAD</h2>
                    <p>
                        Sus datos personales serán utilizados de conformidad con nuestro Aviso de Privacidad. Se podrá hacer uso de sus datos personales para otras finalidades que no se encuentren de manera expresa en este Aviso de Privacidad, siempre que dichas finalidades sean compatibles con las descritas y se consideren análogas. Su consentimiento a este Aviso de Privacidad, además, autoriza de forma expresa a HELPY ® para transferir sus datos personales a terceros siempre que los datos sean utilizados para los fines señalados en el presente Aviso. ¿PARA QUÉ UTILIZAMOS SU INFORMACIÓN? Los datos personales que recopilamos de nuestros clientes y/o terceros que acceden a nuestros servicios vía telefónica, por escrito, a través de internet u algún otro medio digital, son destinados para:

                        Compartir la información de los visitantes al sitio web de HELPY ® con el objeto de crear y dirigir las estrategias de publicidad de HELPY ® en Internet o cualquier otro medio de comunicación. Para poner en contacto a las personas que visiten nuestro sitio de internet y adquieran el carácter de usuarios con terceros especialistas que les proporcionarán directamente sus servicios. Proporcionar información sobre nuestros servicios, envío de publicidad digital o impresa, y actualizaciones, promociones, boletines. Evaluaciones de calidad en el servicio. Perfeccionamientos de nuestros sistemas, procedimientos, servicios, y página web.
                    </p>

                    <h2>MENORES E INCAPACES</h2>
                    <p>
                        En HELPY® estamos comprometidos con la normatividad aplicable respecto de la privacidad de los menores de edad e incapaces; por ello, NO recabamos, ni almacenamos ni tratamos información relacionada con menores de edad e incapaces, salvo con el consentimiento expreso y por escrito de los padres o tutores del menor. Si usted es padre/madre o tutor de algún menor de edad que haya proporcionado sus datos personales sin su consentimiento, puede solicitar que dichos datos sean cancelados de conformidad con el procedimiento contenido en el presente aviso de privacidad.
                    </p>

                    <h2>MEDIDAS DE SEGURIDAD HELPY</h2>
                    <p>
                        helpy® cuenta con las medidas de seguridad tecnológicas, informáticas, y administrativas necesarias para la protección, limitación y custodia de sus datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. DERECHOS ARCO El ejercicio de los derechos de acceso, rectificación, cancelación y oposición (ARCO), así como los derechos de divulgación y limitación de uso; o la revocación del consentimiento, por parte del Titular de los datos podrán efectuarse, en cualquier momento, por escrito dirigido al departamento de datos personales de HELPY EL PULPO, S.A de C.V. (Responsable) a la cuenta de correo electrónico avisoprivacidad@helpy.mx y al domicilio ubicado en la calle de General Pedro María Anaya # 2248 Col. Progreso C.P. 64420 en Monterrey Nuevo León México, debiendo señalar nombre completo, personalidad con la que se ostenta, domicilio para oír y recibir notificaciones; y dirección de correo electrónico, documento con el que acredita ser el Titular de los datos personales, señalando los datos y derechos precisos objeto de la solicitud.
                    </p>



                    <h2>MODIFICACIONES AL AVISO DE PRIVACIDAD</h2>
                    <p>
                        Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad, para la atención de novedades legislativas o jurisprudenciales. Estas modificaciones estarán disponibles a través de los siguientes medios: (i) anuncios visibles, trípticos o folletos disponibles en nuestras oficinas; (ii) en https://helpy.mx/aviso-de-privacidad o (iii) o por cualquier otro medio autorizado.
                    </p>

                    <h2>COOKIES, WEB BEACONS Y OTRAS TECNOLOGÍAS</h2>
                    <p>
                        Nuestra página de internet https://helpy.mx sí como nuestra aplicación para teléfonos inteligentes utilizan cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet.

                        “Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias para la visualización de las páginas en ese servidor, nombre y contraseña”. **

                        Por su parte, las web beacons son imágenes insertadas en una página de Internet o correo electrónico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros.** “.

                        Si usted desea restringir o eliminar las cookies o web beacons de nuestro sitio web, por favor elija la opción adecuada en la configuración de su navegador de internet. ¿ANTE QUIÉN PUEDE PRESENTAR SUS QUEJAS Y DENUNCIAS POR EL TRATAMIENTO INDEBIDO DE SUS DATOS PERSONALES? Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestro equipo o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el INAI (Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales), para mayor información visite: http://inicio.ifai.org.mx/SitePages/ifai.aspx
                    </p>


                    <h2>CONTACTO</h2>
                    <p>
                        Con mucho gusto lo atenderemos respecto de sus preguntas al presente Aviso de Privacidad, por lo que ponemos a su disposición la siguiente cuenta de correo electrónico: avisoprivacidad@helpy.mx CONSENTIMIENTO Al no manifestar oposición por escrito, así como al suscribirse como usuario, se entiende que Usted ha leído y comprendido los términos y condiciones señalados en este Aviso de Privacidad; y expresa su consentimiento expreso y su entera conformidad con el tratamiento que hará HELPY ® de sus datos personales. Si Usted no está de acuerdo con los términos señalados en este Aviso de Privacidad, no proporcione sus datos personales a esta Sociedad por ningún medio o en su caso le pedimos iniciar las acciones correspondientes para que nuestra Empresa no realice el tratamiento de sus datos personales, si es que ya contábamos con ellos.

                        ** Definiciones obtenidas de la página de internet del INAI http://inicio.ifai.org.mx/SitePages/ifai.aspx

                        Fecha de actualización más reciente [14/01/2020]
                    </p>








                </div>
            </div>
        </div>
    )
}
