import React, { useState } from 'react'
import { useContext } from 'react'
import AuthContext from '../../Context/Auth/AuthContext'
import Divider from '../microComponents/Divider'
import { useLazyQuery } from '@apollo/client'
import { GET_USER } from '../../GraphQL/Queries'
import Loader from '../microComponents/Loader'
import { useHistory } from 'react-router-dom'


export default function LoginForm() {
    const history = useHistory()
    const [email, setEmail] = useState("")
    const { LoginUser, newUserTrue, tokenRecieved , setUserType} = useContext(AuthContext)


    const handleInputLogin = (mail) => {
        setEmail(mail.target.value)
    }

    const [findoneUser] = useLazyQuery(GET_USER)

    // Enviar al registro o a lo que sea hasta despues de que magic haya confimado sesión o no





    const logButton = async (ev) => {
        ev.preventDefault()

        const res = await findoneUser({ variables: { username: email } })
        // Verificar si existe en la DB
        if (res.data.users.length > 0) {
            if (res.data.users[0].name === null) {
                //usuario existente sin el registro 
                if(res.data.users[0].role.name === "CompanyPro"){
                    setUserType("CompanyPro")
                }else if(res.data.users[0].role.name === "CompanyWorker"){
                    setUserType("CompanyWorker")
                }else if (res.data.users[0].role.name === "Home"){
                    setUserType("Home")
                }

                LoginUser(email)
                newUserTrue(true)
            } else {
                //usuario existente
                if(res.data.users[0].role.name === "CompanyPro"){
                    setUserType("CompanyPro")
                }else if(res.data.users[0].role.name === "CompanyWorker"){
                    setUserType("CompanyWorker")
                }else if (res.data.users[0].role.name === "Home"){
                    setUserType("Home")
                }
                
                LoginUser(email)
            }
        } else {
            //usuario nuevo
            LoginUser(email)
            newUserTrue(true)
        }
    }


    return (
        <>

            {
                !tokenRecieved ?
                    <div className="pb-12 flex flex-col justify-center sm:px-6 lg:px-8 font-body">
                <div className="sm:mx-auto sm:w-full sm:max-w-md font-extrabold">

                    <img
                        className="mx-auto h-8 w-auto"
                        src="https://res.cloudinary.com/nein/image/upload/v1642617519/logo_p1usnu.svg"
                        alt="helpy"
                    />
                    <h2 className="mt-6 text-center text-xl font-title  text-gray-900">Bienvenido a helpy</h2>

                </div>
                <div className="flex  justify-center text-sm">
                    <p>Creando y mejorando tu espacio</p>
                </div>

                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white my-2 px-4 sm:rounded-lg sm:px-10">
                        <form className="space-y-2" onSubmit={(ev) => logButton(ev)}>
                            <div>

                                <div className="mt-1">
                                    <input
                                        onChange={(ev) => handleInputLogin(ev)}
                                        id="email"
                                        name="email"

                                        type="email"
                                        autoComplete="email"
                                        placeholder="tucorreo@mail.mx"
                                        required
                                        className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                                    />
                                </div>
                            </div>



                            <div>
                                {
                                    email.length > 1 ?
                                        <button
                                            type="submit"
                                            className="w-full flex justify-center py-4 mt-4 px-4 border border-transparent rounded-full shadow-sm text-md font-semibold text-black bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                                        >
                                            Continuar tus compras
                                        </button>
                                        :
                                        <button
                                            type="submit"
                                            className="w-full font-semibold flex justify-center py-4 mt-4 px-4 border border-transparent rounded-full shadow-sm text-md  text-gray-400 bg-yellow-100 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                                        >
                                            Continuar tus compras
                                        </button>
                                }

                            </div>
                        </form>
                        <div className="my-4">

                            <Divider />
                            <div className="mt-2">
                                <p className="text-xs font-light">Al iniciar sesión o realizar tu pedido, aceptas el aviso de privacidad y los términos y condiciones de helpy el pulpo sa de cv</p>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
                    :

                    <div className='mx-auto py-12'>
                        <Loader></Loader>
                    </div>

            }



        </>
    )
}

