import {useEffect} from 'react'
import {Fragment, useContext , useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Order from '../buyProcess/Order'
export default function ModalStatusUpdate({isOpen, setIsOpen, order, products , currentStatus,
    updateStatusOrder , fullorder , steps , setUpdatingOrder , reporMaker , setReporMaker}) {

        
    const [alert ,  setAlert] = useState({open: false, error: "null", color:"red"})
        
        // si el current status ya sigue subir el reporte tiene que hacer 

        const updateStatus = () => {

            let timeStamp = new Date()

            switch (currentStatus) {
                case 0:
                    updateStatusOrder({variables:{id:fullorder.id , status:steps[currentStatus + 1].status , timeGo:timeStamp}})
                    setIsOpen(false)
                    setUpdatingOrder({on:true , id: fullorder.id})
                  break;
                case 1:
                    updateStatusOrder({variables:{id:fullorder.id , status:steps[currentStatus + 1].status , timeArrived:timeStamp}})
                    setIsOpen(false)
                    setUpdatingOrder({on:true , id: fullorder.id})
                    break;
                case 2:
                    updateStatusOrder({variables:{id:fullorder.id , status:steps[currentStatus + 1].status, timeFinish: timeStamp}})
                    setIsOpen(false)
                    setUpdatingOrder({on:true , id: fullorder.id})
                    break;
                case 3:
                    setReporMaker(true);
                    break;
               
                default:
                    return
                
              }
            
    }
     
    return (
        <>
            {
                !reporMaker ?
                <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={isOpen}
                onClose={setIsOpen}>
                <div
                    className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay
                            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-10/12 md:w-1/2">

                            <div>

                                <div className="mt-3 font-title text-center sm:mt-5 ">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg  leading-6 font-medium text-left text-gray-900">
                                        Detalles de orden :
                                    </Dialog.Title>

                                    <div className=" text-left mt-2">
                                        <Order order={order} products={products}></Order>
                                    </div>

                                    <div>
                                    {/* <p className="text-left my-4 font-body">{currentStatus[0].mesage} </p> */}
                                    <button
                        name="cart"
                        type="button"
                                onClick={() => updateStatus()}
                        className=" items-center w-full bg-white text-center mt-2 py-2  border-transparent text-base rounded-md shadow-sm text-black   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 border-2 border-yellow-400"
                            >
                            siguiente
                       {/* {currentStatus[0].button} */}
                    </button>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

                :

        null


            
            }
        </>
        
    )
}
