import { XIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import ProjectOverview from './ProjectOverview'
import { useHistory } from 'react-router-dom'


export default function ProjectService({ data, loading }) {

    const [interesado, setInteresado] = useState(false)
    // const regex = /^[1-9]\d*/
    const history = useHistory()
    const [disableButton, setDisableButton] = useState(false)
    const [dataForm, setdataForm] = useState([])
    const [dataText, setDataText] = useState([])
    const [dataRadio, setDataRadio] = useState([])
    const [dataCheck, setdataCheck] = useState([])
    const [helpymatic, setHelpymatic] = useState(data.services[0].helpymatic.filter((a) => a.boolDependent === null))
    const [total, setTotal] = useState(0)
    const [reload, setReload] = useState(0)
    const [req, setReq] = useState([{
        service: null,
        text: null,
        radio: null,
        check: null,
        number: null,
        price: 0,
        photo: null
    }])



    const handleChanges = (ev) => {
        setDataRadio(
            {
                ...dataRadio,
                [ev.target.name]: Object.values(ev.target).map(e =>
                    e.value
                )[1]
            }
        )
    }

    const handleInputs = (ev, min, max, over) => {

        
            setdataForm(
                {
                    ...dataForm,
                    [ev.target.name]: {
                        "value": ev.target.value,
                        "regularPrice": max,
                        "offPrice": min,
                        "overThis": over
                    }
                    
                }
                )
            

    }

    const handleText = (ev) => {
        setDataText(
            {
                ...dataText,
                [ev.target.name]: ev.target.value
            }
        )
    }

    const handleCheck = (ev, id) => {

        if (ev.target.checked) {
            setdataCheck([
                ...dataCheck,
                {
                    id,
                    "question": ev.target.name,
                    "option": Object.values(ev.target).map(e =>
                        e.value
                    )[1].option,
                    "price": Object.values(ev.target).map(e =>
                        e.value
                    )[1].price
                }
            ])

        } else {
            setdataCheck(
                dataCheck.filter((option) => option.id !== id)
            )
        }



    }

    useEffect(() => {

        const subSequent = data.services[0].helpymatic.filter((a) => a.boolDependent !== null)
        const arrayRadios = Object.values(dataRadio)


        if (arrayRadios.length !== 0 && subSequent.length !== 0) {

            const indexArrayComplete = subSequent[0].boolDependent
            const questionToCompare = data.services[0].helpymatic[indexArrayComplete].question
            const indexInArray = arrayRadios.map(e => e.question).indexOf(questionToCompare)


            if (indexInArray !== -1) {
                const optionSelected = arrayRadios[indexInArray].option


                subSequent.map((question) => {

                    let questionIf = question.correctAnswerToShow


                    if (questionIf === optionSelected && !helpymatic.includes(question)) {
                        setHelpymatic([...helpymatic, question])
                        setReload(reload + 1)
                    } else if (questionIf !== optionSelected && helpymatic.includes(question)) {
                        setHelpymatic(helpymatic.filter((a) => a.question !== question.question))
                        setReload(reload - 1)
                    }


                })





            }





        }

    }, [data, dataRadio, reload])



    useEffect(() => {

        const checkTotal = dataCheck.reduce((a, b) => { return a + b.price }, 0)
        const radioSub = Object.values(dataRadio).map((e) => {
            if (e.IndexPriceDependent !== null && Object.values(dataForm).length !== 0) {
                return e.price * parseInt(Object.values(dataForm)[0].value)
            } else {
                return e.price
            }
        }, 0)

        const radioTotal = radioSub.reduce((a, b) => { return a + b }, 0)
        const arrInputs = Object.values(dataForm).map(e => e.value > e.overThis ? e.value * e.regularPrice : e.value * e.offPrice)
        const sumImputs = arrInputs.reduce((a, b) => { return a + b }, 0)

        setTotal(checkTotal + data.services[0].basePrice + radioTotal + sumImputs)

        setReq([{
            service: data.services[0].name,
            category: data.services[0].categories.map(e => e.category),
            text: dataText,
            radio: dataRadio,
            check: dataCheck,
            number: dataForm,
            price: checkTotal + data.services[0].basePrice + radioTotal + sumImputs,
            photo: data.services[0].image
        }])

        

    }, [dataCheck, dataRadio, dataForm, dataText])










    const handlePayButton = (e) => {
        e.preventDefault()
        const reqId = Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)
        setDisableButton(true)
        setTimeout(() => history.push(`/request/${reqId}`, { requestData: req }), 1000)

    }

    return (
        <>

            {
                !interesado ?


                    <ProjectOverview data={data} loading={loading} setInteresado={setInteresado} interesado={interesado}></ProjectOverview>
                    :

                    <>

                        <form onSubmit={handlePayButton}>

                            <div className="bg-white max-w-xl  sm:w-3/4 mx-auto overflow-hidden shadow rounded-lg">

                                <div className="flex justify-end p-5" >

                                    <button
                                        type="button"

                                        onClick={() => setInteresado(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="px-4">
                                    {
                                        !loading ?

                                            <div className="max-w-xl mx-auto pb-8 px-4 sm:py-8 sm:px-6 lg:px-8 ">
                                                <div className="max-w-xl">
                                                    <h3 className="text-2xl font-title text-gray-900 tracking-tight sm:text-3xl">
                                                        {data.services[0].name}
                                                    </h3>
                                                    <p className="text-xs truncate">{data.services[0].description}</p>
                                                </div>
                                                <div>
                                                    <ul>
                                                        {
                                            helpymatic.map((helpymaticEl, identif) => {
                                                return (
                                                    <div key={identif} className="mt-5">

                                                        <li className="font-medium my-2">{helpymaticEl.question}</li>
                                                        {
                                                            helpymaticEl.type === "number" ?
                                                                <div>
                                                           
                                                                    <input onChange={ev => handleInputs(ev, helpymaticEl.priceInput, helpymaticEl.priceDiscountInput, helpymaticEl.overThisDiscount)}

                                                                        type={helpymaticEl.type}
                                                                        name={helpymaticEl.question}
                                                                        min={1}
                                                                        onKeyPress={(e) => {
                                                                                        if (e.key === "e" || e.key === "-") {
                                                                                        e.preventDefault();
                                                                                        }
                                                                        }}
                                                                        required
                                                                        placeholder={helpymaticEl.type}
                                                                        className="h-10 w-full rounded-md mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                                                                </div>
                                                                :
                                                                helpymaticEl.type === "text" ?
                                                                    <div>
                                                                        <input onChange={handleText}
                                                                            type={helpymaticEl.type}
                                                                            required
                                                                            name={helpymaticEl.question}
                                                                            placeholder={helpymaticEl.type}
                                                                            className="h-10 w-full rounded-md mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" />

                                                                    </div>
                                                                    :

                                                                    helpymaticEl.type === "checkbox" ?

                                                                        <div className={`${helpymaticEl.answers[0].image.length ? "grid grid-cols-2 " : " "}`}>

                                                                            {

                                                                                helpymaticEl.answers.map((element, id) => {

                                                                            return <fieldset key={id} >
                                                                                <div className="bg-red rounded-md -space-y-px">
                                                                                    <label className={`${element.image.length !== 0 ? " " : "border border-gray-200 rounded-md "}   relative  p-4 flex flex-col cursor-pointer`}>
                                                                                        {
                                                                                            element.image.length !== 0 ?
                                                                                                <div className='block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100'>
                                                                                                    <img className='object-cover rounded-lg' src={element.image[0].url} alt="" />
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        <div className='flex flex-row'>

                                                                                        <input onChange={(ev) => handleCheck(ev, id)}
                                                                                                type={helpymaticEl.type}
                                                                                                name={helpymaticEl.question}
                                                                                            value={element}
                                                                                            required={!dataCheck.length ? true : false}
                                                                                            className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description" />
                                                                                        <div className="ml-3 flex flex-col">
                                                                                            <span id="privacy-setting-0-label" className="text-gray-900 block text-sm font-medium">
                                                                                                {element.option}
                                                                                            </span>
                                                                                        </div>
                                                                                        </div>
                                                                                        {

                                                                                            element.description !== null ?
                                                                                                <p className='font-body text-gray-600  text-xs'>{element.description}</p>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </fieldset>


                                                                        })

                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className={`${helpymaticEl.answers[0].image.length ? "grid grid-cols-2 " : " "}`}>


                                                                            {

                                                                                helpymaticEl.answers.map((element, id) => {
                                                                                    return <fieldset key={id} >


                                                                                        <div className="rounded-md -space-y-px">
                                                                                            <label className={`${element.image.length !== 0 ? " " : "border border-gray-200 rounded-md "}   relative  p-4 flex flex-col cursor-pointer`}>

                                                                                                {
                                                                                                    element.image.length !== 0 ?
                                                                                                        <div className='block   rounded-lg bg-gray-100'>
                                                                                                            <img className='object-cover rounded-lg' src={element.image[0].url} alt="" />
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                }

                                                                                                <div className='flex flex-row'>

                                                                                                    <input onChange={handleChanges}
                                                                                                        key={element.id}
                                                                                                        type={helpymaticEl.type}
                                                                                                        name={helpymaticEl.question}
                                                                                                        value={{ "option": element.option, "price": element.price, "image": element.image, "boolDependent": helpymaticEl.boolDependent, "IndexNewQuestion": helpymaticEl.correctAnswerToShow, "IndexPriceDependent": helpymaticEl.priceDependent, "question": helpymaticEl.question }}
                                                                                                        required
                                                                                                        className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description" />


                                                                                                    <div className="ml-3">
                                                                                                        <span id="privacy-setting-0-label" className="text-gray-900 block text-sm font-medium">
                                                                                                            {element.option}
                                                                                                        </span>
                                                                                                    </div>


                                                                                                </div>
                                                                                                {

                                                                                                    element.description !== null ?
                                                                                                        <p className='font-body text-gray-600  text-xs'>{element.description}</p>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </label>
                                                                                        </div>
                                                                                    </fieldset>
                                                                                })}


                                                                        </div>


                                                        }
                                                    </div>
                                                )
                                            })}

                                                    </ul>



                                                </div>


                                            </div>
                                            :

                                            <h1>
                                                Cargando...
                                            </h1>
                                    }
                                </div>

                            </div>

                            <div className="max-w-xl mx-auto">

                                {
                                    disableButton ?
                                        <button
                                            name="pay"
                                            className="disabled cursor-not-allowed items-center w-full text-center mt-2 py-2 border border-transparent text-base  rounded-md shadow-sm text-white bg-yellow-100 "
                                        >
                                            Cargando ...
                                        </button>
                                        :
                                        <button
                                            name="pay"
                                            className=" items-center w-full text-center mt-2 py-2 border border-transparent text-base  rounded-md shadow-sm text-white bg-yellow-400 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
                                        >
                                            Ver estimado
                                        </button>
                                }



                            </div>

                        </form>



                    </>


            }





        </>
    )
}
