/* eslint-disable import/no-anonymous-default-export */
import { LOGIN_USER, LOG_OUT, NEW_ADDRESS, PRE_USER, FAILED_LOGIN, CHECK_LOGIN_USER, ID_USER, NEW_USER_TRUE, LOADING_MAGIC_USER, TOKEN_RECIEVED, SET_USER_TYPE, SET_USER_SELECTED_ADMIN } from "../types";

export default (state, action) => {

    const { payload, type } = action

    switch (type) {
        case LOADING_MAGIC_USER:
            return {
                ...state,
                loadingMagic: payload
            }

        case NEW_ADDRESS:
            return {
                ...state,
                address: payload
            }
        case PRE_USER:
            return {
                ...state,
                preUser: payload
            }
        case LOGIN_USER:
            return {
                ...state,
                user: payload
            }
        case FAILED_LOGIN:
            localStorage.removeItem('token')
            return {
                ...state,
                logged: false,
                user: null
            }
        case LOG_OUT:
            localStorage.removeItem('token')
            return {
                ...state,
                logged: false,
                user: null,
                id: null,
                tokenRecieved: false,
                preUser: {
                    name: "",
                    email: " ",
                    phone: "",
                    rfc: "",
                    password: "",
                    companyName: "",
                    credit: false,
                    company: false
                },
                newUser: false,
            }
        case CHECK_LOGIN_USER:
            return {
                ...state,
                logged: true,
                user: payload
            }
        case ID_USER:
            return {
                ...state,
                id: payload
            }
        case NEW_USER_TRUE:
            return {
                ...state,
                newUser: payload
            }
        case TOKEN_RECIEVED:
            return {
                ...state,
                tokenRecieved: true
            }
        case SET_USER_TYPE:
            return {
                ...state,
                userType: payload
            }
        case SET_USER_SELECTED_ADMIN:
            return {
                ...state,
                userSelectedByAdmin: payload
            }

        default:
            return state

    }
}
