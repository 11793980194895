import { useQuery } from '@apollo/client';
import { SearchIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { GET_PRODUCTS_NAMES, GET_PRODUCTS_NAMES_V2, GET_SERVICES } from '../../GraphQL/Queries';


export default function Searchbar() {

    //Queries
    const { data } = useQuery(GET_SERVICES);
    const history = useHistory()
    const [result, setResult] = useState(undefined)
    const [prodAndService, setProdAndService] = useState(null)
    const [searching, setSearching] = useState(false)
    const products = useQuery(GET_PRODUCTS_NAMES)
    const productsPlus = useQuery(GET_PRODUCTS_NAMES_V2)

    useEffect(() => {
        if (data !== undefined && products.data !== undefined) {
            const produc = products.data.products.map((e) => { return { name: e.name, type: "product", "id": e.id, "subcat": e.sub_category.subcat } })
            const produce2 = productsPlus.data.products.map((e) => { return { name: e.name, type: "product", "id": e.id, "subcat": e.sub_category.subcat } })
            const services = data.services.map((e) => { return { name: e.name, type: "service", keyword: e.keyword } })
            const prodAndServices = [...services, ...produc, ...produce2]
            setProdAndService(prodAndServices)
        }

    }, [data, products.data])
    //Handle changes
    const handleChanges = (ev) => {
        if (ev.target.value.length > 0) {
            setSearching(true)
            if (prodAndService !== null) {
                const filtered = prodAndService.filter(e => {
                    if (e.type === "service" && e.keyword !== null) {
                        if (e.keyword.toLowerCase().includes(ev.target.value.toLowerCase())) {
                            return e.name
                        }

                    } else {
                        return e.name.toLowerCase().includes(ev.target.value.toLowerCase())
                    }
                })
                setResult(filtered)
            }
        } else {
            setSearching(false)
        }
    }


    return (
        <>
            <div className="w-full">


                


                <div className="mt-1 w-full relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <div>
                        <input
                    onChange={handleChanges}
                            name="search" id="search" className="block w-full pl-10 py-3 text-base rounded-md placeholder-gray-600 shadow-lg border border-black" placeholder="Instalar minisplit" />
            {
                searching ?
                                <div className='w-28'>

                                    <div className="bg-white absolute w-96 z-10 shadow overflow-hidden sm:rounded-md">

                                        {
                                            result !== undefined ?
                                                result.map((e, id) => {
                                                    if (e.type === "service") {
                                                        return <ul className="divide-y divide-gray-200 " key={id}>

                                                            <Link to={`/servicio/${e.name}`}>
                                                                <li
                                                                    className="py-4 flex  hover:bg-yellow-100
                                                                cursor-pointer">

                                                                    <div className="ml-3 ">
                                                                        <p className="text-md font-medium text-gray-900">{e.name}</p>
                                                                    </div>
                                                                </li>
                                                            </Link>
                                                        </ul>

                                                    }
                                                    else if (e.type === "product") {
                                                        return <ul className="divide-y divide-gray-200 " key={id}>

                                                            <Link to={`/product/${e.name}/${e.subcat}/${e.id}`}>
                                                                <li
                                                                    className="py-4 flex  hover:bg-yellow-100
                                                                cursor-pointer">

                                                                    <div className="ml-3 ">
                                                                        <p className="text-md font-medium text-gray-900">{e.name}</p>
                                                                    </div>
                                                                </li>
                                                            </Link>
                                                        </ul>
                                                    }
                                })
                                :
                                <li
                                                    className="py-4 flex  hover:bg-yellow-100
                                        cursor-pointer">

                                    <div className="ml-3 ">
                                        <p className="text-md font-medium text-gray-900">Cargando...</p>
                                    </div>
                                </li>
                        }

                    </div>
                                </div>

                    :
                    null
            }
                    </div>
                </div>
            </div>








        </>
    )
}


