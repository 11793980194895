import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Loader from '../microComponents/Loader'
import RequestList from '../microComponents/RequestList'
import {
    CheckIcon
} from '@heroicons/react/solid'
import { useMutation, useQuery } from '@apollo/client'
import { GET_SERVICESBYCATANDTYPE, GET_SERVICES_FEATURES } from '../../GraphQL/Queries'
import RelatedServices from '../microComponents/RelatedServices'
import { NEW_LEAD } from '../../GraphQL/Mutations'
import AuthContext from '../../Context/Auth/AuthContext'
import ReactMarkdown from 'react-markdown'
import ModalContext from '../../Context/Modal/ModalContext'




export default function RequestPreview() {

    const location = useLocation()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [mailValid, setMailValid] = useState("")
    const [showPrice, setShowPrice] = useState(false)
    const [description, setDescription] = useState("")
    const [cheklist, setChecklist] = useState([
        {

            list: 'Atención personalizada'
        },
        {

            list: 'Asistencia 24/7 **'
        },
        {

            list: 'Seguimiento en tiempo real'
        },
        {

            list: 'Asesoría del proyecto'
        }
    ])
    const prices = {
        min: location.state.requestData[0].price ,
        max: location.state.requestData[0].price * 1.27
    }

    const { user, loadingMagic, logged } = useContext(AuthContext)
    const { setInteresade } = useContext(ModalContext)
    const { data } = useQuery(GET_SERVICES_FEATURES, { variables: { name: location.state.requestData[0].service } })
    const miniservice = useQuery(GET_SERVICESBYCATANDTYPE, { variables: { type: "miniService", category: location.state.requestData[0].category[0] } })
    const [newLead] = useMutation(NEW_LEAD)
    const frases = [
        { title: "Calculando precios" },
        { title: "Buscando alternativas" },
        { title: "Invocando descuentos" },
        { title: "Creando cotización" }
    ]
    const [index, setIndex] = useState(0)

    setTimeout(() => setLoading(false), 2000)
    setTimeout(() => {
        if (index < frases.length - 1) {
            setIndex(index + 1)
        } else {
            setIndex(0)
        }
    }, 1000)

    useEffect(() => {
        if (data !== undefined) {
            setChecklist(data.services[0].feature.listOfFeatures)
            setDescription({ "description": data.services[0].description, "disclaimer": data.services[0].disclaimer })
        }
    }, [data])

    useEffect(() => {
        if (!loadingMagic && logged) {
            setMailValid(user)
        } 
    }, [loadingMagic, logged])

    const revealPrices = (event) => {
        event.preventDefault()
        if (mailValid !== "") {
            localStorage.setItem("mail", mailValid)
            setShowPrice(true)
            newLead({ variables: { service: location.state.requestData[0].service, request: location.state.requestData[0], email: mailValid } })
        }

    }


    const goBackAndTrue = () => {
        history.goBack()
        setInteresade(true)
    }


    useEffect(() => {
        const mail = localStorage.getItem('mail')

        if (mail !== null) {

            if (mail.length !== 0) {
                setMailValid(mail)
            }
        }
    }, [mailValid])

    return (
        <>
            {
                loading ?
                    <div className='text-center mt-24 py-48'>

                        <Loader />


                        <h1 className='mt-4 font-body text-gray-600' >{frases[index].title}</h1>

                    </div>
                    :
                    <>

                        <div className="min-h-full">


                            <main className="py-10">
                                {/* Page header */}
                                <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                                    <div className="flex items-center space-x-5">
                                        <div className="flex-shrink-0">
                                            <div className="flex-shrink-0 h-20 w-20 bg-gray-100 rounded-full shadow ">
                                                <div className='w-full h-full flex justify-center items-center'>
                                                    <img className=" w-3/4" src={location.state.requestData[0].photo.url} alt={location.state.requestData[0].service} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='font-title'>
                                            <h1 className="text-2xl font-bold text-gray-900">{location.state.requestData[0].service}</h1>
                                            <p className="text-sm font-medium text-gray-500">
                                                A un paso de crear y mejorar tu espacio
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        !showPrice ?
                                            <div>
                                        <form onSubmit={revealPrices} className='mt-6 lg:flex font-body lg:flex-row lg:space-x-2 space-y-2 lg:space-y-0'>
                                            <input
                                                required
                                                className=' w-full border-gray-300 rounded-md text-sm '
                                                type="email"
                                                onChange={(ev) => setMailValid(ev.target.value)}
                                                        placeholder={mailValid ? mailValid : "Ingresa un correo"}
                                                        value={mailValid}
                                                    />

                                            <button
                                                type="submit"
                                                className="inline-flex items-center justify-center w-full  px-4 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-yellow-300"
                                            >
                                                Revelar precio
                                            </button>
                                        </form>
                                        <p className='text-xs mt-2'>Prometemos usar tu correo como si fuera nuestro</p>
                                    </div>
                                            :

                                            null
                                    }

                                </div>




                                {
                                    showPrice ?


                                        <>
                                            <div className=" mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                                                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                                    {/* Description list*/}
                                                    <section aria-labelledby="applicant-information-title">
                                                        <div className="bg-white shadow sm:rounded-lg">
                                                            <div className="px-4 py-5 sm:px-6">
                                                                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                                    Detalles de la estimación
                                                                </h2>
                                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Al contratar recibes garantía de calidad , atención personalizada y seguimiento en tiempo real</p>
                                                            </div>





                                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                                <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-medium text-gray-500">Precio</dt>
                                                                        <dd className="mt-1 text-xl font-medium text-gray-900">{prices.min.toLocaleString() + " - " + prices.max.toLocaleString()}<span className='text-sm font-base'> mxn</span> </dd>
                                                                    </div>

                                                                    <div className="mx-auto relative -top-3 flex w-full flex-col lg:hidden">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => goBackAndTrue()}
                                                                            className="
                                                                            mb-2 inline-flex items-center justify-center px-4 py-2 border border-yellow-300 text-sm font-medium rounded-md shadow-sm text-black bg-white"
                                                                        >
                                                                            Editar respuestas
                                                                        </button>
                                                                        <a
                                                                            href='https://calendly.com/helpymx/30min'
                                                                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-yellow-300 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                                                        >
                                                                            Agenda tu visita técnica
                                                                        </a>
                                                                        <p className='text-xs mt-1'>Estimado basado en respuestas precios pueden variar</p>

                                                                    </div>

                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-medium text-gray-500">Asesor asignado</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">Arq. Nahum Villegas</dd>
                                                                        <dd className="mt-1 text-sm text-gray-900">nahum@helpy.mx</dd>
                                                                    </div>
                                                                    <div className="sm:col-span-2">
                                                                        <dt className="text-sm font-medium text-gray-500">Descripción</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">

                                                                            <ReactMarkdown>{description.description}</ReactMarkdown>


                                                                        </dd>
                                                                    </div>
                                                                    <div className="sm:col-span-2">
                                                                        <dt className="text-sm font-medium text-gray-500">Alcances</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">
                                                                            <ReactMarkdown>{description.disclaimer}</ReactMarkdown>
                                                                        </dd>
                                                                    </div>
                                                                    <div className="sm:col-span-2">
                                                                        <dt className="text-sm font-medium text-gray-500 mb-4">Tu selección</dt>
                                                                        <RequestList order={location.state.requestData}></RequestList>

                                                                    </div>
                                                                </dl>
                                                            </div>

                                                        </div>
                                                    </section>

                                                    {/* Comments*/}

                                                </div>

                                                <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                                                    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                                        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                                                            ¿Qué incluye?
                                                        </h2>

                                                        {/* Activity Feed */}
                                                        <div className="mt-6 flow-root">
                                                            <ul className="-mb-8">
                                                                {

                                                                    cheklist.map((item, itemIdx) => {

                                                                        return (
                                                                            <li key={itemIdx}>
                                                                                <div className="relative pb-8">
                                                                                    {
                                                                                        itemIdx !== cheklist.length - 1 ? (
                                                                                <span
                                                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                        )
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    <div className="relative flex space-x-3">
                                                                                <div>
                                                                                    <span
                                                                                        className='h-8 w-8 rounded-full flex  bg-green-400 items-center justify-center'
                                                                                    >
                                                                                        <CheckIcon className="w-5 h-5  text-white" aria-hidden="true" />
                                                                                    </span>
                                                                                </div>
                                                                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                                                    <div>
                                                                                        <p className="text-sm text-gray-800">
                                                                                                    {item.list}

                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                        )

                                                                    })


                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="mt-6 flex flex-col justify-stretch">
                                                            <button
                                                                type="button"
                                                                onClick={() => goBackAndTrue()}
                                                                className="
                                                                            mb-2 inline-flex items-center justify-center px-4 py-2 border border-yellow-300 text-sm font-medium rounded-md shadow-sm text-black bg-white"
                                                            >
                                                                Editar respuestas
                                                            </button>
                                                            <a
                                                                href='https://calendly.com/helpymx/30min'
                                                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-yellow-300 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                                            >
                                                                Agendar cita
                                                            </a>
                                                            <p className='text-xxs mt-1'>Estimado basado en respuestas, precios pueden variar en la visita</p>

                                                        </div>
                                                    </div>
                                                </section>

                                            </div>

                                            {
                                                miniservice !== undefined ?
                                                    <div className='px-4 sm:px-6 md:px-32 lg:px-36  mx-auto mt-2'>
                                                        <h3 className="text-md  py-2 font-medium text-gray-900 tracking-tight">
                                                            Te puede interesar:
                                                        </h3>
                                                        <RelatedServices data={miniservice.data}></RelatedServices>
                                                    </div>
                                                    :

                                                    null
                                            }

                                        </>
                                        :

                                        <>
                                            <div className=' absolute w-full lg:mt-8 h-3/4 z-10 bg-white bg-opacity-20 backdrop-filter backdrop-blur-sm'>

                                            </div>
                                            <div className=" mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                                                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                                    {/* Description list*/}
                                                    <section aria-labelledby="applicant-information-title">
                                                        <div className="bg-white shadow sm:rounded-lg">
                                                            <div className="px-4 py-5 sm:px-6">
                                                                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                                    Información muestra
                                                                </h2>
                                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Para ver el precio , ingresa un correo.</p>
                                                            </div>
                                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-medium text-gray-500">¿Te interesa trabajar con nosotros?</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">Front end Developer</dd>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-medium text-gray-500">Manda tu correo</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">info@helpy.mx</dd>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-medium text-gray-500">Salario</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">$20,000</dd>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-medium text-gray-500">Teléfono</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">+811 500 0527</dd>
                                                                    </div>
                                                                    <div className="sm:col-span-2">
                                                                        <dt className="text-sm font-medium text-gray-500">Si te interesa {location.state.requestData[0].service}</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">
                                                                            Ingresa tu mail para ver el precio y la información.
                                                                        </dd>
                                                                    </div>

                                                                </dl>
                                                            </div>

                                                        </div>
                                                    </section>

                                                    {/* Comments*/}

                                                </div>

                                                <section aria-labelledby="timeline-title" className="hidden lg:block lg:col-start-3 lg:col-span-1">
                                                    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                                        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                                                            cheklist
                                                        </h2>

                                                        {/* Activity Feed */}
                                                        <div className="mt-6 flow-root">
                                                            <ul className="-mb-8">
                                                                {
                                                                    cheklist.map((item, itemIdx) => {
                                                                        return (
                                                                            <li key={itemIdx}>
                                                                                <div className="relative pb-8">
                                                                                    {

                                                                                        itemIdx !== cheklist.length - 1 ? (
                                                                                <span
                                                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                        )

                                                                                            :

                                                                                            null

                                                                                    }
                                                                                    <div className="relative flex space-x-3">
                                                                                <div>
                                                                                    <span
                                                                                        className='h-8 w-8 rounded-full flex  bg-green-400 items-center justify-center'
                                                                                    >
                                                                                        <CheckIcon className="w-5 h-5  text-white" aria-hidden="true" />
                                                                                    </span>
                                                                                </div>
                                                                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                                                    <div>
                                                                                        <p className="text-sm text-gray-800">
                                                                                                    {item.list}

                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                        )

                                                                    })}
                                                            </ul>
                                                        </div>
                                                        <div className="mt-6 flex flex-col justify-stretch">
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                            >
                                                                Advance to offer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </section>

                                            </div>
                                        </>
                                }


                            </main>
                        </div>

                    </>


            }
        </>
    )
}
