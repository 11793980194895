import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

const company = [
    { name: 'Aire acondicionado', imageUrl: "../img/iconos/g-aire-acondicionado.png" },
    { name: 'Albañilería', imageUrl: "../img/iconos/g-albanileria.png" },
    { name: 'Baño', imageUrl: "../img/iconos/g-banos.png" },
    { name: 'Calefacción', imageUrl: "../img/iconos/g-carpinteria.png" },
    { name: 'Carpintería', imageUrl: "../img/iconos/g-carpinteria.png" },
    { name: 'Cerrajero', imageUrl: "../img/iconos/g-cerrajero.png" },
    { name: 'Cocina', imageUrl: "../img/iconos/g-cocina.png" },
    { name: 'Electricidad', imageUrl: "../img/iconos/g-electricidad.png" },
    { name: 'Electrodomésticos', imageUrl: "../img/iconos/g-electrodomesticos.png" },

]

const resources = [
    { name: 'Fumigación', imageUrl: "../img/iconos/g-fumigacion.png" },
    { name: 'Handyman', imageUrl: "../img/iconos/g-handyman.png" },
    { name: 'Jardín', imageUrl: "../img/iconos/g-jardineria.png" },
    { name: 'Mudanza y Fletes', imageUrl: "../img/iconos/g-flete.png" },
    { name: 'Pintura', imageUrl: "../img/iconos/g-pintura.png" },
    { name: 'Pisos', imageUrl: "../img/iconos/g-pisos.png" },
    { name: 'Plomería', imageUrl: "../img/iconos/g-plomeria.png" },
    { name: 'Puertas y ventanas', imageUrl: "../img/iconos/g-puertas.png" },
    { name: 'Remodelación', imageUrl: "../img/iconos/g-remodelacion.png" },
    { name: 'Seguridad', imageUrl: "../img/iconos/g-seguridad.png" },
]

const products = [
    { name: 'Mascota' },
    { name: 'Organización' },
    { name: 'Fitness' },
    { name: 'Hogar' },
    { name: 'Decoración' },
    { name: 'Iluminación' },
    { name: 'Plomería' },
    { name: 'Clósets' },
    { name: 'Home office' },
]




const blogPosts = [
    {
        id: 1,
        name: '6 Reparaciones de casas que no debes dejar de hacer',
        href: 'https://elblog.helpy.mx/6-reparaciones-de-casas-que-no-debes-dejar-de-hacer/',
        preview: 'Una vez al año para evitar estos problemas a futuro como..',
        imageUrl:
            'https://elblog.helpy.mx/wp-content/uploads/2022/04/support-service-concept-toolbox-with-tools-2048x1365.jpg',
    },
    {
        id: 2,
        name: 'El minimalismo está de moda',
        href: 'https://elblog.helpy.mx/el-minimalismo-esta-de-moda/',
        preview: 'Un nuevo año trae nuevos diseños para el hogar y ..',
        imageUrl:
            'https://elblog.helpy.mx/wp-content/uploads/2022/01/hutomo-abrianto-9ZvuWg8deho-unsplash.jpg',
    },
    {
        id: 2,
        name: 'Los mejores acabados de pintura para cada habitación',
        href: 'https://elblog.helpy.mx/el-minimalismo-esta-de-moda/',
        preview: 'Ha llegado el momento de pintar tus espacios y uno de los primeros consejos ...',
        imageUrl:
            'https://elblog.helpy.mx/wp-content/uploads/2021/11/ashley-byrd-yzkTCP4uc9E-unsplash.jpg',
    },
]


export default function MenuFly({ clase }) {



    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <div className="relative z-10 bg-white ">
                        <div className="max-w-7xl mx-auto flex px-4 pt-5 sm:px-6 lg:px-8">
                            <Popover.Button
                                className={classNames(
                                    open ? 'text-gray-900' : 'text-gray-500',
                                    'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                )}
                            >
                                <span>Categorías</span>
                                <ChevronDownIcon
                                    className={classNames(
                                        open ? 'text-gray-600' : 'text-gray-400',
                                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                                    )}
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                    >

                        <Popover.Panel className={"absolute shadow-lg " + clase}>
                            <div className="absolute inset-0 flex" aria-hidden="true">
                                <div className="bg-white w-1/2" />
                                <div className="bg-gray-50 w-1/2" />
                            </div>
                            <div className="relative max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3">
                                <nav
                                    className="grid gap-y-5 px-4 pb-8 pt-0 col-span-2 bg-white sm:grid-cols-3 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12"
                                    aria-labelledby="solutions-heading"
                                >
                                    <h2 id="solutions-heading" className="sr-only">
                                        Service menu
                                    </h2>
                                    <div>
                                        <h3 className="text-sm pb-2 mt-4 md:mt-0 font-medium tracking-wide text-gray-500 uppercase">Servicios</h3>
                                        <ul className="space-y-5 mt-3">
                                            {company.map((item, id) => (
                                                <li key={id} className="flow-root">
                                                    <Link to={`/categoria/${item.name}`}>
                                                        <p
                                                            href={item.href}
                                                            className="-m-3 p-2 flex text-sm items-center rounded-md font-body  text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                                        >
                                                            <span className="flex-shrink-0 h-8 w-8 text-gray-300" aria-hidden="true"><img src={item.imageUrl} alt="" /></span>
                                                            <span className="ml-4">{item.name}</span>
                                                        </p>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='mt-4 sm:mt-0'>
                                        <ul className="space-y-5">
                                            {resources.map((item, id) => (

                                                <li key={id} className="flow-root">
                                                    <Link to={`/categoria/${item.name}`}>
                                                        <p
                                                            href={item.href}
                                                            className="-m-3 p-2 flex text-sm  items-center rounded-md font-body  text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                                        >
                                                            <span className="flex-shrink-0 h-8 w-8 text-gray-300" aria-hidden="true"><img src={item.imageUrl} alt="" /></span>
                                                            <span className="ml-4">{item.name}</span>
                                                        </p>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <h3 className="text-sm font-medium pb-2 tracking-wide text-gray-500 uppercase">Productos</h3>
                                        <ul className=" space-y-5 mt-3 ">
                                            {products.map((item, id) => (

                                                <li key={id} className="flow-root">
                                                    <Link to={`/esentials/${item.name.toLocaleLowerCase()}`}>
                                                        <p
                                                            href={item.href}
                                                            className="-m-3 p-2 flex text-sm  items-center rounded-md font-body  text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                                        >
                                                            <span className="ml-1">{item.name}</span>
                                                        </p>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </nav>
                                <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                                    <div>
                                        <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">Nuestro blog</h3>
                                        <ul className="mt-6 space-y-6">
                                            {blogPosts.map((post) => (
                                                <li key={post.id} className="flow-root">
                                                    <a
                                                        href={post.href}
                                                        className="-m-3 p-3 flex rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                                                    >
                                                        <div className="hidden sm:block flex-shrink-0">
                                                            <img className="w-32 h-20 object-cover rounded-md" src={post.imageUrl} alt="" />
                                                        </div>
                                                        <div className="min-w-0 flex-1 sm:ml-8">
                                                            <h4 className="text-base font-medium text-gray-900 truncate">{post.name}</h4>
                                                            <p className="mt-1 text-sm text-gray-500">{post.preview}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="mt-6 text-sm font-medium">
                                        <a href="elblog.helpy.mx" className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                                            ver el blog <span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}


