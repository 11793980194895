import React from 'react'
import { Fragment ,useState , useEffect ,useContext} from 'react'
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DotsHorizontalIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  ShoppingBagIcon,
} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_ORDERS_TODO, ORDERS_PAID } from '../../../GraphQL/Queries'
import ModalDash from '../../microComponents/ModalDash'
import AuthContext from '../../../Context/Auth/AuthContext'
import ModalChangeStatus from '../../microComponents/ModalChangeStatus'
import Loader from '../../microComponents/Loader'
import Searchbar from '../../landing/Searchbar'

const heuteDate = new Date()
const daysInMonth = new Date(heuteDate.getFullYear(),heuteDate.getMonth() + 1 , 0).getDate() 
const daysInPrevMonth = new Date(heuteDate.getFullYear(),heuteDate.getMonth() , 0).getDate() 
const startDate = new Date(heuteDate.getFullYear(), heuteDate.getMonth() , 1).getDay()
const dayos = []



for(let i = 1 ; i <= 42 ; i++){
if( i <= startDate){
  dayos.push({ date: `${heuteDate.getFullYear()}-${heuteDate.getMonth()}-${(daysInPrevMonth - (startDate - i ))}` })
}else if ( i >= startDate && i-startDate <= daysInMonth){

  let day = i - startDate
  let dayTwo = '0' + day
  let dayFinal = dayTwo.slice(-2)

  if(heuteDate.getDate() === i - startDate){
    dayos.push({ date: `${heuteDate.getFullYear()}-${heuteDate.getMonth() + 1}-${dayFinal}` , isCurrentMonth: true , isToday: true })
  }else{
    dayos.push({ date: `${heuteDate.getFullYear()}-${heuteDate.getMonth() + 1}-${dayFinal}` , isCurrentMonth: true })
  }
}else{
  dayos.push({ date: `${heuteDate.getFullYear()}-${heuteDate.getMonth() + 2}-${(i-daysInMonth - startDate )}`})
}



}



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DashboardAdmin() {

  const [loaderOn , setLoader]= useState(false)
  const success = () =>{
    setLoader(false)
    setDaySelected(true)
  }
  

  const {id , loadingMagic, logged} = useContext(AuthContext)

  const {data} = useQuery(ORDERS_PAID)
  const [getSerToDo, {data: dataTodo , loading}] = useLazyQuery(GET_ORDERS_TODO , {
    onCompleted : ()=> success()
  })

  
  
  const [days, setDays] = useState(dayos)
  const [services, setServices] = useState([{
      cargando:true,
      ordnum: "",
      name: '',
      time: '',
      date: "",
      location: '',
      service: {
        order:[{service:"cargando"}],
        products:[{name:"cargando"}]
      },
      paymentMethod: "",
      price: 0 ,
  
    }])

    useEffect(()=>{
    data !== undefined && setServices(data.orders.map(order=>{
        const newData = {
          idOrder: order.id,
          name: order.user.name,
          time: new Date(order.when).toLocaleString("es-MX", {timeStyle: "short"}),
          date: new Date(order.when).toLocaleString("es-MX", {dateStyle: "long"}),
          location: order.fullLocation.fullAdress,
          locationlink: `https://www.google.com/maps/search/` + order.fullLocation.fullAdress ,
          service: order.cartshop,
          paymentMethod: "card",
          price: order.total,
          
        }
        return newData
      }))

    },[data])

    
    
    const [daySelected , setDaySelected] = useState(false)
    const [currentDate , setCurrentDate] = useState(" ")

    useEffect(()=>{
      setCurrentDate({ date: `${heuteDate.getFullYear()}-${heuteDate.getMonth() + 1}-${heuteDate.getUTCDate()}` , isCurrentMonth: true , isToday: true })
      
      if(id !== null){
        
        
        getSerToDo({variables:{
          id:id.users[0].pro_worker.id,
          initWhen:new Date(heuteDate.getFullYear(),heuteDate.getMonth(),heuteDate.getUTCDate() , 0,0),
          finitWhen:new Date(heuteDate.getFullYear(),heuteDate.getMonth(),(Number (heuteDate.getUTCDate())+1), 0,0)
        }})
      }


    },[id])

  const selectDay = (day , dayIdx) =>{
    setLoader(true)
    const dateSelected = new Date(day.date)
    
    setCurrentDate(day)
    getSerToDo({variables:{
      id:id.users[0].pro_worker.id,
      initWhen:new Date(dateSelected.getFullYear(),dateSelected.getMonth(),dateSelected.getUTCDate() , 0,0),
      finitWhen:new Date(dateSelected.getFullYear(),dateSelected.getMonth(),(Number (dateSelected.getUTCDate())+1), 0,0)
    }})
    const newDays = dayos.map(({...vals}, i) => dayIdx === i ? {...vals , isSelected : true} : vals)
    setDays(newDays)

  }

  const [isOpen , setIsOpen] = useState(false)
  const [detailSelected , setDetailSelected] = useState({
    service:{
    order: [],
    products: []
  }
  })

  const [misOpen , setMisOpen] = useState(false)
  const [detailAppSelected , setAppDetailSelected] = useState({
    cartshop:{
    order: [],
    products: []
  }
  })

  const [idOrderUpdate, setidOrderUpdate] = useState()

  const openDetails = (service)=>{
    setIsOpen(true)
    setDetailSelected(service)
  } 

  const openMDetails = (service )=>{
    setMisOpen(true)
    setAppDetailSelected(service)
    
    setType("details")
  } 

  const updateOrder = (id) =>{
    setMisOpen(true)
    setidOrderUpdate(id)
    setType("date")
  }

  const updateOrderStatus = (idOrder) =>{
    setAppDetailSelected(idOrder)
    setMisOpen(true)
    setType("status")
  }

  const [type, setType]= useState()

  return (
    <>
    {
      !loadingMagic && logged ? 
<div className="lg:px-24 lg:px-6 p-8">

<ModalChangeStatus id={id} isOpen={misOpen} setIsOpen={setMisOpen} order={detailAppSelected} type={type} newid={idOrderUpdate}/>

<div className="my-2">

<Searchbar></Searchbar>
</div>
<h2 className="text-lg font-title text-gray-900">Servicios agendados</h2>
<span>
<h1 className="text-xl font-title font-semibold text-gray-900">
  {new Date(currentDate.date + "T00:00" ).toLocaleDateString('es-MX', {dateStyle: "long"})}
</h1>
</span>


  <div className="lg:grid lg:grid-cols-12 lg:gap-x-16 font-body">
    <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        
        <div className="flex-auto font-semibold capitalize">{heuteDate.toLocaleString('default', { month: 'long' })}</div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span  className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>D</div>
        <div>L</div>
        <div>M</div>
        <div>X</div>
        <div>J</div>
        <div>V</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
      {
        days.length !== 0 || days === undefined?
        days.map((day, dayIdx) => (
          <button
            onClick={()=>selectDay(day , dayIdx)}
            key={day.date}
            type="button"
            className={classNames(
              'py-1.5 hover:bg-gray-100 focus:z-10',
              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
              (day.isSelected || day.isToday) && 'font-semibold',
              day.isSelected && 'text-white',
              !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
              !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
              day.isToday && !day.isSelected && 'text-blue-600',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === days.length - 7 && 'rounded-bl-lg',
              dayIdx === days.length - 1 && 'rounded-br-lg'
            )}
          >
            <time
              dateTime={day.date}
              className={classNames(
                'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                day.isSelected && day.isToday && 'bg-blue-600',
                day.isSelected && !day.isToday && 'bg-blue-400'
              )}
            >
              {day.date.split('-').pop().replace(/^0/, '')}
            </time>
          </button>
        ))
         :
          null
      }
        
      </div>
     
      
    </div>
    <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
      {
        !daySelected  ? 
        <p>Selecciona un día
        </p>

        :

     
       
        !loaderOn || dataTodo !== undefined || !loading? 
       
        dataTodo.orders.length > 0 ? 

        dataTodo.orders.map((appointment) => (
        <li key={appointment.id} className="relative flex space-x-6 py-6 xl:static">
          <div className="flex-auto">
            <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{appointment.cartshop.order.length > 0 ? appointment.cartshop.order[0].service : appointment.cartshop.products.length > 0 &&  appointment.cartshop.products[0].name} </h3>
            <h2 className="pr-10  text-gray-900 xl:pr-0">{appointment.user.name} </h2>
            <span className="flex flex-row">
            <ClockIcon className="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
            <div >hora:  {new Date(appointment.when).toLocaleTimeString('es-MX', {hour:'2-digit',minute:'2-digit'})} -  {
              new Date(new Date(appointment.when).getTime() + (Number(appointment.eta)*60000)).toLocaleTimeString('es-MX', {hour:'2-digit',minute:'2-digit'}) 
            } 
              </div>
            </span>
            <a className="underline z-10" target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/` + appointment.fullLocation.fullAdress }>

            <span className="flex flex-row">
            <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <dd>{appointment.fullLocation.fullAdress}</dd>
            </span>
            </a>
            <h2 className="pr-10  text-gray-900 xl:pr-0">Técnico(s) asignado: {appointment.pro_selecteds.map(pro=>{
              if(appointment.pro_selecteds.length > 1){
                return  pro.name + " , " 
              } else {
                return pro.name
              }
              })} </h2>

                           
            <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
              <div 
              onClick={()=>updateOrderStatus(appointment.id)}
              className="flex items-start space-x-3 cursor-pointer">
                <dt 
                className="mt-0.5">
                  <span className="sr-only">Estatus</span>
                  <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>Estatus : {appointment.status}</dd>
              </div>
              <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt className="mt-0.5">
                  <span className="sr-only">Precio</span>
                  <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>Precio:{appointment.total} </dd>
              </div>
              <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt className="mt-0.5">
                  <span className="sr-only">Pago</span>
                  <ShoppingBagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>Método de pago : {appointment.paymentMethod}</dd>
              </div>
              
            </dl>
          </div>
          <Menu as="div" className="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center">
            <div>
              <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                <span className="sr-only">Open options</span>
                <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="focus:outline-none absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <p
                        onClick={()=>{updateOrder(appointment.id)}}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Mover fecha/hora
                      </p>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/#"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Cancelar
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <p
                      onClick={()=>openMDetails(appointment)}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        ver detalles
                      </p>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </li>
        ))

        : 

        <h1>No hay servicios agendados</h1>
      :

      <Loader/>
      
      }
    </ol>
  </div>

              <div>

  <h2 className="text-lg my-4 font-title font-semibold text-gray-900">Servicios pendientes por asignar</h2>
  <div className="font-body">
  
  {
    services.cargando || services.length === 0?

    <p>No hay servicios</p>
    
    :
    services[0].service.order[0].service === "cargando" ? 
    <div className='space-y-10 ' >
                    <div className="bg-gray-200 h-48 animate-pulse rounded-md  px-4 py-5 border-b border-gray-200 sm:px-6">
    

                    </div>
                    <div className="bg-gray-200 h-48 animate-pulse rounded-md  px-4 py-5 border-b border-gray-200 sm:px-6">

                    </div>
                    <div className="bg-gray-200 h-48 animate-pulse rounded-md  px-4 py-5 border-b border-gray-200 sm:px-6">

                    </div>

                </div>
  :
  <>
  <ModalDash isOpen={isOpen} setIsOpen={setIsOpen} order={detailSelected.service.order} products={detailSelected.service.products} fullorder={detailSelected}></ModalDash>
{
    services.map((service) => (
    <div
      key={service.ordnum}
      onClick={()=>openDetails(service)}
      className="relative my-2 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-teal-400 focus-within:ring-2 focus-within:ring-offset-2 outline-4 focus-within:ring-teal-500 cursor-pointer"
    >
      <div className="flex-1 min-w-0 text-sm">
        <div className="focus:outline-none space-y-1">
          <span className="absolute inset-0" aria-hidden="true" />
            
         
          <p className="text-lg font-bold font-title">{service.service.order.length > 0 ? service.service.order[0].service : service.service.products.length > 0 &&  service.service.products[0].name}</p>
          <p className="text-sm">{service.name}</p>
          
          
          <span className="flex flex-row">
          <CurrencyDollarIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          <p className="text-md text-green-600 font-bold ">{((service.price) - ((service.price * .25) * .16) - (service.price * .25)).toFixed(2)} <span className="text-xs"> mxn</span></p>
          </span>
          
          <span className="flex flex-row">

          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          <p className="mr-2">{service.date}</p>

          <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          <p>{service.time}</p>
          </span>
                     
          <span className="flex flex-row">
            <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          <a className="underline z-10" target="_blank" rel="noreferrer" href={service.locationlink} >
            <dd>{service.location}</dd>
          </a>
            </span>
                    

        </div>
      </div>

      <div>
              
            </div>
    </div>
  ))
  }
  </>
  }
</div>
              </div>
</div>
      :
      <div className="py-48 text-center">

      <Loader/>
      </div>
    }
    
    

    </>
  )
}




