import {useQuery} from '@apollo/client'
import React from 'react'
import {useState , useContext} from 'react'
import AuthContext from '../../../Context/Auth/AuthContext'
import {GET_CLIENTS} from '../../../GraphQL/Queries'
import AddNewAddress from '../../buyProcess/AddNewAddress'
import Alert from '../../microComponents/Alert'

export default function ExistingClientData() {

    const {data, loading} = useQuery(GET_CLIENTS)
    const {address , AddNewAddressContext , setUserSelectedByAdmin}= useContext(AuthContext)
    const [addNewAddressAdmin , setNewAddressAdmin] = useState(false)
    const [newAddressCreated , setnewAddressCreated] = useState(false)
    const [users,setUsers] = useState([
        {
            id: "",
            location: [
                {
                    fullAdress: "",
                    phoneNumber: ""
                }
            ],
            name: "",
            username: ""
        }
    ])
    const [input, setInput] = useState(" ")
    const [userSelected, setuserSelected] = useState(null)
    const [userSelectedNew, setuserSelectedNew] = useState(false)
    const [alert, setAlert] = useState({
        open: false,
        color:"red",
        error: "Ingresa un correo válido"
    })
    const [dataReady, setDataReady] = useState({dReady : false , dropDown: false})

    const checkUserByMail = (ev) => {
        setInput(ev.target.value)

        if (!loading && data !== undefined) {
            const filtered = data.users.filter(user => user.username.includes(input))
            setUsers([...filtered , { username: ev.target.value} ])
            setDataReady({...dataReady , dReady:true})
        }

        // aqui revisar que no este en el array y mostrar para llenar los datos

    }


    const setName = (user)  =>{
        setInput(user.username)
        setDataReady({...dataReady , dropDown:false})

        // aqui se valida si es un correo válido si existe en el primer array
        // revisar si existe en el data para que agregue o no la dirección
        const bool = data.users.some(userArr=>userArr.username === user.username)

        if(bool){
            if(!user.location.length < 1){
            setuserSelected(user)
            setUserSelectedByAdmin(user)
            // poner la dirección en el authcontext
            AddNewAddressContext({
                    name: user.name,
                    street: user.location[0].street,
                    phoneNumber: user.location[0].phoneNumber,
                    postalCode: user.location[0].postalCode,
                    colonia: user.location[0].colonia,
                    city:user.location[0].city,
                    state: user.location[0].state,
                    extras: user.location[0].extras,
                    default: true
                })
            setAlert({
                open: false,
                color:"red",
                error: "Ingresa un correo válido"
            })
            
            }else{
                setuserSelectedNew(true)
                setNewAddressAdmin(true)
                setAlert({
                    open: true,
                    color:"red",
                    error: "Contacta admin falta dirección en DB"
                })
            }
            
        }else{
            setuserSelected(null)
            let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
            if(!filter.test(user.username)){
                setAlert({
                    open: true,
                    color:"red",
                    error: "Ingresa un correo válido"
                })
            }else{
                setuserSelectedNew(true)
                setNewAddressAdmin(true)
                setUserSelectedByAdmin(user)
                setAlert({
                    open: false,
                    color:"red",
                    error: "Ingresa un correo válido"
                })
            }
        }
        
        
        
    }

    return (
        <div>

            <div className="mt-1 font-body">
            
                                                       
                <input
                    onChange={checkUserByMail}
                    onClick={()=>setDataReady({...dataReady, dropDown: true})}
                    type="email"
                    name="email"
                    id="email"
                    value={input}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-600 rounded-md"
                    placeholder="you@example.com"/>
                
                
                    <div className="bg-white absolute w-full z-10 shadow flex flex-col-reverse overflow-hidden sm:rounded-md">

                        {  
                            dataReady.dropDown?
                            dataReady.dReady? 
                            users.map((user, id) => {
                                    return <ul className="divide-y divide-gray-200 " key={id}>
                                        <li 
                                        onClick={()=>setName(user)}
                                        className="py-4 flex  hover:bg-yellow-100 cursor-pointer">
                                            <div className="ml-3 ">
                                                <p className="text-md font-medium text-gray-900">{user.username}</p>
                                            </div>
                                        </li>
                                    </ul>
                            })
                            : 
                            <li className="py-4 flex  hover:bg-yellow-100 cursor-pointer">
                                <div className="ml-3 ">
                                    <p className="text-md font-medium text-gray-900">Cargando...</p>
                                </div>
                            </li>
                            :
                            null
                      }
                    </div>
                      {

                        userSelected !== null?
                        

                    <div className="overflow-hidden ">
                    
                                                            <p className="mt-3 text-sm">
                                                            <span className="font-semibold">Dirección del cliente :  </span>
                                                             
                                                                {userSelected.name} , <span className="text-gray-500">{userSelected.location[0].fullAdress}</span>
                                                            </p>
                                                        </div>

                                                      
                                                        :

                                                    userSelectedNew&&

                                                    <div>
                                                    {
                                                        addNewAddressAdmin&&
                                                        <div>

                                                    <p className="text-sm mt-2">Ingresa datos del cliente</p>
                                                        <div className="overflow-y-scroll max-h-80 ">
                                                        
                                                    <AddNewAddress addNewAddressAdmin={addNewAddressAdmin} setNewAddressAdmin={setNewAddressAdmin} setnewAddressCreated={setnewAddressCreated}></AddNewAddress>
                                                        </div>
                                                        </div>

                                                    }
                                                    {
                                                        newAddressCreated&&
                                                        <div>
                                                        <p className="mt-3 text-sm">
                                                            <span className="font-semibold">Dirección del cliente :  </span>
                                                             
                                                                {address.name} , <span className="text-gray-500">{address.street + " " + address.colonia}</span>
                                                            </p>
                                                        </div>
                                                    }
                                                    </div>
                                                        
                      }
                    
                <Alert status={alert}></Alert>
                </div>
            </div>
    )
}
