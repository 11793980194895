import React from "react";
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from 'swiper/core';



SwiperCore.use([Navigation]);



export default function Carousel({ data }) {


    return (
        <>
            <Swiper
                navigation={true}
                className="mySwiper">
                {
                    data ?
                        data.map((e, id) => {
                            return <SwiperSlide key={id}>

                                <img src={e.url} alt={e.url}></img>

                            </SwiperSlide>
                        })
                        :
                        null

                }
            </Swiper>
        </>
    )
}

