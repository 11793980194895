import React, { useContext, useReducer } from 'react'
import ProductContext from '../Products/ProductsContext'
import { OPEN_MODAL, CLOSE_MODAL, OPEN_CARD_OVERLAY, CLOSE_CARD_OVERLAY, LOG_CARD, SET_NEW_ADDRESS_LOCAL, SET_NEW_CARD, SET_NEW_ADDRESS, SET_INTERESADO } from '../types'
import ModalContext from './ModalContext'
import ModalReducer from './ModalReducer'


const ModalState = (props) => {
    const initialState = {
        open: false,
        selectedProd: null,
        slider: false,
        openCard: false,
        logCard: "default",
        addressFromDB: [],
        newCard: false,
        newAddressCheck: false,
        interesade: false,
    }

    const [state, dispatch] = useReducer(ModalReducer, initialState)

    const { emptyTemporalOrder } = useContext(ProductContext)


    const openModal = (product) => {
        dispatch({
            type: OPEN_MODAL,
            open: true,
            payload: product
        })
    }

    const closeModal = () => {
        dispatch({
            type: CLOSE_MODAL,
            open: false,
            payload: null
        })
    }

    const openCardOverlay = () => {
        dispatch({
            type: OPEN_CARD_OVERLAY,
            openCard: true,
        })
    }

    const closeCardOverlay = () => {
        emptyTemporalOrder()
        dispatch({
            type: CLOSE_CARD_OVERLAY,
        })
    }

    const switchCard = (moveTo, address) => {
        dispatch({
            type: LOG_CARD,
            payload: {
                data: moveTo,
                address,
            },
        })
    }

    const setAddress = (address) => {
        dispatch({
            type: SET_NEW_ADDRESS_LOCAL,
            payload: address,
        })
    }

    const setNewCard = (bool) => {
        dispatch({
            type: SET_NEW_CARD,
            payload: bool,
        })
    }

    const setNewAdressCheck = (bool) => {
        dispatch({
            type: SET_NEW_ADDRESS,
            payload: bool,
        })
    }

    const setInteresade = (bool) => {
        dispatch({
            type: SET_INTERESADO,
            payload: bool,
        })
    }


    return (
        <ModalContext.Provider value={{
            state,
            closeModal,
            openModal,
            open: state.open,
            openCardOverlay,
            closeCardOverlay,
            switchCard,
            openCard: state.openCard,
            logCard: state.logCard,
            setAddress,
            addressFromDB: state.addressFromDB,
            setNewCard,
            newCard: state.newCard,
            setNewAdressCheck,
            newAddressCheck: state.newAddressCheck,
            interesade: state.interesade,
            setInteresade

        }}>
            {props.children}
        </ModalContext.Provider>
    )
}
export default ModalState