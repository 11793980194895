import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { USER_ORDERS } from '../../GraphQL/Queries'
import Order from '../buyProcess/Order'
import { Link } from 'react-router-dom'

export default function DashboardFull() {


    const { idClient } = useParams()
    const { data } = useQuery(USER_ORDERS, { variables: { id: idClient } })

    // const [steps, setSteps] = useState([
    //     { name: 'Step 1', href: '#', status: 'complete' },
    //     { name: 'Step 2', href: '#', status: 'complete' },
    //     { name: 'Step 3', href: '#', status: 'current' },
    //     { name: 'Step 4', href: '#', status: 'upcoming' },
    //     { name: 'Step 5', href: '#', status: 'upcoming' },

    // ])



    // const steps = [
    //     { name: 'Step 1', href: '#',status:"paid", title: 'Pedido realizado con éxito' },
    //     { name: 'Step 2', href: '#',status:"acceptedProject", title: 'Orden confirmada' },
    //     { name: 'Step 3', href: '#',status:"Omw", title: 'Helpy Pro en camino"' },
    //     { name: 'Step 4', href: '#',status:"inProgress", title: 'Servicio en progreso' },
    //     { name: 'Step 5', href: '#',status:"done", title: 'Servicio concluido' },
    // ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className="bg-gray-100 p-5 ">
            {
                data !== undefined ?
                    data.users[0].orders.map((order, id) => {

                        return <div className="bg-white max-w-2xl   mx-auto shadow overflow-hidden rounded-xl mt-5" key={id}>

                            <div className="bg-white  px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap font-title">
                                    <div className=" mt-4">
                                        <div className="flex items-center">
                                            <div className="ml-4 ">
                                                <h3 className="text-sm leading-6 font-bold text-gray-900">Id de la orden : <span className="font-normal"> {order.orderNumber} </span></h3>
                                                <h3 className="text-xs leading-6 font-bold text-gray-900"> <span className="font-normal"> {data.users[0].name} </span></h3>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <div className="ml-3 mt-4 flex-shrink-0 flex">
                                        <Link to={`/dashboard/client/${order.orderNumber}`}>
                                            <button
                                                type="button"
                                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                                <span>detalles</span>
                                            </button>
                                        </Link>
                                        {
                                            order.typeOfOrder === "service" && order.status === "done"?
                                                <>
                                                    <Link to={`/report/service/${order.orderNumber}`}>
                                                    <button
                                                        type="button"
                                                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    >
                                                        {/* <DocumentTextIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                                                        <p>reporte</p>
                                                    </button>
                                                    </Link>
                                                </>
                                                :

                                                null
                                        }

                                    </div>

                                </div>

                            </div>

                            {/* <div className="p-5 border-b border-gray-200">
                                <nav aria-label="Progress" className="w-11/12 mx-0 w-full lg:mx-auto lg:w-12/12  " >
                                    <ol className="flex items-center">
                                        {steps.map((step, stepIdx) => (
                                            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
                                                {step.status === 'complete' ? (
                                                    <>
                                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                            <div className="h-0.5 w-full bg-green-400" />
                                                        </div>
                                                        <span

                                                            className="relative w-5 h-5 flex items-center justify-center bg-green-400 rounded-full hover:bg-green-900"
                                                        >
                                                            <CheckIcon className="w-3 h-3 text-white" aria-hidden="true" />
                                                            <span className="sr-only">{step.name}</span>
                                                        </span>
                                                    </>
                                                ) : step.status === 'current' ? (
                                                    <>
                                                        <div className="absolute  inset-0 flex items-center" aria-hidden="true">
                                                            <div className="h-0.5 w-full bg-gray-200" />
                                                        </div>
                                                        <span

                                                            className="relative w-5 h-5 flex items-center justify-center bg-white border-2 border-green-400 rounded-full"
                                                            aria-current="step"
                                                        >
                                                            <span className=" absolute h-6 w-6 bg-green-400 animate-ping rounded-full" aria-hidden="true" />
                                                            <span className="h-2 w-2 bg-green-400  rounded-full" aria-hidden="true" />
                                                            <span className="sr-only">{step.name}</span>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                            <div className="h-0.5 w-full bg-gray-200" />
                                                        </div>
                                                        <span

                                                            className="group relative w-5 h-5 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400"
                                                        >
                                                            <span
                                                                className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="sr-only">{step.name}</span>
                                                        </span>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ol>
                                    <div className="mt-3 font-title">
                                        <h1 className="text-sm">El pro va en camino</h1>
                                    </div>
                                </nav>
                            </div> */}


                            <div className=" font-body">
                                <div className="ml-4 mt-4 font-title">
                                    <h3 className="text-md leading-6 font-bold text-gray-900">Resumen de la orden : </h3>
                                </div>
                                <Order order={order.cartshop.order} products={order.cartshop.products}></Order>
                            </div>
                        </div>
                    })

                    :
                    <div className='space-y-10 ' >
                        <div className="bg-gray-200 h-64 animate-pulse rounded-md  px-4 py-5 border-b border-gray-200 sm:px-6">

                        </div>
                        <div className="bg-gray-200 h-64 animate-pulse rounded-md  px-4 py-5 border-b border-gray-200 sm:px-6">

                        </div>
                        <div className="bg-gray-200 h-64 animate-pulse rounded-md  px-4 py-5 border-b border-gray-200 sm:px-6">

                        </div>

                    </div>

            }
        </div>

    )
}
