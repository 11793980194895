import React from 'react'

export default function StatusDirectService({ img, status, percentage, title, description }) {
    return (
        <>

            <img className="mx-auto w-96" src={img} alt="" />
            <div className="relative pt-1 mt-10">
                <div className="flex mb-2 items-center justify-between">
                    <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                            {status}
                        </span>
                    </div>

                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                    <div style={{ width: Number(percentage) + "%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                </div>

            </div>
            <div >

                <h2 className="font-title text-md"> <span className="font-bold">{title}</span> </h2>
                <p className="font-body text-xs">{description}</p>
            </div>

        </>
    )
}
