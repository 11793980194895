import { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/solid'
import React from 'react'
import { useContext } from 'react'
import ModalContext from '../../Context/Modal/ModalContext'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { SET_DEFAULT_ADDRESS } from '../../GraphQL/Mutations'
import AuthContext from '../../Context/Auth/AuthContext'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SelectAddress() {

    const { switchCard, addressFromDB, setAddress } = useContext(ModalContext)
    const { id } = useContext(AuthContext)
    const [selected, setSelected] = useState(addressFromDB.listOfAddress[addressFromDB.selectedIndex])
    const [newAddress, setnewAddress] = useState()

    const [SetDefault, { loading }] = useMutation(SET_DEFAULT_ADDRESS)

    useEffect(() => {
        const newAddress = addressFromDB.listOfAddress.map((e, id) => {
            if (e.id === selected.id) {
                return {
                    ...selected,
                    default: true
                }
            } else {
                return {
                    ...e,
                    default: false
                }
            }
        })

        // console.log(newAddress)
        setnewAddress(newAddress)
    }, [setSelected, selected])



    const submitNewDefault = async () => {
        try {
            await SetDefault({
                variables:
                {
                    id: id.users[0].id,
                    address: newAddress
                }
            })
            switchCard("checkout")
            setAddress({
                listOfAddress: newAddress,
                selected: selected,
                selectedIndex: newAddress.findIndex(e => e.default === true)
            })


        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }

    }



    return (
        <div >
            <div
                onClick={() => switchCard("checkout")}
                className="px-6 pb-6">
                <div className="inline-flex align-middle">
                    <ArrowLeftIcon
                        className="text-gray-400 w-6 mr-2"
                    />
                    <p className="font-body text-sm" >Regresar</p>
                </div>
            </div>



            <RadioGroup value={selected} onChange={setSelected}>
                <RadioGroup.Label className="px-6">Dirección de preferencia:</RadioGroup.Label>
                <div
                    className="bg-white rounded-md -space-y-px px-6 pt-2">
                    {addressFromDB.listOfAddress.map((adds, settingIdx) => (
                        <RadioGroup.Option
                            key={adds.id}
                            value={adds}
                            className={({ checked }) =>
                                classNames(
                                    settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                    settingIdx === addressFromDB.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                    checked ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
                                    'relative border p-4 flex  cursor-pointer focus:outline-none'
                                )
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <span
                                        className={classNames(
                                            checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                                            active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                                            'w-4 h-4  mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                    >
                                        <span className="rounded-full bg-white  w-1.5 h-1.5" />
                                    </span>
                                    <div >

                                        <div className="ml-3 flex flex-col">

                                            <RadioGroup.Label
                                                as="span"
                                                className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'block text-sm font-medium')}
                                            >
                                                {adds.name}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                                as="span"
                                                className={classNames(checked ? 'text-blue-700' : 'text-gray-500', 'block text-sm')}
                                            >
                                                {adds.street} , {adds.colonia} , {adds.postalCode} , {adds.city}
                                            </RadioGroup.Description>
                                        </div>
                                    </div>

                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>



            <div className="px-6">
                <div className="flex justify-center md:justify-start mb-2">

                    {
                        loading ?
                            <button
                                className="relative w-full md:w-1/4 mt-2 px-4 py-2 border shadow-sm text-sm font-body rounded-md  bg-yellow-100"
                                disable
                            >
                                <p>
                                    creando tu espacio..
                                </p>

                            </button>
                            :
                            <button
                                onClick={submitNewDefault}
                                className="relative w-full md:w-1/4 mt-2 px-4 py-2 border shadow-sm text-sm font-body rounded-md  bg-yellow-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                            >
                                <p>
                                    Seleccionar dirección
                                </p>

                            </button>
                    }
                </div>

                <Link to={"/nueva/direccion"}>
                    <button
                        type="button"
                        className="relative block w-full border-2 border-gray-200 border-dashed rounded-lg py-2 text-center hover:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    >
                        <span className="flex justify-center">
                            <PlusIcon className="w-4"></PlusIcon>
                        </span>

                        <span className="mt-2 block text-sm font-medium text-gray-900">
                            <p>
                                Agregar nueva dirección
                            </p>
                        </span>
                    </button>

                </Link>
            </div>

        </div>
    )
}
