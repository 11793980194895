import React, { useReducer, useState } from 'react'
import { ADD_CART_ORDER, ADD_PRODUCT_IN_ORDER, REMOVE_PRODUCT_IN_ORDER, ADD_CART_PRODUCT, DATE_SELECTED, TIME_SELECTED, PAY_FAST_ORDER, EMPTY_FAST_ORDER, EMPTY_FAST_PRODUCTS, REMOVE_PRODUCTS_CART_AFTER_PAY_IN_CART, REMOVE_SERVICE_CART_ORDER, ADD_CART_PRODUCT_ADMIN, ADD_CART_ORDER_ADMIN, ADD_PRODUCT_IN_ORDER_ADMIN, SET_CART_NUM, ADD_CART_ORDER_HEAVEN } from '../types'
import ProductsContext from './ProductsContext'
import ProductsReducer from './ProductsReducer'


const ProductsState = (props) => {

    const initialState = {
        products: [],
        orderFastCheckOut: [],
        order: [],
        productsCheckOut: [],
        date: null,
        time: null,
        cartNum: 0
    }

    const [category, SetCategory] = useState([])

    const [state, dispatch] = useReducer(ProductsReducer, initialState)


    const addProdCurrentOrder = (product) => {
        dispatch({
            payload: product,
            type: ADD_PRODUCT_IN_ORDER
        })
    }

    const removeProdCurrentOrder = (productdel) => {

        const result = state.products.filter(element => element !== productdel);
        dispatch({
            payload: result,
            type: REMOVE_PRODUCT_IN_ORDER
        })
    }

    const removeServiceFromCart = (whatsLeft) => {
        dispatch({
            payload: whatsLeft,
            type: REMOVE_SERVICE_CART_ORDER
        })
    }

    const addOrderToCart = (data) => {
        dispatch({
            type: ADD_CART_ORDER,
            payload: data
        })
    }

    const addOrderToCartFromHeaven = (data) => {
        dispatch({
            type: ADD_CART_ORDER_HEAVEN,
            payload: data
        })
    }

    const setCartNum = (data) => {
        dispatch({
            type: SET_CART_NUM,
            payload: data
        })
    }

    const addOrderToCartAdmin = (id, newPrice) => {
        state.order[id].price = newPrice
        dispatch({
            type: ADD_CART_ORDER_ADMIN
        })
    }
    const orderToFastPay = (data) => {
        dispatch({
            type: PAY_FAST_ORDER,
            payload: data
        })
    }
    const killCarWhenBought = () => {
        dispatch({
            type: REMOVE_PRODUCTS_CART_AFTER_PAY_IN_CART,
        })
    }

    const emptyTemporalOrder = () => {
        dispatch({
            type: EMPTY_FAST_ORDER,
        })
    }

    const emptyTemporalProducts = () => {
        dispatch({
            type: EMPTY_FAST_PRODUCTS,
        })
    }

    const addProductToCart = (data) => {

        dispatch({
            type: ADD_CART_PRODUCT,
            payload: data
        })
    }

    const addProductToCartAdmin = (id, newPrice) => {
        const newProd  = state.productsCheckOut.map((product)=> {return {...product}})
        newProd[id].price = newPrice
        dispatch({
            type: ADD_CART_PRODUCT_ADMIN,
            payload : newProd
        })
    }

    const addProductToOrderAdmin = (id, newPrice) => {
        const newProd  = state.products.map((product)=> {return {...product}})
        newProd[id].price = newPrice
        dispatch({
            type: ADD_PRODUCT_IN_ORDER_ADMIN,
            payload : newProd
        })
    }

    const dateSelected = (data) => {

        dispatch({
            type: DATE_SELECTED,
            payload: data
        })
    }

    const timeSelected = (data) => {

        dispatch({
            type: TIME_SELECTED,
            payload: data
        })
    }

    const categoryByMoth = () => {
        const mes = new Date().getMonth() + 1

        switch (mes) {
            case 1:
                SetCategory
                    (["Albañileria", "Plomería", "Carpintería", "Baño"])
                break;
            case 2:
                SetCategory
                    (["Pintura", "Jardinería", "Fumigación"])
                break;
            case 3:
                SetCategory
                    (["Pintura", "Jardinería", "Fumigación"])
                break;
            case 4:
                SetCategory
                    (["Pintura", "Jardinería", "Fumigación"])
                break;
            case 5:
                SetCategory
                    (["Handyman", "Plomería", "Aire acondicionado", "Electricidad"])
                break;
            case 6:
                SetCategory
                    (["Handyman", "Plomería", "Aire acondicionado", "Electricidad"])
                break;
            case 7:
                SetCategory
                    (["Albañileria", "Remodelación", "Plomería", "Carpintería", "Baño", "Pintura", "Fumigación", "Aire acondicionado", "Cerrajero", "Handyman"])
                break;
            case 8:
                SetCategory
                    (["Pintura", "Fumigación", "Handyman"])
                break;
            case 9:
                SetCategory
                    (["Pintura", "Fumigación"])
                break;
            case 10:
                SetCategory
                    (["Pintura", "Fumigación"])
                break;
            case 11:
                SetCategory
                    (["Pintura", "Fumigación"])
                break;
            case 12:
                SetCategory
                    (["Pintura", "Fumigación", "Electrodomésticos"])
                break;
            default:
                SetCategory
                    (["Albañileria", "Plomería", "Carpintería", "Baño", "Pintura", "Fumigación", "Aire acondicionado"])
        }

    }


    return (
        <ProductsContext.Provider value={{
            addProdCurrentOrder,
            removeProdCurrentOrder,
            dateSelected,
            timeSelected,
            addOrderToCart,
            categoryByMoth,
            addProductToCart,
            orderToFastPay,
            emptyTemporalOrder,
            emptyTemporalProducts,
            killCarWhenBought,
            removeServiceFromCart,
            addOrderToCartAdmin,
            addProductToCartAdmin,
            addProductToOrderAdmin,
            setCartNum,
            addOrderToCartFromHeaven,
            cartNum:state.cartNum,
            productsCheckOut: state.productsCheckOut,
            category,
            products: state.products,
            orderFastCheckOut: state.orderFastCheckOut,
            order: state.order,
            date: state.date,
            time: state.time
        }}>
            {props.children}
        </ProductsContext.Provider>
    )
}

export default ProductsState
