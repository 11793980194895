import React, { useEffect, useReducer } from 'react'
import { LOG_OUT, NEW_ADDRESS, PRE_USER, FAILED_LOGIN, CHECK_LOGIN_USER, ID_USER, NEW_USER_TRUE, LOADING_MAGIC_USER, TOKEN_RECIEVED, SET_USER_TYPE, SET_USER_SELECTED_ADMIN } from '../types'
import AuthContext from './AuthContext'
import AuthReducer from './AuthReducer'
import { Magic } from 'magic-sdk'
import { useLazyQuery } from '@apollo/client'
import { GET_USER } from '../../GraphQL/Queries'


const PUBLIC_KEY_MAGIC = process.env.REACT_APP_MAGIC_PUBLIC_KEY
let magic

const AuthState = (props) => {
    const initialState = {
        loadingMagic: true,
        logged: false,
        user: null,
        id: null,
        address: [],
        preUser: {
            name: "",
            email: " ",
            phone: "",
            rfc: "",
            password: "",
            companyName: "",
            credit: false,
            company: false
        },
        newUser: false,
        tokenRecieved: false,
        userType:null,
        userSelectedByAdmin:null
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState)
    const [findoneUser, { client }] = useLazyQuery(GET_USER)

    const LoginUser = async (email) => {
        loadingMagic(true)
        try {
            await magic.auth.loginWithMagicLink({ email })
            const token = await getToken()
            localStorage.setItem("token", token)
            dispatch({
                type: TOKEN_RECIEVED
            })
            checkUserLogIn()
        } catch (err) {
            localStorage.removeItem('token')
            dispatch({
                type: FAILED_LOGIN
            })
        }
    }

    const logout = async () => {
        try {
            await magic.user.logout()
            dispatch({
                type: LOG_OUT
            })
            client.resetStore()
        } catch (err) {
            localStorage.removeItem('token')
        }
    }


    const checkUserLogIn = async () => {
        localStorage.removeItem('token')
        try {
            const isLoggedIn = await magic.user.isLoggedIn()
            if (isLoggedIn) {
                const { email } = await magic.user.getMetadata()
                const token = await getToken()
                const { data } = await findoneUser({ variables: { username: email } })
                
                if(data.users[0] !== undefined){
                    setUserType(data.users[0].role.name)
                }else{
                    setUserType("Home")
                }
                
                getIdUser(data)
                
                dispatch({
                    payload: email,
                    type: CHECK_LOGIN_USER
                })

                localStorage.setItem("token", token)
                loadingMagic(false)

            } else if (!isLoggedIn) {
                loadingMagic(false)
                localStorage.removeItem('token')
            }
        } catch (er) {
            console.log("Auth", er)
            client.resetStore()
        }
    }

    const loadingMagic = (bool) => {
        dispatch({
            type: LOADING_MAGIC_USER,
            payload: bool
        })

    }

    const setUserType = (typeOfWorker) => {
        dispatch({
            type: SET_USER_TYPE,
            payload: typeOfWorker
        })

    }

    const setUserSelectedByAdmin = (user) => {
        dispatch({
            type: SET_USER_SELECTED_ADMIN,
            payload: user
        })

    }

    


    const getToken = async () => {
        try {
            const token = await magic.user.getIdToken()
            return token
        } catch (err) {

        }
    }


    const AddNewAddressContext = (address) => {
        dispatch({
            payload: address,
            type: NEW_ADDRESS
        })
    }

    const newUserTrue = (bool) => {
        dispatch({
            type: NEW_USER_TRUE,
            payload: bool
        })
    }

    const HoldPreUser = (user) => {
        dispatch({
            payload: user,
            type: PRE_USER
        })
    }

    const getIdUser = (id) => {
        dispatch({
            payload: id,
            type: ID_USER
        })
    }

    useEffect(() => {
        magic = new Magic(PUBLIC_KEY_MAGIC, { locale: 'es' })
        checkUserLogIn()
    }, [])


    return (
        <AuthContext.Provider value={{
            logged: state.logged,
            address: state.address,
            preUser: state.preUser,
            userType:state.userType,
            user: state.user,
            id: state.id,
            newUser: state.newUser,
            loadingMagic: state.loadingMagic,
            tokenRecieved: state.tokenRecieved,
            userSelectedByAdmin:state.userSelectedByAdmin,
            setUserSelectedByAdmin,
            HoldPreUser,
            AddNewAddressContext,
            LoginUser,
            logout,
            getToken,
            checkUserLogIn,
            getIdUser,
            newUserTrue,
            setUserType
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}
export default AuthState