import { useQuery } from '@apollo/client';
import React from 'react'
import { useParams } from 'react-router';
// import AuthContext from '../../Context/Auth/AuthContext';
import { ORDER_DATA } from '../../GraphQL/Queries';
import Order from '../buyProcess/Order';
// import CardLoader from '../microComponents/CardLoader';
import Loader from '../microComponents/Loader';
import CardLong from '../microComponents/CardLong';
import StatusDirectService from './StatusDirectService';
import { Link } from 'react-router-dom';


export default function ClientDashboard() {


    // const [times, setTimes] = useState(0)
    // const [showData, setShowData] = useState(false)
    const { idOrder } = useParams()


    // const { refetch } = useQuery(ORDER_USER_CHECK, { skip: !idOrder })
    const { data, loading } = useQuery(ORDER_DATA, {
        variables: { orderNumber: idOrder },
        pollInterval: 500
    })
    // const history = useHistory()


    // useEffect(() => {

    //     if (!loading) {
    //         if (data.orders.length === 0 || data === undefined) {
    //             history.push("/")
    //             return
    //         }
    //     }
    //     setTimes(times + 1)
    //     if (times === 1) {
    //         if (logged) {
    //             const checkOrderAndUser = async () => {
    //                 const data = await refetch({ orderNumber: idOrder })
    //                 if (user !== undefined) {
    //                     if (data.data.orders[0].user.username === user) {
    //                         setShowData(true)
    //                     }
    //                 }

    //             }
    //             checkOrderAndUser()
    //         } else {
    //             history.push("/")
    //         }
    //     }


    // }, [user])



    return (
        <>



            {
                loading ?
                    <div className="grid justify-items-center">
                        <div className='mx-auto py-64 w-24'>
                            <Loader></Loader>
                        </div>
                    </div>
                    :
                    <div className="bg-gray-100 p-5 ">
                        <div className="bg-white  sm:w-3/4   mx-auto overflow-hidden shadow rounded-lg">
                            <Link to={`/client/dashboard/${data.orders[0].user.id}`}>
                                <div className="flex flex-row mt-4 font-body justify-between px-3">
                                    <div className='flex flex-row'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                        <p className="text-sm">Ver servicios</p>
                                    </div>
                                    <div><p className='text-sm text-gray-500 relative'># de Orden: {idOrder}</p></div>
                                </div>
                            </Link>


                            <div className="p-8">

                                <div className="mt-5">
                                    <div>

                                        {
                                            data !== undefined ?

                                                {
                                                    'paid': <StatusDirectService img={"../../img/paidService.svg"} status="Pagado" percentage={10} title={"Pedido realizado con éxito"} description={"En breve estará la confirmación de tu orden en tu correo electronico. Puedes ver el status y actualizaciones en esta página"} />,
                                                    "siteSurvey": <h1>hola</h1>,
                                                    "quoted": <h1>hola</h1>,
                                                    "penApproval": <h1>hola</h1>,
                                                    "acceptedProject": <StatusDirectService img={"../../img/proAccepted.svg"} status="Confirmado" percentage={30} title={"Orden confirmada"} description={"Revisa las actualizaciones cuando el HelpyPro vaya en camino a tu domicilio"} />,
                                                    "paymentPending": <h1>hola</h1>,
                                                    "paymentDeclined": <h1>hola</h1>,
                                                    "Omw": <StatusDirectService img={"../../img/omw.svg"} status="En camino" percentage={60} title={"Helpy en camino"} description={"En camino a tu domicilio 🚗"} />,
                                                    "inProgress": <StatusDirectService img={"../../img/workInProgress.svg"} status="Magic Time" percentage={80} title={"Servicio en progreso"} description={"Servicio en progreso, deja que el helpy Pro haga su magia ⚙️"} />,
                                                    "rejected": <h1>hola</h1>,
                                                    "cancel": <h1>hola</h1>,
                                                    "done": <StatusDirectService img={"../../img/serviceDone.svg"} status="Listo" percentage={100} title={"Servicio concluido"} description={"Esperamos haya sido una experiencia amigable contratar en helpy, deja tu retroalimentación "} />,
                                                    "ratePending": <h1>hola</h1>
                                                }[data.orders[0].status]
                                                : null
                                        }

                                        {
                                            data.orders[0].typeOfOrder === "service" ?

                                                <> 
                                        <h1 className="mt-6 mb-2  font-title font-bold">Día del servicio :</h1>
                                                    <CardLong when={data.orders[0].when}></CardLong> 
                                                </>

                                                :

                                                null

                                        }

                                        <h1 className="mt-6 mb-2  font-title font-bold flex flex-row">Lugar  {data.orders[0].typeOfOrder === "product" ? <p className='ml-1'> de entrega :</p> : <p className='ml-1'> del servicio :</p>} </h1>
                                        <CardLong where={data.orders[0].fullLocation}></CardLong>

                                        <h1 className="mt-6 mb-2  font-title font-bold">Detalle :</h1>
                                        <CardLong total={data.orders[0].total} delivery={data.orders[0].deliveryPrice}></CardLong>

                                        <Order order={data.orders[0].cartshop.order} products={data.orders[0].cartshop.products} type={data.orders[0].typeOfOrder}></Order>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>



            }




        </>
    )
}


