import { useQuery } from '@apollo/client';
import React from 'react'
import { Link } from 'react-router-dom';
import { GET_CATEGORIES } from '../../GraphQL/Queries';


export default function Categories() {

    //Queries
    const {  data } = useQuery(GET_CATEGORIES);


    // console.log(data)

    return (
        <>

            <div className="flex items-center justify-center">

                <div className=" grid  md:grid-cols-4 grid-cols-2 gap-x-5 lg:gap-x-10 lg:gap-y-4 lg:py-24 lg:px-56 p-8">



                    {
                        data !== undefined ? data.categories.map((cat, id) => {
                            return (
                                <>
                                    {
                                        id % 5 === 0 ?
                                            <>

                                                {
                                                    id === data.categories.length - 1 ?
                                                        <div className='col-span-2 font-bold md:col-span-4 '>
                                                            <div key={id} className=" bg-black md:h-72 rounded-lg overflow-hidden shadow-md ">
                                                                <Link to={`/categoria/${cat.category}`}>
                                                                    <img
                                                                        src={cat.image.url}
                                                                        alt={cat.image.url}
                                                                        className="object-center h-full object-cover opacity-60"
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <h1 className='font-title text-white text-md lg:text-2xl  relative -top-1/2 text-center' >{cat.category}</h1>
                                                        </div>
                                                        :

                                                        <div className='col-span-2 font-bold'>
                                                            <div key={id} className="rounded-lg md:h-72 bg-black overflow-hidden shadow-md ">
                                                                <Link to={`/categoria/${cat.category}`}>
                                                                    <img
                                                                        src={cat.image.url}
                                                                        alt={cat.image.url}
                                                                        className="object-center h-full object-cover opacity-60"
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <h1 className='font-title text-white text-md lg:text-2xl relative -top-1/2 text-center' >{cat.category}</h1>
                                                        </div>
                                                }
                                            </>
                                            :
                                            <div className='font-bold'>
                                                <div key={id} className=" md:h-72 bg-black rounded-lg overflow-hidden shadow-md">
                                                    <Link to={`/categoria/${cat.category}`}>
                                                        <img
                                                            src={cat.image.url}
                                                            alt={cat.image.url}
                                                            className="object-center h-full object-cover opacity-60"
                                                        />
                                                    </Link>
                                                </div>
                                                <h1 className='font-title text-white text-md lg:text-2xl relative -top-1/2 text-center' >{cat.category}</h1>
                                            </div>


                                    }

                                </>
                    )
                }) : null


            }



                </div>

            </div >

        </>
    )
}

