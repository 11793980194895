import { useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { GET_HELPY_SELECT } from '../../GraphQL/Queries'
import Loader from '../microComponents/Loader'




export default function Selection() {


    const { data, loading } = useQuery(GET_HELPY_SELECT)

    return (
        <>
            {
                !loading && data !== undefined ?
                    <div className="bg-white">
                        <header className="relative overflow-hidden">
                            <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
                                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
                                    <div className="sm:max-w-lg font-title">
                                        <h1 className="text-4xl font font-extrabold font-title tracking-tight text-gray-900 sm:text-5xl">
                                            {data.helpyselections[0].title}
                                        </h1>
                                        <p className="mt-4 text-xl font-body text-gray-500">
                                            {data.helpyselections[0].catch}
                                        </p>
                                    </div>
                                    <div>
                                        <div className="mt-10">
                                            {/* Decorative image grid */}
                                            <div
                                                aria-hidden="true"
                                                className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                                            >
                                                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                                    <div className="flex items-center space-x-6 lg:space-x-8">
                                                        <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                            <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                                                                <img
                                                                    src={data.helpyselections[0].imageOne.url}
                                                                    alt=""
                                                                    className="w-full h-full object-center object-cover"
                                                                />
                                                            </div>
                                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                                <img
                                                                    src={data.helpyselections[0].imageTwo.url}
                                                                    alt=""
                                                                    className="w-full h-full object-center object-cover"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                                <img
                                                                    src={data.helpyselections[0].imageThree.url}
                                                                    alt=""
                                                                    className="w-full h-full object-center object-cover"
                                                                />
                                                            </div>
                                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                                <img
                                                                    src={data.helpyselections[0].imageFour.url}
                                                                    alt=""
                                                                    className="w-full h-full object-center object-cover"
                                                                />
                                                            </div>
                                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                                <img
                                                                    src={data.helpyselections[0].imageFive.url}
                                                                    alt=""
                                                                    className="w-full h-full object-center object-cover"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                                <img
                                                                    src={data.helpyselections[0].imageSix.url}
                                                                    alt=""
                                                                    className="w-full h-full object-center object-cover"
                                                                />
                                                            </div>
                                                            <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                                <img
                                                                    src={data.helpyselections[0].imageSeven.url}
                                                                    alt=""
                                                                    className="w-full h-full object-center object-cover"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={data.helpyselections[0].button.link}>
                                                <p
                                                    href="#"
                                                    className="inline-block text-center bg-blue-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-blue-700"
                                                >
                                                    {data.helpyselections[0].button.text}
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <main>
                            {/* Category section */}
                            <section aria-labelledby="category-heading" className="bg-gray-50">
                                <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-baseline sm:justify-between">
                                        <h2 id="category-heading" className="text-2xl font-extrabold tracking-tight text-gray-900">
                                            Compra por categoria
                                        </h2>
                                        <Link to={"/esentials"}>
                                            <span className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
                                                Ver todo<span aria-hidden="true"> &rarr;</span>
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                                        <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
                                            <img
                                                src="https://images.unsplash.com/photo-1631048499455-4f9e26f23b9f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                                alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                                                className="object-center object-cover group-hover:opacity-75"
                                            />
                                            <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50" />
                                            <div className="p-6 flex items-end">
                                                <div>
                                                    <h3 className="font-semibold text-white">
                                                        <Link to={"/esentials/closets"}>
                                                            <span className="absolute inset-0" />
                                                            Closets
                                                        </Link>
                                                    </h3>
                                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                                        comprar
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full">
                                            <img
                                                src="https://images.unsplash.com/photo-1514237487632-b60bc844a47d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"
                                                alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                                                className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                                            />
                                            <div
                                                aria-hidden="true"
                                                className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                                            />
                                            <div className="p-6 flex items-end sm:absolute sm:inset-0">
                                                <div>
                                                    <h3 className="font-semibold text-white">
                                                        <Link to={"/esentials/organización"}>
                                                            <span className="absolute inset-0" />
                                                            Organización
                                                        </Link>
                                                    </h3>
                                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                                        comprar
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full">
                                            <img
                                                src="https://images.unsplash.com/photo-1492138786289-d35ea832da43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                                alt="Walnut desk organizer set with white modular trays, next to porcelain mug on wooden desk."
                                                className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                                            />
                                            <div
                                                aria-hidden="true"
                                                className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                                            />
                                            <div className="p-6 flex items-end sm:absolute sm:inset-0">
                                                <div>
                                                    <h3 className="font-semibold text-white">
                                                        <Link to={"/esentials/home%20office"}>
                                                            <span className="absolute inset-0" />
                                                            Home Office
                                                        </Link>
                                                    </h3>
                                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                                        comprar
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 sm:hidden">
                                        <Link to={"/esentials"}>
                                            <p className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                                                ver<span aria-hidden="true"> &rarr;</span>
                                            </p>
                                        </Link>

                                    </div>
                                </div>
                            </section>

                            {/* Featured section */}
                            <section aria-labelledby="cause-heading">
                                <div className="relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
                                    <div className="absolute inset-0 overflow-hidden">
                                        <img
                                            src="https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-full-width.jpg"
                                            alt=""
                                            className="w-full h-full object-center object-cover"
                                        />
                                    </div>
                                    <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-50" />
                                    <div className="relative max-w-3xl mx-auto flex flex-col items-center text-center">
                                        <h2 id="cause-heading" className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                                            {data.helpyselections[0].ctaOne}
                                        </h2>
                                        <p className="mt-3 text-xl text-white">
                                            {data.helpyselections[0].description}
                                        </p>
                                        <Link to={data.helpyselections[0].linkCta}>
                                            <p
                                                className="mt-8 w-full block bg-white border border-transparent rounded-md py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
                                            >
                                                Ver más
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </section>

                            {/* Favorites section */}
                            <section aria-labelledby="favorites-heading">
                                <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-baseline sm:justify-between">
                                        <h2 id="favorites-heading" className="text-2xl font-extrabold tracking-tight text-gray-900">
                                            Los favoritos
                                        </h2>

                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 lg:gap-x-8">
                                        {data.helpyselections[0].products.map((favorite, id) => (
                                            <div key={id} className="group relative">
                                                <Link to={`product/${favorite.name}/${favorite.sub_category.subcat}/${favorite.id}`}>
                                                    <div className="w-full h-96 rounded-lg overflow-hidden group-hover:opacity-75 sm:h-auto sm:aspect-w-2 sm:aspect-h-3">
                                                        <img
                                                            src={favorite.images[0].url}
                                                            alt={favorite.name}
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                    <h3 className="mt-4 text-base font-semibold text-gray-900">

                                                        <span className="absolute inset-0" />
                                                        {favorite.name}

                                                    </h3>
                                                    <div className='flex flex-row justify-start'>
                                                        <p className="font-medium text-lg text-gray-900 ">{favorite.price}  </p>
                                                        <p className='text-xs ml-1 leading-loose'>mxn </p>
                                                        <ChevronRightIcon className='w-6 h-6 text-blue-400 group-hover:text-blue-500' />
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>


                                </div>
                            </section>

                            {/* CTA section */}
                            <section aria-labelledby="sale-heading">
                                <div className="pt-32 overflow-hidden sm:pt-14">
                                    <div className="bg-gray-800">
                                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                            <div className="relative pt-48 pb-16 sm:pb-24">
                                                <div>
                                                    <h2 id="sale-heading" className="text-4xl font-extrabold tracking-tight text-white md:text-5xl">
                                                        {data.helpyselections[0].ctaFinal}
                                                    </h2>
                                                    <div className="mt-6 text-base">
                                                        <Link to={data.helpyselections[0].linkFinal}>
                                                            <p className="font-semibold text-white">
                                                                {data.helpyselections[0].subtitleFinal}<span aria-hidden="true"> &rarr;</span>
                                                            </p>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="absolute -top-32 left-1/2 transform -translate-x-1/2 sm:top-6 sm:translate-x-0">
                                                    <div className="ml-24 flex space-x-6 min-w-max sm:ml-3 lg:space-x-8">
                                                        <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                                            <div className="flex-shrink-0">
                                                                <img
                                                                    className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                                    src={data.helpyselections[0].imageOne.url}
                                                                    alt=""
                                                                />
                                                            </div>

                                                            <div className="mt-6 flex-shrink-0 sm:mt-0">
                                                                <img
                                                                    className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                                    src={data.helpyselections[0].imageTwo.url}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                                            <div className="flex-shrink-0">
                                                                <img
                                                                    className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                                    src={data.helpyselections[0].imageThree.url}
                                                                    alt=""
                                                                />
                                                            </div>

                                                            <div className="mt-6 flex-shrink-0 sm:mt-0">
                                                                <img
                                                                    className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                                    src={data.helpyselections[0].imageFour.url}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                                            <div className="flex-shrink-0">
                                                                <img
                                                                    className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                                    src={data.helpyselections[0].imageFive.url}
                                                                    alt=""
                                                                />
                                                            </div>

                                                            <div className="mt-6 flex-shrink-0 sm:mt-0">
                                                                <img
                                                                    className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                                    src={data.helpyselections[0].imageSix.url}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>


                    </div>
                    :
                    <div className='py-48 w-24 mx-auto'>
                        <Loader />
                    </div>
            }
        </>
    )
}
