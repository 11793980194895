export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"
export const ADD_PRODUCT_IN_ORDER = "ADD_PRODUCT_IN_ORDER"
export const ADD_PRODUCT_IN_ORDER_ADMIN = "ADD_PRODUCT_IN_ORDER_ADMIN"
export const REMOVE_PRODUCT_IN_ORDER = "REMOVE_PRODUCT_IN_ORDER"
export const ADD_CART_ORDER = "ADD_CART_ORDER"
export const ADD_CART_ORDER_ADMIN = "ADD_CART_ORDER_ADMIN"
export const REMOVE_SERVICE_CART_ORDER = "REMOVE_SERVICE_CART_ORDER"
export const ADD_CART_PRODUCT = "ADD_CART_PRODUCT"
export const ADD_CART_PRODUCT_ADMIN = "ADD_CART_PRODUCT_ADMIN"
export const OPEN_CARD_OVERLAY = "OPEN_CARD_OVERLAY"
export const CLOSE_CARD_OVERLAY = "CLOSE_CARD_OVERLAY"
export const DATE_SELECTED = "DATE_SELECTED"
export const TIME_SELECTED = "TIME_SELECTED"
export const LOG_CARD = "LOG_CARD"
export const PAY_FAST_ORDER = "PAY_FAST_ORDER"
export const EMPTY_FAST_ORDER = "EMPTY_FAST_ORDER"
export const EMPTY_FAST_PRODUCTS = "EMPTY_FAST_PRODUCTS"
export const NEW_ADDRESS = "NEW_ADDRESS"
export const PRE_USER = "PRE_USER"
export const LOGIN_USER = "LOGIN_USER"
export const LOADING_MAGIC_USER = "LOADING_MAGIC_USER"
export const LOG_OUT = "LOG_OUT"
export const CHECK_USER = "CHECK_USER"
export const FAILED_LOGIN = "FAILED_LOGIN"
export const CHECK_LOGIN_USER = "CHECK_LOGIN_USER"
export const ID_USER = "ID_USER"
export const SET_NEW_ADDRESS_LOCAL = "SET_NEW_ADDRESS_LOCAL"
export const NEW_USER_TRUE = "NEW_USER_TRUE"
export const SET_NEW_CARD = "SET_NEW_CARD"
export const SET_NEW_ADDRESS = "SET_NEW_ADDRESS"
export const REMOVE_PRODUCTS_CART_AFTER_PAY_IN_CART = "REMOVE_PRODUCTS_CART_AFTER_PAY_IN_CART"
export const TOKEN_RECIEVED = "TOKEN_RECIEVED"
export const SET_INTERESADO = "SET_INTERESADO"
export const SET_USER_TYPE = "SET_USER_TYPE"
export const SET_USER_SELECTED_ADMIN = "SET_USER_SELECTED_ADMIN"
export const SET_CART_NUM = "SET_CART_NUM"
export const ADD_CART_ORDER_HEAVEN= "ADD_CART_ORDER_HEAVEN"