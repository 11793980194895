import React from 'react'
import SyncLoader from "react-spinners/SyncLoader"

export default function Loader() {

    const color = "#EF4315"


    return (
        <>
            <SyncLoader className="y-8" color={color} />
        </>
    )
}
