import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import "./App.css";
import AddNewAddress from './Components/buyProcess/AddNewAddress';
import AddNewCard from './Components/buyProcess/AddNewCard';
import Cart from './Components/buyProcess/Cart';
import ScrollToTop from './Components/Controllers/ScrollToTop';
import ClientDashboard from './Components/dashboard/ClientDashboard';
import DashboardFull from './Components/dashboard/DashboardFull';
import OrderReport from './Components/dashboard/OrderReport';
import AvisoPrivacidad from './Components/landing/AvisoPrivacidad';
import Categories from './Components/landing/Categories';
import Error404 from './Components/landing/Error404';
import Footer from './Components/landing/Footer';
import GridExplore from './Components/landing/GridExplore';
import HeroHome from "./Components/landing/HeroHome";
import ListSubcat from './Components/landing/ListSubcat';
import LoginLanding from './Components/landing/LoginLanding';
import Navbar from "./Components/landing/Navbar";
import OnlyProductsOverview from './Components/landing/OnlyProductsOverview';
import RequestPreview from './Components/landing/RequestPreview';
import Selection from './Components/landing/Selection';
import Service from './Components/landing/Service';
import ServiceAndProductsByCat from './Components/landing/ServiceAndProductsByCat';
import Subcat from './Components/landing/Subcat';
import SuccesDate from './Components/landing/SuccesDate';
import Terminos from './Components/landing/Terminos';
import RegisterForm from './Components/Users/Clients/RegisterForm';
import CreateService from './Components/Users/Pro/CreateService';
import DashboardAdmin from './Components/Users/Pro/DashboardAdmin';
import DashboardWorker from './Components/Users/Pro/DashboardWorker';
import RateService from './Components/Users/Pro/RateService';
import AuthState from './Context/Auth/AuthState';
import ModalState from './Context/Modal/ModalState';
import ProductsState from './Context/Products/ProductsState';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
function App() {
  return (
    <AuthState>
    <ProductsState>
    <ModalState>

      <Elements stripe={stripePromise}>

      <Router>
          <Navbar />
          <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HeroHome}/>
          <Route exact path="/login" component={LoginLanding}/>
          <Route exact path="/company/dashboard" component={DashboardAdmin}/>
          <Route exact path="/worker/dashboard" component={DashboardWorker}/>
          <Route exact path="/servicio/:name" component={Service}/>
          <Route exact path="/explorar" component={GridExplore}/>
          <Route exact path="/compra/carrito/:idCart" component={Cart}/>
          <Route exact path="/nueva/direccion" component={AddNewAddress}/>
          <Route exact path="/nueva/tarjeta" component={AddNewCard}/>
          <Route exact path="/client/registration" component={RegisterForm}/>
          <Route exact path="/dashboard/client/:idOrder" component={ClientDashboard}/>
          <Route exact path="/helpySelection" component={Selection}/>
          <Route exact path="/categorias" component={Categories}/>
          <Route exact path="/categoria/:servicio" component={ServiceAndProductsByCat}/>
          <Route exact path="/request/:reqId" component={RequestPreview}/>
          <Route exact path="/product/:productName/:subcat/:id" component={OnlyProductsOverview}/>
          <Route exact path={"/esentials/:subcat"} component={Subcat}/>
          <Route exact path={"/esentials"} component={ListSubcat}/>
          <Route exact path={"/legal/terminoshelpy"} component={Terminos}/>
          <Route exact path={"/legal/avisoPrivacidad"} component={AvisoPrivacidad}/>
          <Route exact path={"/visita/success"} component={SuccesDate}/>
          <Route exact path={"/rateservice/:id"} component={RateService}/>
          <Route exact path={"/report/service/:idOrder"} component={OrderReport}/>
          {/* RUTAS PRIVADAS */}
          <Route exact path="/dashboard/company/finishit" component={CreateService}/>
          <Route exact path="/client/dashboard/:idClient" component={DashboardFull}/>
          {/* ERROR */}
          <Route path="*" component={Error404} />
        </Switch>
        <Footer></Footer>
      </Router>
      
      </Elements> 
      
    </ModalState>
    </ProductsState>
    </AuthState>
  );
}

export default App;
