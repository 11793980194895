import { Fragment, useContext, useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_CARTS, GET_PRODUCTS_RELATED_SUBCAT, GET_PRODUCT_BY_ID } from '../../GraphQL/Queries'
import ProductContext from '../../Context/Products/ProductsContext'
import ModalContext from '../../Context/Modal/ModalContext'
import LogCard from '../buyProcess/LogCard'
import ReactMarkdown from 'react-markdown'
import { SET_CART, UPDATE_CART } from '../../GraphQL/Mutations'
import Loader from '../microComponents/Loader'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function OnlyProductsOverview() {

    const history = useHistory()
    const { id, subcat, productName } = useParams()

    const { addProductToCart, addProdCurrentOrder , cartNum , setCartNum , order, productsCheckOut} = useContext(ProductContext)
    const { openCardOverlay } = useContext(ModalContext)
    const { data, loading  } = useQuery(GET_PRODUCT_BY_ID, { variables: { id: id } })
    const [newCart , setNewCart] = useState(true)
    const [findOneCart , { data: dataCart }] = useLazyQuery(GET_CARTS)
    const randum = Math.floor(Math.random() * 59595959)

    const [setCart] = useMutation(SET_CART)
    const [qty , setQty] = useState(1)
    const [qtyCart , setQtyCart] = useState([])
    const [updateCart] = useMutation(UPDATE_CART)
    const [loadNew , setLoadNew] = useState(false)

    
    useEffect(()=>{
   
           if(cartNum !== 0){
               setCartNum(cartNum)
               setNewCart(false)
              findOneCart({variables:{idCart:cartNum}})
           }else{
               setCartNum(randum)
           }
           
       },[])
    
    const productsRelated = useQuery(GET_PRODUCTS_RELATED_SUBCAT, { variables: { subCategory: subcat } })
    const [product, setProduct] = useState({
        name: '...',
        price: '... mxn',
        variantes: [],
        sub_category: {
            subcat: " "
        },
        images: [],
        description: "...",
        timeDelivery: {
            quantity: " ",
            unit: " "
        },
        deliveryPrice: " "
    })
    const [buttonClicked, setButtonClicked] = useState("active")

    const [relatedProducts, setRelatedProducts] = useState([])
    const [variant, setVariant] = useState(null)
    useEffect(() => {
        if (!loading) {

            setTimeout(()=> setLoadNew(false) , 1000) 
            setProduct(data.product)
            setQtyCart([{
                price: data.product.price,
                name: data.product.name,
                description: data.product.description,
                images: data.product.images,
                deliveryPrice: data.product.deliveryPrice,
                timeDelivery: data.product.timeDelivery
            }])
            if (product.variantes.length > 0) {
                setProduct({
                    ...product,
                    images: product.variantes[0].image,
                    price: product.variantes[0].price
                })
                setVariant(product.variantes[0])
            }
        }else{
            setLoadNew(true)
        }
    }, [data, loading, product.variantes.length , history , productName])

    useEffect(() => {
        if (productsRelated.data !== undefined) {
            setRelatedProducts(productsRelated.data.products)
        }
    }, [productsRelated])

    const handleVariant = (selected) => {
        setVariant(selected)
        setProduct({
            ...product,
            images: selected.image,
            price: selected.price
        })
        setQtyCart([{
            ...product,
            images: selected.image,
            price: selected.price
        }])
    }

    const whichButton = (which) => {
        setButtonClicked(which)
    }

    useEffect(()=>{
        if(qty < qtyCart.length){
            qtyCart.pop()
        }else{

            for(let i = 1 ; i <= qty ; i++){
                setQtyCart([...qtyCart , qtyCart[0]])
            }
        
        }
        
        
    },[qty])

    useEffect(()=>{
       if(qtyCart[0] === undefined){
        if (!loading) {

            setTimeout(()=> setLoadNew(false) , 1000) 
            setProduct(data.product)
            setQtyCart([{
                price: data.product.price,
                name: data.product.name,
                description: data.product.description,
                images: data.product.images,
                deliveryPrice: data.product.deliveryPrice,
                timeDelivery: data.product.timeDelivery
            }])
            if (product.variantes.length > 0) {
                setProduct({
                    ...product,
                    images: product.variantes[0].image,
                    price: product.variantes[0].price
                })
                setVariant(product.variantes[0])
            }
        }else{
            setLoadNew(true)
        }
       } 
    },[])


    const handlePayButton = async (e) => {
        e.preventDefault()

        if (buttonClicked === "pay") {
            addProdCurrentOrder({
                price: product.price,
                name: product.name,
                description: product.description,
                images: product.images,
                deliveryPrice: product.deliveryPrice,
                timeDelivery: product.timeDelivery
            })
            openCardOverlay()


        } else if (buttonClicked === "cart") {
            
             addProductToCart(qtyCart)

            
            if(newCart){
                await  setCart({
                    variables:{cart: 
                        {
                            
                        order:[...order] , 
                        
                        products: qtyCart
                } , statusC:"sent" , idCart:cartNum  }
                })
            }else{
                await updateCart({
                    variables:
                    { 
                        cart: {
                            order:[...order] , 
                            products:[...dataCart.carts[0].cartfull.products , ...qtyCart]} , 
                            id: dataCart.carts[0].id
                        }
                        
                    })
                }

                window.pinAddCart(product.price, product.name, subcat ,id)
                setTimeout(() => history.push(`/compra/carrito/${cartNum}` ), 1000)
        }
    }

    const relatedClick = (name, subcatt , id) =>{
        setQty(1)
        setLoadNew(true)
        history.push(`/product/${name}/${subcatt}/${id}`)
    }


    return (
        <div className="bg-white">
            <div className="border-b border-gray-200">
                <nav aria-label="Breadcrumb" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <ol className="flex items-center space-x-4 py-4">
                        <li>
                            <div className="flex flex-row items-center">
                                <Link to={`/esentials`}>
                                    <p className='text-sm font-medium underline text-gray-500 mr-2'>Categorías</p>
                                </Link>
                                <svg
                                    viewBox="0 0 6 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    className="h-5 w-auto text-gray-300"
                                >
                                    <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
                                </svg>
                                <Link to={`/esentials/${subcat}`}>
                                    <p className='text-sm font-medium underline text-gray-500 ml-2 mr-4'>{subcat.charAt(0).toUpperCase() + subcat.slice(1)}</p>
                                </Link>
                                <svg
                                    viewBox="0 0 6 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    className="h-5 w-auto text-gray-300"
                                >
                                    <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
                                </svg>
                                <p className="text-sm font-medium text-gray-900">
                                    {product.subCategory}
                                </p>

                            </div>
                        </li>
                        <li className="text-sm">
                            <p aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {productName}
                            </p>
                        </li>
                    </ol>
                </nav>
            </div>
            <LogCard total={product.price} type={"product"} delivery={{ "deliveryPrice": product.deliveryPrice, "timeDelivery": product.timeDelivery }}></LogCard>


            {
                !loadNew  ? 
                <main className="max-w-7xl mx-auto sm:pt-16 sm:px-6 lg:px-8">
                <div className="max-w-2xl mx-auto lg:max-w-none">
                    {/* Product */}
                    <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                        {/* Image gallery */}
                        <Tab.Group as="div" className="flex flex-col-reverse">
                            {/* Image selector */}
                            <div className="mt-6 w-full max-w-2xl mx-auto  lg:max-w-none">
                                <Tab.List className="grid grid-cols-4 gap-6 px-4 sm:px-4">

                                    {
                                        product.images.length === 0 ?
                                            <Tab className="bg-gray-200 h-24">

                                            </Tab>

                                            :

                                            product.images.map((image, id) => (
                                                <Tab
                                                    key={id}
                                                    className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span className="sr-only ">{image.name}</span>
                                                            <span className="absolute inset-0 rounded-md overflow-hidden">
                                                                <img src={image.url} alt="" className="w-full h-full object-center object-cover" />
                                                            </span>
                                                            <span
                                                                className={classNames(
                                                                    selected ? 'ring-blue-500' : 'ring-transparent',
                                                                    'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )}
                                                </Tab>
                                            ))
                                    }

                                </Tab.List>
                            </div>

                            <Tab.Panels className="w-full aspect-w-4 aspect-h-3">

                                {
                                    product.images.length === 0 ?
                                        <Tab.Panel className="bg-gray-300 animate-pulse">

                                        </Tab.Panel>
                                        :
                                        product.images.map((image, id) => (
                                            <Tab.Panel key={id}>
                                                <img
                                                    src={image.url}
                                                    alt={image.alt}
                                                    className="p-4 lg:p-1 w-full h-full object-center object-cover sm:rounded-lg"
                                                />
                                            </Tab.Panel>
                                        ))

                                }

                            </Tab.Panels>
                        </Tab.Group>

                        {/* Product info */}
                        <div className="mt-10 font-title px-4 sm:px-0 sm:mt-16 lg:mt-0">
                            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">{product.name} {variant !== null ? variant.title : null}</h1>

                            <div className="mt-1 font-medium">
                                <h2 className="sr-only">Product information</h2>
                                <p>Tiempo de entrega: {product.timeDelivery.quantity} {product.timeDelivery.unit}</p>
                                <p className="text-3xl font-body text-gray-900">{product.price.toLocaleString()} <span className='text-sm'>mxn</span></p>
                            </div>

                            <form className="mt-6" onSubmit={handlePayButton}>
                                <div className='flex flex-row space-x-3'>

                                    {
                                        product.variantes.length > 0 ?
                                            product.variantes.map((variant, id) => {
                                                return <div key={id}>

                                                    <button
                                                        onClick={() => handleVariant(variant)}
                                                        type="button"
                                                        className="relative block w-24 h-32 border-2 border-gray-300 border-dashed rounded-lg p-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        <div>
                                                            <img className='h-12 w-12 rounded-full mx-auto' src={variant.image[variant.image.length - 1].url} alt="" />
                                                        </div>
                                                        <span className="mt-2 block text-sm  font-medium text-gray-900">{variant.title}</span>
                                                    </button>
                                                </div>
                                            })
                                            :
                                            null
                                    }
                                </div>


                                <div className="mt-10 font-body flex flex-col lg:flex-row lg:space-x-4">


                                    <>

                                    
                                        <button
                                            type="submit"
                                            name='pay'
                                            onClick={() => whichButton("pay")}
                                            className={`max-w-xs flex-1 mt-2  border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-white bg-yellow-400 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 sm:w-full text-yellow-300 bg-yellow-100 `
                                            }
                                        >
                                            Comprar
                                        </button>
                                        
                                        <button
                                            name='cart'
                                            id="buttonCartUnique"
                                            type="submit"
                                            onClick={() => whichButton("cart")}
                                            className={`max-w-xs flex-1 mt-2 bg-white border-transparent rounded-md py-3 px-8 flex items-center justify-center shadow-sm text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 border-2 border-yellow-400`}
                                        >
                                            Añadir al carrito
                                        </button>
                                    </>

                                </div>
  <div>
                            <div>
                                
                            <div className="max-w-xs flex flex-row h-10 sm:max-w-full  rounded-lg relative border-solid border-2 border-gray-600 mt-5">
                                <button 
                                onClick={()=>qty > 1 && setQty(qty - 1)}
                                className=" bg-white text-gray-600 hover:text-gray-700 h-full border-1 border-yellow-400 w-20 rounded-l cursor-pointer ">
                                <span className="m-auto text-2xl font-thin">−</span>
                                </button>
                                <input type="number" className=" 
                                 text-center w-full bg-white font-semibold  flex items-center text-gray-700 " name="custom-input-number" value={qty}></input>
                            <button 
                                onClick={()=>setQty(qty + 1)}
                                className="bg-white text-gray-600 hover:text-gray-700 border-1  border-yellow-400 h-full w-20 rounded-r cursor-pointer">
                                <span className="m-auto text-2xl font-thin">+</span>
                            </button>
                            </div>
                            </div>
                            
                            </div>
                            </form>

                            <div className="mt-6 text-base font-body text-gray-700 space-y-6">
                                <h3 className="sr-only">Descripción</h3>
                                <ReactMarkdown>{product.description}</ReactMarkdown>
                                <p className="">  {variant !== null ? variant.extra : null} </p>
                            </div>




                        </div>
                    </div>

                    <section aria-labelledby="related-heading" className="mt-10 border-t border-gray-200 py-16 px-4 sm:px-0">

                        {
                            relatedProducts.length === 0 ?
                                null
                                :
                                <div className='font-title'>
                                    <h2 id="related-heading" className="text-xl font-bold text-gray-900">
                                        También te puede interesar
                                    </h2>
                                    <div className="mt-8 flex flex-row overflow-x-auto space-x-10 pb-4">
                                        {relatedProducts.map((product, id) => (
                                            <div className="cursor-pointer" key={id}>
                                                <div
                                                onClick={()=>relatedClick(product.name, subcat , product.id)}
                                                >
                                                    <div className="relative">
                                                        <div className="relative w-64 h-72 rounded-lg overflow-hidden">
                                                            <img
                                                                src={product.images[0].url}
                                                                alt={product.name}
                                                                className="w-full h-full object-center object-cover"
                                                            />
                                                        </div>
                                                        <div className="relative mt-4">
                                                            <h3 className="text-sm font-medium text-gray-900 text-ellipsis overflow-hidden">{product.name}</h3>
                                                        </div>
                                                        <div className="absolute top-0 inset-x-0 h-72 rounded-lg p-4 flex items-end justify-end overflow-hidden">
                                                            <div
                                                                aria-hidden="true"
                                                                className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                                                            />
                                                            <p className="relative text-lg font-semibold text-white"><span className='font-body'>{product.price} mxn</span> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                        }

                    </section>
                </div>
                </main>
            : 
                        <div className="my-12 text-center">
                <Loader></Loader>
                        </div>
            }
            


        </div>
    )
}






