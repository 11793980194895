import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { GET_SUBCAT } from '../../GraphQL/Queries';

export default function ListSubcat() {

    const { data, loading } = useQuery(GET_SUBCAT)
    return (

        <>

            <div className=" flex items-center justify-center">

                <div className=" grid  md:grid-cols-4 grid-cols-2 gap-x-5 lg:gap-x-10 lg:gap-y-4 lg:py-24 lg:px-56 p-8">



                    {
                        !loading ?

                            data.subCategories.map((cat, id) => {
                                return (
                                    <>
                                        {
                                            id % 5 === 0 ?
                                                <>

                                                    {
                                                        id === data.subCategories.length - 1 ?
                                                            <div key={id} className=' col-span-2 font-bold md:col-span-4 '>
                                                                <div className=" bg-gray-200 h-48 bg-center rounded-lg overflow-hidden shadow-md ">
                                                                    <Link to={`/esentials/${cat.subcat}`}>
                                                                        <img
                                                                            src={cat.image.url}
                                                                            alt={cat.image.url}
                                                                            className="w-full "
                                                                        />
                                                                    </Link>
                                                                </div>
                                                                <h1 className='font-title text-white text-md lg:text-2xl  relative -top-1/2 text-center' >{cat.subcat}</h1>
                                                            </div>
                                                            :

                                                            <div key={id} className='col-span-2 font-bold'>
                                                                <div className="rounded-lg bg-center  h-48 bg-gray-200 overflow-hidden shadow-md ">
                                                                    <Link to={`/esentials/${cat.subcat}`}>
                                                                        <img
                                                                            src={cat.image.url}
                                                                            alt={cat.image.url}
                                                                            className="w-full "
                                                                        />
                                                                    </Link>
                                                                </div>
                                                                <h1 className='font-title text-white text-md lg:text-2xl relative -top-1/2 text-center' >{cat.subcat}</h1>
                                                            </div>
                                                    }
                                                </>
                                                :
                                                <div key={id} className='font-bold'>
                                                    <div className="h-48  bg-gray-200 bg-center rounded-lg overflow-hidden shadow-md">
                                                        <Link to={`/esentials/${cat.subcat}`}>
                                                            <img
                                                                src={cat.image.url}
                                                                alt={cat.image.url}
                                                                className="w-full"
                                                            />
                                                        </Link>
                                                    </div>
                                                    <h1 className='font-title text-white text-md lg:text-2xl relative -top-1/2 text-center' >{cat.subcat}</h1>
                                                </div>


                                        }

                                    </>
                                )
                            }) : null


                    }



                </div>

            </div >

        </>

    )
}
