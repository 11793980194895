import { Fragment , useState} from 'react'

import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import { useMutation } from '@apollo/client'
import { UPDATE_STATUS_ORDER } from '../../GraphQL/Mutations'
import Alert from './Alert'
import { GET_ORDERS_TODO } from '../../GraphQL/Queries'

export default function DropChangeStatus({order , setIsOpen}) {

    const people = [
        {
          id: 0,
          option: 'paid',
        },
        {
            id: 1,
            option: 'acceptedProject',
          },
          {
            id: 2,
            option: 'Omw',
          },
          {
            id: 3,
            option: 'building',
          },
          {
            id: 4,
            option: 'done',
          },
          {
            id: 5,
            option: 'cancel',
          }
        
      ]

      const [updateStatusOrder , {loading}]= useMutation(UPDATE_STATUS_ORDER , {
        onCompleted: () => success(),
        refetchQueries:[
            {query:GET_ORDERS_TODO},
            'getSerToDo'
        ]
    })

    const [alert,setAlert] = useState({open: false, error: "null",color:"red"})

    const success = ()=>{
        setAlert({open: true, error: "Oferta actualizada con éxito", color:"green",})
        setTimeout(()=>{
            setIsOpen(false)
        }, 800)
    }

     function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
          }
          const [selected, setSelected] = useState(people[1])

          const submitStatus = ()=>{
            updateStatusOrder({variables:{
                id: order,
                status:selected.option
            }})
        }

return (
    
    <div className="mt-3 font-title text-center sm:mt-5 py-16 ">
   
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">Actualizar Status</Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="flex items-center">
                
                <span className="ml-3 block truncate">{selected.option}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {people.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-blue-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {person.option}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-black' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    <Alert status={alert}></Alert>

    {
        loading? 
        <button
                                                        type="button"
                                                        disabled
                                                        className="cursor-not-allowed px-4 py-3 border border-gray-300 shadow-sm w-full text-base font-medium rounded-md text-gray-700 bg-yellow-300 hover:bg-yellow-200 focus:outline-none ">
                                                        enviando ...
                                                    </button>
        :
        <button
onClick={submitStatus}
                                                    type="button"
                                                    className=" px-4 py-3 my-6 border border-gray-300 shadow-sm w-full text-base font-medium rounded-md text-gray-700 bg-yellow-300 hover:bg-yellow-200 focus:outline-none ">
                                                    Actualizar status
                                                </button>

    }

    
    
            

</div>
  )
}
