import React, { useContext, useEffect, useState } from 'react'
import ProductContext from '../../Context/Products/ProductsContext'
import RelatedServices from '../microComponents/RelatedServices'
import { GET_CARTS, GET_SERVICESBYCATEGORY } from '../../GraphQL/Queries'
import { useQuery } from '@apollo/client'
import Loader from '../microComponents/Loader'
import Order from '../buyProcess/Order'
import CtaCart from '../buyProcess/CtaCart'
import { useLocation , useParams} from 'react-router-dom'
import Searchbar from '../landing/Searchbar'

export default function Cart() {


    const { order, products, categoryByMoth, category, productsCheckOut , setCartNum , addOrderToCartFromHeaven , addProductToCart , cartNum} = useContext(ProductContext)
    const [sumItems, setSumItems] = useState(0)
    const [catOrder, setCatOrder] = useState(["Handyman"])
    const [sumPrices, setSumPrices] = useState(0)
    const [sumDelivery, setSumdelivery] = useState(0)
    const [localProducts, setLocalProducts] = useState(productsCheckOut)
    const [localOrder, setLocalOrder] = useState(order)
    const [idCartDb, setIdCartDb] = useState(null)
    const location = useLocation()

    const [company, setCompany] = useState({
        id: null,
        company : false
    })


    const { idCart } = useParams()
    

    const { data: dataCart } = useQuery(GET_CARTS , {variables:{idCart: idCart}})
    const { data } = useQuery(GET_SERVICESBYCATEGORY, { variables: { category: catOrder } })

    
    useEffect(()=>{
        if(dataCart !== undefined){

            if(dataCart.carts.length !== 0){
                setIdCartDb(dataCart.carts[0].id)
                setLocalOrder(dataCart.carts[0].cartfull.order)
                setLocalProducts(dataCart.carts[0].cartfull.products)
            }
        }
    },[order , productsCheckOut , dataCart , cartNum])
    
    useEffect(()=>{
        if(dataCart !== undefined){
            if(dataCart.carts.length !== 0){

                
                if(order.length === 0){
                    addOrderToCartFromHeaven(dataCart.carts[0].cartfull.order)
                }
                
                if(productsCheckOut.length === 0){
                    addProductToCart(dataCart.carts[0].cartfull.products)
                }
            }
        }
        
    },[dataCart])
    
    useEffect(()=>{
        if(location.state !== undefined){
            setCompany(location.state.requestData)
            setIdCartDb(location.state.requestData.id)
        }else{
            
            setCompany({
                id: null,
                company : false
            })
        }
    },[location])
    
    useEffect(() => {
        setSumItems(localProducts.length + localOrder.length)
        if (localOrder.length !== 0) {
            setCatOrder(localOrder[0].category)
        } else {
            setCatOrder(category)
        }
        
        const sumOrder = localOrder.reduce((a, b) => { return a + Number(b.price) }, 0)
        const sumProducts = localProducts.reduce((a, b) => { return a + Number(b.price) }, 0)

        setSumPrices(sumOrder + sumProducts)
    }, [products, localOrder, category, localProducts])

    useEffect(() => {
        if (localProducts.length > 0) {
            const sum = localProducts.reduce((a, b) => { return a + Number(b.deliveryPrice) }, 0)
            if (localOrder.length === 0) {
                setSumdelivery(sum)
            }
        }
        
        setCartNum(idCart)
    }, [])
    
    
    useEffect(() => {
        categoryByMoth()
    }, [])
    
    
    
    
    return (
        <>
            <div className="bg-gray-100 p-5">
                <div className="bg-white  sm:w-3/4   mx-auto overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6 ">

                    <div className="mt-4">


      <Searchbar></Searchbar>
      </div>

                        {
                            sumItems === 0 && cartNum !== 0?


                                <div >
                                    <div >
                                        <img
                                            className="mx-auto w-1/2 md:w-1/3 "
                                            src="../img/cart-empty.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                                        <div className="md:ml-auto  md:pl-10 font-bold">

                                            <h1 className="mt-4 text-black text-2xl font-title tracking-tight sm:text-2xl">¿No sabes que agregar?</h1>
                                        </div>
                                        <div className="md:ml-auto  md:pl-10 ">
                                            <h3 className="mt-4 relative bottom-3 text-black text-lg font-title tracking-tight">
                                                Te sugiero lo siguiente
                                            </h3>
                                            <div className="mt-6">
                                                {
                                                    data !== undefined ?
                                                        <RelatedServices data={data} />

                                                        :
                                                        <Loader></Loader>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                :
                                <div>
                                    <CtaCart subtotal={sumPrices} items={sumItems} delivery={sumDelivery} idComp={company}></CtaCart>
                                    <Order fromCart={true} order={localOrder} products={localProducts} idCart={idCartDb}></Order>
                                    
                                    
                                    <div className="mt-6">
                                        {
                                            data !== undefined ?
                                                <RelatedServices data={data} />

                                                :
                                                <Loader></Loader>
                                        }
                                    </div>

                                    {
                                        localOrder.length > 0 ? 

                                        localOrder.map((disclaimer , id)=>{
                                            return <div className="mt-4">
                                                
                                                <p className="text-xs font-body"><span className="text-xxs text-red-600">*{id}</span> {disclaimer.description}</p>
                                                <p className="text-xs font-body"><span className="text-xxs text-red-600">*{id}</span> {disclaimer.limitations}</p>
                                            </div>
                                        })

                                        :

                                        null
                                    }
                                </div>
                        }

                    </div>

                </div>
            </div>
        </>
    )
}
