import React from 'react'
import LoginForm from './LoginForm'

export default function LoginLanding() {
  return (
    <div className="my-20" >
    <LoginForm></LoginForm>
    </div>
  )
}
