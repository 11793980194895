import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Keyboard, Mousewheel, Scrollbar } from 'swiper/core';
import { Link } from 'react-router-dom';



SwiperCore.use([Scrollbar, Mousewheel, Keyboard]);



const people = [
    {
        name: 'Aire acondicionado',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-aire-acondicionado.png",
    },
    {
        name: 'Albañilería',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-albanileria.png"
    },
    {
        name: 'Baños',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-banos.png"
    },
    {
        name: 'Carpintería',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-carpinteria.png"
    },
    {
        name: 'Cerrajero',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-cerrajero.png"
    },
    {
        name: 'Cocina',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-cocina.png"
    },
    {
        name: 'Electricidad',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-electricidad.png"
    },
    {
        name: 'Electrodomésticos',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-electrodomesticos.png"
    },
    {
        name: 'Fumigación',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-fumigacion.png"
    },
    {
        name: 'Handyman',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-handyman.png"
    },
    {
        name: 'Jardín',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-jardineria.png"
    },
    {
        name: 'Mudanza y Fletes',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-flete.png"
    },
    {
        name: 'Pintura',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-pintura.png"
    },
    {
        name: 'Pisos',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-pisos.png"
    },
    {
        name: 'Plomería',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-plomeria.png"
    },
    {
        name: 'Puertas y ventanas',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-puertas.png"
    },
    {
        name: 'Remodelación',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-remodelacion.png"
    },
    {
        name: 'Seguridad',
        role: 'Co-Founder / CTO',
        imageUrl:
            "../img/iconos/g-seguridad.png"
    },

]




export default function CirclesCat({ items }) {
    return (
        <div className="bg-white">
            <div className="max-w-5xl mx-auto pt-16 px-4 sm:px-6 lg:px-8 lg:py-12">
                <div className="space-y-8 sm:space-y-12">
                    {/* <div className="space-y-5  font-title sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Comprar por categoría</h2>

                    </div> */}

                    <Swiper
                        spaceBetween={40}
                        slidesPerView={2.5 + items}
                        scrollbar={true}
                        loop={true}
                        mousewheel={true}
                        keyboard={true}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                        <ul
                            className=" "
                        >


                            {people.map((category, id) => (
                                <SwiperSlide key={id}>

                                    <li >
                                        <div className="space-y-4 w-24">
                                            <Link to={`/categoria/${category.name}`}>
                                                <img className="mx-auto h-12 w-12 rounded-full lg:w-20 lg:h-20" src={category.imageUrl} alt="" />
                                                <div className="space-y-2">
                                                    <div className="text-xs font-medium lg:text-sm">
                                                        <h3 className='text-center break-words' >{category.name}</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                </SwiperSlide>
                            ))}
                        </ul>
                    </Swiper>

                    <div className='text-center relative -top-5'>
                        <Link to={"/categorias"}>
                            <h5>ver categorías</h5>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}
