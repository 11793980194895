import { useQuery } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import { GET_FOUR_MOST_VIEWED } from '../../GraphQL/Queries'

const products = [
    {
        id: 1,
        name: 'Remodela tu cuarto',
        category: 'New year, new you',
        href: '#',
        price: '2800',
        imageSrc: 'https://images.unsplash.com/photo-1615874959474-d609969a20ed?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80',
        imageAlt:
            'Payment application dashboard screenshot with transaction table, financial highlights, and main clients on colorful purple background.',
    },
    {
        id: 2,
        name: 'Cambia tu entorno',
        category: 'Cambia tu vida',
        href: '#',
        price: '1900',
        imageSrc: 'https://images.unsplash.com/photo-1562663474-6cbb3eaa4d14?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
        imageAlt:
            'Payment application dashboard screenshot with transaction table, financial highlights, and main clients on colorful purple background.',
    },
    {
        id: 3,
        name: 'Espacios de reflexión',
        category: 'Tu esquina personal',
        href: '#',
        price: '3999',
        imageSrc: 'https://i.pinimg.com/564x/86/4a/47/864a47fe227e1f2229949968661f4f1e.jpg',
        imageAlt:
            'Payment application dashboard screenshot with transaction table, financial highlights, and main clients on colorful purple background.',
    },
    {
        id: 4,
        name: 'Work hard',
        category: 'El espacio si importa',
        href: '#',
        price: '1200',
        imageSrc: 'https://images.pexels.com/photos/2294361/pexels-photo-2294361.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        imageAlt:
            'Payment application dashboard screenshot with transaction table, financial highlights, and main clients on colorful purple background.',
    },
    // More products...
]

export default function MisPromos() {

    const { data, loading } = useQuery(GET_FOUR_MOST_VIEWED)

    return (
        <div className="bg-white">
            <div className="max-w-2xl mx-auto py-10 px-4 sm:pt-2 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center font-body justify-between space-x-4">
                    <h2 className="text-lg font-medium text-gray-900">Los más visto: </h2>

                </div>
                {
                    !loading && data !== undefined ?
                        <div className="mt-6 grid grid-cols-2 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                            {data.mostVieweds.map((product, id) => (
                                <div key={id} className="relative group font-title">
                            <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                                        <img src={product.imageSrc.url} alt={product.service.name} className="object-center object-cover" />
                                <div className="flex items-end opacity-0 p-4 group-hover:opacity-100" aria-hidden="true">
                                            <div className="w-full bg-yellow-200 bg-opacity-75 backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                                                ver más
                                    </div>
                                </div>
                            </div>
                                    <div className="mt-4 lg:flex items-center justify-between text-base font-medium text-gray-900 lg:space-x-8">
                                <h3>
                                            <Link to={`/servicio/${product.service.name}`}>
                                        <span aria-hidden="true" className="absolute inset-0" />
                                                {product.service.name}
                                            </Link>

                                        </h3>
                                        <div className='text-left'>
                                        <p className='text-sm'> <span className='text-xs'> desde :</span> {product.startingPrice.toLocaleString()} <span className='text-xs'>mxn</span></p>
                                        </div>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500 font-body">{product.catchFrase}</p>
                                </div>
                            ))}
                        </div>
                        :

                        <div className="mt-6 grid grid-cols-2 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                            {[1, 2, 3, 4].map((product, id) => (
                                <div key={id} className="relative group font-title">
                                    <div className="aspect-w-4 aspect-h-3 animate-pulse rounded-lg overflow-hidden bg-gray-200">
                                        <div className="flex items-end opacity-0 p-4 group-hover:opacity-100" aria-hidden="true">

                                        </div>
                                    </div>
                                    <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900 space-x-8">

                                        <span className='w-full h-4 rounded-md animate-pulse bg-gray-100'>
                                            <span></span>
                                        </span>



                            </div>

                        </div>
                    ))}
                </div>
                }

            </div>
        </div>
    )
}
