import { CheckCircleIcon, CheckIcon, XCircleIcon, XIcon } from "@heroicons/react/solid"
import { useContext , useState , useEffect } from 'react'
import ProductContext from "../../Context/Products/ProductsContext"
import { useLocation } from 'react-router-dom'
import ModalDetailsCart from "../microComponents/ModalDetailsCart"
import { useMutation } from "@apollo/client"
import { UPDATE_CART } from "../../GraphQL/Mutations"

export default function Order({ order, products, type , fromCart , editPrice , setTotal , idCart}) {

// Mostrar la tachita si fromCart es verdadero
// Hacer el handle button para despues quitarlo del la lista y crear la nueva lista, trar tambien la funcion del contexto de prductos

const {
    removeServiceFromCart,

    addProductToOrderAdmin, 
    orderToFastPay , 
    orderFastCheckOut , 
    products: productContext , 

    addOrderToCartAdmin , 
    addProductToCartAdmin , 
    order:orderContex , 
    productsCheckOut

} = useContext(ProductContext)

const [orderLocal , setOrderLocal] = useState(order)
const [productsLocal , setProductsLocal] = useState(products)
const [newPrice , setNewPrice] = useState()
const [newPriceProd , setNewPriceProd] = useState()
const [editThePrice , setEditThePrice] = useState({
    edit : false
})
const [arrayOfCartAndOrder , setArrayOfCartAndOrder] = useState([])
const [sumPrices, setSumPrices] = useState(0)
const [alertIcon , setAlertIcon] = useState({
    on : false,
    type: "check"
})
const [showTotal, setShowTotal] = useState(false)
const [isOpen , setIsOpen] = useState(false)
const location = useLocation()
const [updateCartdb] = useMutation(UPDATE_CART)
// const [sumDelivery, setSumdelivery] = useState(0)

useEffect(() => {
    if (location.pathname.includes("/dashboard/company/finishit")) {
        setShowTotal(true)
    }
}, [location])


useEffect(() => {
    if(editPrice === undefined){
        setEditThePrice({
            edit : false
        })
    } else{
        setEditThePrice(editPrice)
        
        if(editPrice.from === "fastCheckOut"){
            setArrayOfCartAndOrder([orderFastCheckOut , productContext]) 
        }else if(editPrice.from === "cartCheckOut"){
            setArrayOfCartAndOrder([orderContex , productsCheckOut]) 
        }else{
            setArrayOfCartAndOrder([orderContex , productsCheckOut, orderFastCheckOut , productContext]) 
        }
    }
}, [editPrice])

useEffect(()=>{


    const count = [...new Set(productsLocal.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

    const group = (arr) => {

        const reduced = arr.reduce((acc, curr) => {
            const text = curr.name;
            acc[text] = acc[text] || 0;
            acc[text] ++;
            return acc;
        }, {});

    
        return Object.getOwnPropertyNames(reduced).map((prop) => ({ ...count.filter(e=> e.name === prop)[0], count: reduced[prop]}));
        
    };
    
    var grouped = group(products);
   setProductsLocal(grouped)

},[products])


const eraseFromCart = (orderId) =>{
    const newOrder = order.filter((or,id)=> id !== orderId)
    setOrderLocal(newOrder)
    removeServiceFromCart(newOrder)

    
    updateCartdb({
        variables:
            { 
            cart: {
                order:[...newOrder] , 
                products:[...productsLocal]} , 
                id: idCart
            }
            
        })

}





const handlePriceChangeSer = (ev) =>{
    setNewPrice({
        ...newPrice,
        [ev.target.name]:ev.target.value
    })
}

const handlePriceChangeProd = (ev) =>{
    setNewPriceProd({
        ...newPriceProd,
        [ev.target.name]:ev.target.value
    })
}




const sumTotal = (arrOfOrder) =>{

    let sum = []
    arrOfOrder.forEach(element => {
        if(element.length >0){
            sum.push(element.reduce((acc, ele)=>{return acc + Number(ele.price)},0))
        }
    });
    const total = sum.reduce((acc , b)=> acc + b)
    return total 
   
}



const UpdatePrice = (id, type) =>{
    const reg = /^-?\d+\.?\d*$/

    

        if(editPrice.from === "fastCheckOut"){
            
                if(type.type === "service"){

                    if(newPrice[id] !== undefined){
                        if(reg.test(newPrice[id])){
                            orderToFastPay(
                                ...orderFastCheckOut,
                                orderFastCheckOut[id].price = newPrice[id]
                                )
                                setAlertIcon({
                                    on:true,
                                    type:"check"
                                })
                        }
                    }else{
                        setAlertIcon({
                            on:true,
                            type:"cross"
                        })
                    }
                    
                
                }else if(type.type === "product"){

                    if(newPriceProd[id] !== undefined){
                        if(reg.test(newPriceProd[id])){
                            addProductToOrderAdmin(id, newPriceProd[id])
                            setAlertIcon({
                                on:true,
                                type:"check"
                            })
                        }
                    }
                    else{
                        setAlertIcon({
                            on:true,
                            type:"cross"
                        })
                    }


                    
                }
                    
            }else if(editPrice.from === "cartCheckOut"){

                if(type.type === "service"){

                    if(newPrice[id] !== undefined){
                        if(reg.test(newPrice[id])){
                            addOrderToCartAdmin(id, newPrice[id] )
                        setAlertIcon({
                            on:true,
                            type:"check"
                        })
                        }
                    }else{
                        setAlertIcon({
                            on:true,
                            type:"cross"
                        })
                    }


                    
                }else if(type.type === "product"){


                    if(newPriceProd[id] !== undefined){
                        if(reg.test(newPriceProd[id])){
                            addProductToCartAdmin(id, newPriceProd[id])
                        setAlertIcon({
                            on:true,
                            type:"check"
                        })
                        }
                    }else{
                        setAlertIcon({
                            on:true,
                            type:"cross"
                        })
                    }
                    
                }

        }
        
    }


    useEffect(() => {
        if(editThePrice.edit){
            setTotal(sumTotal(arrayOfCartAndOrder))
            setSumPrices(sumTotal(arrayOfCartAndOrder))   
        }
    }, [arrayOfCartAndOrder , UpdatePrice])


    const [selectedService , setSelectedService] = useState()
    const openModal = (details) =>{
        setIsOpen(true)
        setSelectedService(details)
    }


    return (
        <>
        <ModalDetailsCart details={selectedService} isOpen={isOpen} setIsOpen={setIsOpen}></ModalDetailsCart>
            <div className="bg-white shadow overflow-hidden rounded-md">
                <ul className="divide-y divide-gray-200">
                    {orderLocal.map((order, id) => (
                        <li key={id}>

                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                <div className="flex-shrink-0">
                                            <div className="flex-shrink-0 h-16 w-16 bg-gray-100 rounded-full shadow ">
                                                <div className='w-full h-full flex justify-center items-center'>
                                                    <img className=" w-2/4" src={order.photo} alt={order.name} />
                                                </div>
                                            </div>
                                        </div>
                                   
                                    <div className="min-w-0 flex-1 px-4">
                                        <div>
                                            <p className="text-sm font-title truncate relative">{order.service}<span className="absolute right-0 top-0 text-xxs  text-red-600">{id}*</span></p>

                                            {
                                                editThePrice.edit !== true ? 
                                                <p className="text-xs font-title text-gray-900"><span className="font-bold">{order.price.toLocaleString()}</span> <span className="text-xxs">mxn</span> </p>

                                                :
                                                <div className="flex flex-row">
                                                <input
                                                onChange={handlePriceChangeSer}
                                                type="number"
                                                name={id}
                                                id={id}
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:text-sm border-gray-300 rounded-md"
                                                placeholder={order.price}
                                                ></input>
                                                <button className="ml-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                type="button" onClick={()=>UpdatePrice(id ,{type:"service"})} >Actualizar</button>
                                                {
                                                    alertIcon.on ? 

                                                    alertIcon.type === "check" ? <CheckCircleIcon className="text-green-500 ml-4 h-4 w-4"></CheckCircleIcon>
                                                    :
                                                    alertIcon.type === "cross" && <XCircleIcon className="text-red-500 ml-4 h-10 w-10"></XCircleIcon>

                                                    : 
                                                    null
                                                }
                                                </div>
                                            }
                                            
                                            <ul className="text-xs text-gray-500">
                                                {
                                                    order.radio.length !== 0 ?
                                                        Object.keys(order.radio).map((e, id) => {
                                                            return <li key={id}>{e} : {Object.values(order.radio)[id].option} </li>
                                                        })
                                                        :
                                                        null
                                                }
                                                {
                                                    order.text.length !== 0 ?
                                                        Object.keys(order.text).map((e, id) => {
                                                            return <li key={id}>{e} : {Object.values(order.text)[id]}  </li>
                                                        })
                                                        :
                                                        null
                                                }
                                                {
                                                    order.number.length !== 0 ?

                                                        Object.keys(order.number).map((e, id) => {
                                                            return <li key={id}>{e} : {Object.values(order.number)[id].value} </li>
                                                        })
                                                        :
                                                        null
                                                }
                                                {
                                                    order.check.length !== 0 ?

                                                        [...new Set(order.check.map(e => e.question))].map((question, id) => {
                                                            return <li key={id}>{question} : {order.check.map((e, id) => {
                                                                if (e.question === question) {
                                                                    return <span className="mr-1"> {e.option}</span>
                                                                }
                                                            })} </li>
                                                        })
                                                        :
                                                        null
                                                }
                                            </ul>
                                            <p 
                                            onClick={()=>openModal({details: order.description , limitations:order.limitations})}
                                            className="text-sm text-gray-700 cursor-pointer">ver detalle</p>
                                        </div>
                                    </div>
                                </div>
                            
                                {
                                    fromCart === true?

                                <XIcon 
                                onClick={()=>eraseFromCart(id)}
                                className="w-5 text-gray-500 cursor-pointer"></XIcon>
                                    :

                                    null
                                }
                            </div>
                            
                            
                        </li>
                    ))}
                </ul>
            </div>
            <div>
            {
                productsLocal.length !== 0 ?

                    <div className="bg-white shadow overflow-hidden rounded-md">
                        <ul className="divide-y divide-gray-200">
                            {productsLocal.map((product, id) => (
                                <li key={id}>
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="flex-shrink-0 h-12 w-12 rounded-full shadow ">
                                                <img className="mx-auto mt-4 w-3/4" src={product.images[0].url} alt={product.name} />
                                            </div>
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <p className="text-sm font-title">{product.name}</p>
                                                    {
                                                editThePrice.edit !== true ? 
                                                <>

                                                <p className="text-xs font-title text-gray-900"><span className="font-bold">{product.price.toLocaleString()}</span> <span className="text-xxs">mxn</span> </p>
                                                <p className="text-xs font-title text-gray-900">Cantidad : <span className="font-bold"> {product.count}</span>  </p>
                                                <p className="text-xs font-title text-gray-900">Total : <span className="font-bold"> {(product.count * product.price).toLocaleString()} <span className="text-xxs">mxn</span></span>  </p>

                                                
                                                </>

                                                :
                                                <div className="flex flex-row w-full">
                                                <input
                                                onChange={handlePriceChangeProd}
                                                type="number"
                                                name={id}
                                                id={id}
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                placeholder={product.price}
                                                ></input>
                                                <button className="ml-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                type="button" onClick={()=>UpdatePrice(id , {type:"product"})} >Actualizar</button>
                                                
                                                {
                                                    alertIcon.on ? 

                                                    alertIcon.type === "check" ? <CheckCircleIcon className="text-green-500 ml-4 h-4 w-4"></CheckCircleIcon>
                                                    :
                                                    alertIcon.type === "cross" && <XCircleIcon className="text-red-500 ml-4 h-10 w-10"></XCircleIcon>

                                                    : 
                                                    null
                                                }
                                                </div>
                                            }
                                                    
                                                </div>
                                                {
                                                    type === "product" ?
                                                        <div className="flex flex-row">
                                                            <p className="text-xs font-title space-x-1">{product.timeDelivery.quantity} {product.timeDelivery.unit}</p>
                                                            <CheckIcon className="text-green-500 ml-4 h-4 w-4"></CheckIcon>
                                                        </div>
                                                        :

                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </li>
                            ))}
                        </ul>
                    </div>
                    :

                    null}
            {
                editThePrice && showTotal ?<div className="font-bold mt-12">
                            <h3 className="text-lg leading-6 font-title text-gray-900"><span className="font-medium">Total :</span> {sumPrices.toLocaleString()} <span className="text-xs font-medium">mxn</span></h3>
                            <p className="text-xs font-light">Precios incluyen IVA , algunos productos pueden incluir tarifas de envío</p>
                        </div>
                        : null
            }
                                                        
            </div>

        </>
    )
}