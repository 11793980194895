import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import ModalContext from '../../Context/Modal/ModalContext'
import AuthContext from '../../Context/Auth/AuthContext'
import ProductContext from '../../Context/Products/ProductsContext'
import Alert from '../microComponents/Alert'
import Divider from '../microComponents/Divider'
import { useLazyQuery } from '@apollo/client'
import { DIE_PM } from '../../GraphQL/Queries'
import axios from 'axios'
import SERVER_ROUTE from '../../Services/paths'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { CreditCardIcon } from '@heroicons/react/solid'


export default function MakePayment({ total, address, type, deliveryPrice, deleteCart , idCompany }) {

    const { switchCard, closeCardOverlay, newCard, setNewCard } = useContext(ModalContext)
    const { id, loadingMagic, newUser } = useContext(AuthContext)
    const { date, time, products, orderFastCheckOut, order, productsCheckOut, killCarWhenBought , setCartNum } = useContext(ProductContext)

    const [getPaymentMethod, { data, loading }] = useLazyQuery(DIE_PM)
    const dateFake = new Date()
    const [dateOrNot, setDateOrNot] = useState(dateFake)
    const [alert, setAlert] = useState({
        open: false,
        color:"red",
        error: "Completa todos los campos"
    })

    const [sending, setSending] = useState(false)

    const history = useHistory()


    useEffect(() => {
        if (newCard) {
            getPaymentMethod({ variables: { id: id.users[0].id } })
            const timer = setTimeout(() => {
                setNewCard(false)
            }, 2000);
            return () => clearTimeout(timer);
        }
        if (!loadingMagic) {
            if (id !== null) {
                getPaymentMethod({ variables: { id: id.users[0].id } })
            }
        }
    }, [newCard, loadingMagic, id, newUser])
    // Falta que tambien haga la consulta cuando se termina el registro

    useEffect(() => {
        if (type === "service") {
            if (time !== null && date !== null) {
                const dateTime = new Date(date.dayData.getFullYear(), date.dayData.getMonth(), date.dayData.getDate(), parseInt(time.day, 10), parseInt(time.day.substr(-2)))
                setDateOrNot(dateTime)
            }
        }
    }, [date, time])



    const handleMakeFastPayment = async (ev) => {

        ev.preventDefault()

        const alphabet = ["gracias", "súper", "amor", "levitacorpus", "lumus", "accio", "feliz", "útil", "esperanza", "respeto", "solución", "felicidad", "amistad", "generosidad", "helpy", "ayuda", "vivir", "potter", "posible", "caridad", "gratidud", "novedad", "snow", "sirius", "lady", "aria", "khaleesi"]

        const letter = alphabet[Math.floor(Math.random() * alphabet.length)]
        const ordNum = Math.floor(Math.random() * (99999 - 10000) + 10000)


        
        if (date === null && type === "service") {
            setAlert({
                error: "Ingresa una fecha a realizar",
                color:"red",
                open: true,
            })
        } else if (time === null && type === "service") {
            setAlert({
                error: "Ingresa un horario ",
                color:"red",
                open: true,         
            })
        } else if (data.user.card === null && !idCompany.company) {
            setAlert({
                error: "Selecciona un método de pago",
                color:"red",
                open: true,
            })
        }
        else {

            if(!idCompany.company){
                try {
                    setSending(true)
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }
    
                    const dataToServer = {
                        "products": products,
                        "fastOrder": orderFastCheckOut,
                        "cartOrder": order,
                        "productOrder": productsCheckOut,
                        "sec": data.user.card.dpmid,
                        "usid": data.user.id,
                        "sclid": data.user.card.stripeClient,
                        "order": { products, orderFastCheckOut, order, productsCheckOut },
                        "total": total,
                        "when": dateOrNot,
                        "deliverPrice": deliveryPrice,
                        "ordernum": letter + "-" + ordNum,
                        "type": type,
                        "address": address.selected,
                        "zipCode": address.selected.postalCode,
                        "who":"user"
                    }
    
    
                    const res = await axios.post(SERVER_ROUTE + "/orders", dataToServer, config)
    
                    if (res.data.status === "succeeded") {
                        closeCardOverlay()
                        if (deleteCart) {
                            killCarWhenBought()
                            setCartNum(0)
                        }
                        history.push(`/dashboard/client/${letter + "-" + ordNum}`)
                        // console.log("perras drogas", res)
                    } else if (res.data.status === "card_declined") {
                        setAlert({
                            error: "Tarjeta declinada, intenta otro método de pago",
                            color:"red",
                            open: true,
                        })
                        setSending(false)
                        console.log("no tienes dinero papu", res)
                    }
                } catch (e) {
    
                }
            }else{
                try{
                    history.push("/dashboard/company/finishit",{requestData: {from : idCompany, total: total}})
                    // closeCardOverlay()
                }catch(e){
                    console.log(e)
                }
            }

            
        }
    }


    return (
        <>
            {

                data !== undefined && !loading ?

                    data.user.card === null ?
                    
                    !idCompany.company &&
                        <div>
                            <Link to={"/nueva/tarjeta"}>
                                <button
                                    type="button"
                                    className="relative block w-full border-2 border-gray-200 border-dashed rounded-lg py-2 text-center hover:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                >
                                    <span className="flex justify-center">
                                        <CreditCardIcon className="w-4"></CreditCardIcon>
                                    </span>

                                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                                        <p>
                                            Agregar nueva tarjeta
                                        </p>
                                    </span>
                                </button>

                            </Link>
                        </div>

                        :

                        <div className="flex text-sm px-2 mt-4 mb-2 cursor-pointer"
                            onClick={() => switchCard("cards")}>
                            <div className="mr-2 flex-shrink-0 font-bold">
                                <p className="mt-1 font-body text-blue-600 underline ">
                                    Pagar con
                                </p>

                            </div>
                            <div className="overflow-hidden text-body">
                                {
                                    data.user.card.brand === null ?
                                        <p className="mt-1 truncate text-md" >
                                            Selecciona método de pago
                                        </p>

                                        :

                                        newCard ?
                                            <p>Cargando..</p>
                                            :
                                            <p className="mt-1 truncate text-md" >
                                                {data.user.card.brand.charAt(0).toUpperCase() + data.user.card.brand.slice(1)} **** {data.user.card.last4}


                                            </p>

                                }


                            </div>
                        </div>

                    :
                    <p className="mt-1 truncate text-md" >
                        Cargando ...
                    </p>




            }


            <form

                action="submit"
                onSubmit={(ev) => handleMakeFastPayment(ev)}
            >

                <Alert status={alert} />

                <div className="flex justify-center md:justify-start mb-2">
                    <button
                        disabled={sending || loading}
                        type="submit"
                        className="relative w-full  mt-4 px-4 py-2 border shadow-sm text-sm font-body rounded-md  bg-yellow-300  focus:outline-none disabled:bg-yellow-100 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                    >
                        {
                            sending || loading ?
                                <div className="inline-flex">
                                    <div>

                                        <svg className="animate-spin -ml-1 mx-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                    <p>
                                        Juntando todo lo necesario
                                    </p>
                                </div>
                                :
                                <p>
                                    Finalizar pedido
                                </p>
                        }


                    </button>
                </div>
            </form>


            <Divider />
            <div className="mt-2">
                <p className="text-xs font-light">Al realizar tu pedido, aceptas el aviso de privacidad y los términos y condiciones de Helpy El Pulpo SA de CV.</p>

            </div>
        </>
    )
}
