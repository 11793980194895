import React, { useEffect, useState } from 'react'
import Divider from "../microComponents/Divider"

export default function CardLong({ total, where, when, delivery }) {

    const monthArr = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    const [date, setDate] = useState({ day: "", month: "", hour: "", minute: "" })


    useEffect(() => {
        if (when !== undefined) {
            const date = new Date(when)
            const month = date.getMonth()
            const dayNumber = date.getDate()
            const hour = date.getHours()
            const minute = date.getMinutes()
            setDate({ day: dayNumber, month: monthArr[month], hour: hour, minute: minute })

        }

    }, [])

    return (

        <div className="my-2" >
            <div className="bg-white shadow overflow-hidden rounded-md mb-4">
                <div className="px-4 py-2">
                    <ul className="divide-y divide-gray-200">
                        {
                            total !== undefined ?
                                <li>

                                    <div className="flex text-sm px-2 ">
                                        <div className="mr-2 flex-shrink-0 ">
                                            <p className="font-body">
                                                Subtotal :
                                            </p>

                                        </div>
                                        <div className="overflow-hidden ">
                                            <p className="font-body">
                                                {((total - delivery) / 1.16).toFixed(1)}
                                            </p>
                                        </div>

                                    </div>
                                    <div className="flex text-sm px-2 ">
                                        <div className="mr-2 flex-shrink-0 ">
                                            <p className="font-body">
                                                Envio :
                                            </p>

                                        </div>
                                        <div className="overflow-hidden ">
                                            <p className="font-body">
                                                {(delivery / 1.16).toFixed(1)}
                                            </p>
                                        </div>

                                    </div>
                                    <div className="flex text-sm px-2 mb-2">
                                        <div className="mr-2 flex-shrink-0 ">
                                            <p className="font-body">
                                                IVA :
                                            </p>

                                        </div>
                                        <div className="overflow-hidden ">
                                            <p className="font-body">
                                                {(((total + delivery) / 1.16) * .16).toFixed(1)}
                                            </p>
                                        </div>

                                    </div>
                                    <Divider />
                                    <div className="flex text-sm px-2 mt-2">
                                        <div className="mr-2 flex-shrink-0 font-bold">
                                            <p className="font-body">
                                                Total :
                                            </p>

                                        </div>
                                        <div className="overflow-hidden font-bold">
                                            <p className="font-body  text-red-700">
                                                {total}  <span className="text-xxs">mxn</span>
                                            </p>
                                        </div>

                                    </div>
                                </li>
                                :
                                null
                        }
                        {
                            where !== undefined ?
                                <li>
                                    <div className="flex items-center px-0  sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="flex-shrink-0 h-8 w-8 rounded-full shadow ">

                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>

                                            </div>
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <p className="text-xs font-title">{where.street + " , " + where.colonia + " , " + where.postalCode + " , " + where.city}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                :
                                null
                        }

                        {
                            when !== undefined ?
                                <li>
                                    <div className="flex items-center px-0  sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="flex-shrink-0 h-8 w-8 rounded-full shadow ">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>

                                            </div>
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <p className="text-sm font-title">{date.day + " de " + date.month}</p>
                                                    <p className="text-xs font-title text-gray-900"><span className="font-bold">{date.hour + " : " + date.minute}{date.minute === 0 ? "0" : null} - {date.hour + 1 + " : " + date.minute}{date.minute === 0 ? "0" : null}  </span> hrs</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>

                                </li>
                                :
                                null
                        }




                    </ul>
                </div>
            </div>

        </div>

    )
}


