import React from 'react'

import { XIcon } from '@heroicons/react/solid'
import { useState, useEffect } from 'react'
export default function Alert({ status }) {


    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        setOpen(status.open)
    }, [status])



    return (
        open ?
            <div className={`rounded-md bg-${status.color}-50 p-2 my-2`}>
                <div className="flex">

                    <div className="ml-3">
                        <p className={`text-sm font-medium text-${status.color}-800`}>{status.error}</p>
                    </div>
                    <div className="ml-auto pl-3">
                        <div className="-mx-1.5 -my-1.5">
                            <button
                                type="button"
                                onClick={handleClose}
                                className={`inline-flex bg-red-50 rounded-md p-1.5 text-${status.color}-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${status.color}-50 focus:ring-${status.color}-600`} 
                            >
                                <span className="sr-only">Dismiss</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            :
            null

    )
}



