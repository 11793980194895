import { useQuery } from '@apollo/client';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { GET_PRODUCTS_RELATED_SUBCAT } from '../../GraphQL/Queries';
import Loader from '../microComponents/Loader';

export default function Subcat() {
    const { subcat } = useParams()


    const { data, loading } = useQuery(GET_PRODUCTS_RELATED_SUBCAT, { variables: { subCategory: subcat } })


    return (

        <div className="bg-white">
            <div>
                <div className="border-b border-gray-200">
                    <nav aria-label="Breadcrumb" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <ol className="flex items-center space-x-4 py-4">
                            <li>
                                <div className="flex items-center">
                                    <Link to={"/esentials"}>
                                        <p className="mr-4 text-sm font-medium text-gray-900">
                                            Categorias
                                        </p>
                                    </Link>
                                    <svg
                                        viewBox="0 0 6 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        className="h-5 w-auto text-gray-300"
                                    >
                                        <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
                                    </svg>
                                </div>
                            </li>
                            <li className="text-sm">
                                <p aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                    {subcat}
                                </p>
                            </li>
                        </ol>
                    </nav>
                </div>

                <main className="max-w-2xl mx-auto px-4 lg:max-w-7xl lg:px-8">
                    <div className="border-b font-title border-gray-200 pt-2 pb-4">
                        <h1 className="text-xl font-extrabold tracking-tight text-gray-900">Todo lo que necesitas</h1>
                        <p className="text-sm font-body text-gray-500">
                            Desde poner un foco, hasta hacer una casa!
                        </p>
                    </div>

                    <div className="pt-4 pb-24 lg:grid lg:grid-cols-3 font-body lg:gap-x-8 xl:grid-cols-4">
                        <section aria-labelledby="product-heading" className=" mt-6 lg:mt-0 lg:col-span-4 xl:col-span-4">
                            <h2 id="product-heading" className="sr-only">
                                Productos
                            </h2>
                            {
                                !loading ?

                                    <div className="font-body grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4">
                                        {data.products.map((products, id) => (
                                            <div
                                                key={id}
                                                className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden"
                                            >
                                                {
                                                    products.images.length !== 0 ?

                                                        <div className='bg-white'>
                                                            <img className="w-full" src={products.images[0].url} alt={products.name} />
                                                        </div>
                                                        :
                                                        <div className='bg-gray-50 p-10'>

                                                        </div>
                                                }

                                                <div className="flex-1 p-4 space-y-2 flex flex-col">
                                                    <h3 className="sm:text-sm text-xs font-bold text-gray-900">
                                                        <Link to={`/product/${products.name}/${subcat}/${products.id}`}>
                                                            <span aria-hidden="true" className="absolute inset-0" />
                                                            {products.name}
                                                        </Link>
                                                    </h3>
                                                    <div className="flex-1 flex flex-col justify-start">
                                                        <p className="text-base font-bold my-0 text-gray-900">{products.price} <span className='text-xs font-medium 0'>mxn</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :

                                    <Loader></Loader>
                            }


                        </section>
                    </div>
                </main>


            </div>
        </div>
    )
}
