
export default function RequestList({ order }) {

    return (
        <>
            <div className="font-body">

                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {order.map((order, id) => (

                        <>
                            {
                                order.radio.length !== 0 ?
                                    Object.keys(order.radio).map((e, id) => {
                                        return <li className="pl-3 pr-4 py-3 flex items-center text-sm" key={id}> <span className="font-medium"> {e} </span>: {Object.values(order.radio)[id].option} </li>
                                    })
                                    :
                                    null
                            }
                            {
                                order.text.length !== 0 ?
                                    Object.keys(order.text).map((e, id) => {
                                        return <li className="pl-3 pr-4 py-3 flex items-center  text-sm" key={id}><span className="font-medium"> {e} </span> : {Object.values(order.text)[id]}  </li>
                                    })
                                    :
                                    null
                            }
                            {
                                order.number.length !== 0 ?

                                    Object.keys(order.number).map((e, id) => {
                                        return <li className="pl-3 pr-4 py-3 flex items-center  text-sm" key={id}><span className="font-medium"> {e} </span> : {Object.values(order.number)[id].value} </li>
                                    })
                                    :
                                    null
                            }
                            {
                                order.check.length !== 0 ?

                                    [...new Set(order.check.map(e => e.question))].map((question, id) => {
                                        return <li className="pl-3 pr-4 py-3 flex items-center  text-sm" key={id}><span className="font-medium"> {question}   </span> : {order.check.map((e, id) => {
                                            if (e.question === question) {
                                                return <span className="ml-1"> {e.option}</span>
                                            }
                                        })} </li>
                                    })
                                    :
                                    null
                            }



                        </>
                    ))}
                </ul>

            </div>


        </>
    )
}