/* eslint-disable import/no-anonymous-default-export */
import { ADD_CART_ORDER, ADD_PRODUCT_IN_ORDER, REMOVE_PRODUCT_IN_ORDER, ADD_CART_PRODUCT, DATE_SELECTED, TIME_SELECTED, PAY_FAST_ORDER, EMPTY_FAST_ORDER, EMPTY_FAST_PRODUCTS, REMOVE_PRODUCTS_CART_AFTER_PAY_IN_CART, REMOVE_SERVICE_CART_ORDER, ADD_CART_ORDER_ADMIN, ADD_CART_PRODUCT_ADMIN, ADD_PRODUCT_IN_ORDER_ADMIN, SET_CART_NUM, ADD_CART_ORDER_HEAVEN } from "../types";

export default (state, action) => {

    const { payload, type } = action
    switch (type) {
        case ADD_PRODUCT_IN_ORDER:
            return {
                ...state,
                products: [payload, ...state.products]
            }
        case REMOVE_PRODUCT_IN_ORDER:
            return {
                ...state,
                products: payload
            }
        case REMOVE_PRODUCTS_CART_AFTER_PAY_IN_CART:
            return {
                ...state,
                productsCheckOut: [],
                order: []
            }
            case REMOVE_SERVICE_CART_ORDER:
            return {
                ...state,
                order: payload
            }
        case ADD_CART_PRODUCT:
            return {
                ...state,
                productsCheckOut: [...payload.map(e => {
                    return e
                }), ...state.productsCheckOut.map(e => {
                    return e
                })],
                products: []
            }
        case ADD_CART_ORDER:
            
            return {
                ...state,
                order: [payload, ...state.order]
            }
        case ADD_CART_ORDER_HEAVEN:
            return{
                ...state,
                order:[...payload]
            }
        case ADD_CART_ORDER_ADMIN:
            return{
                    ...state
                }
        case  ADD_CART_PRODUCT_ADMIN:   
        return{
                        ...state,
                        productsCheckOut : payload
                    }
        case  ADD_PRODUCT_IN_ORDER_ADMIN:   
                    return{
                                    ...state,
                                    products : payload
                                }
        case PAY_FAST_ORDER:
            return {
                ...state,
                orderFastCheckOut: [payload]
            }
        case EMPTY_FAST_ORDER:
            return {
                ...state,
                orderFastCheckOut: []
            }
        case EMPTY_FAST_PRODUCTS:
            return {
                ...state,
                products: []
            }

        case DATE_SELECTED:
            return {
                ...state,
                date: payload
            }

        case TIME_SELECTED:
            return {
                ...state,
                time: payload
            }
        case SET_CART_NUM:
            return{
                ...state,
                cartNum: payload
            }
        

        default:
            return state
    }

}