import { Fragment , useState } from 'react'
import {Dialog, Transition} from '@headlessui/react'

export default function ModalDetailsCart({isOpen , setIsOpen , details}) {

    

  return (
    <>

    {
        isOpen ?
<Transition.Root show={isOpen} as={Fragment}>
    <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={setIsOpen}>
        <div
            className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                    className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-10/12 md:w-1/2">

                    <div>

                        <div className="mt-3 font-title text-center sm:mt-5 ">
                            <Dialog.Title
                                as="h3"
                                className="text-md  leading-6 font-medium text-left text-gray-900">
                                Detalles del servicio :
                            </Dialog.Title>

                            <div className=" text-left mt-2">
                                <p className="text-left font-body text-sm">
                                    {details.details}
                                </p>
                                <p className="text-left mt-3 font-body text-sm">
                                    {details.limitations}
                                </p>

                            </div>

                            <div>
                            {/* <p className="text-left my-4 font-body">{currentStatus[0].mesage} </p> */}
                            
                            </div>

                        </div>

                    </div>

                </div>
            </Transition.Child>
        </div>
    </Dialog>
</Transition.Root>
        :

        null
        
    }
    
    
    </>
  )
}

