import React, {useState , useEffect} from 'react'
import Loader from '../microComponents/Loader'
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ORDER_REPORT } from '../../GraphQL/Queries';

export default function OrderReport() {

    
    const { idOrder } = useParams()
    const { data, loading } = useQuery(ORDER_REPORT, {variables: { orderNumber: idOrder }})

    const [report , setReport]=useState({
        report:[{
            photos:[null],
            comment:null,
            service:null
        }],
        // reportPro:[]
    })

    useEffect(()=>{
        if(data !== undefined){
            setReport({
                report :data.orders[0].report.orderDetail,
                // reportPro : data.orders[0].reportProducts
            })
        }
    },[data])

  return (
    <div>

{
                loading ?
                    <div className="grid justify-items-center">
                        <div className='mx-auto py-64 w-24'>
                            <Loader></Loader>
                        </div>
                    </div>
                    :
                    <div className="bg-gray-100 p-5 ">
                        <div className="bg-white  sm:w-3/4   mx-auto overflow-hidden shadow rounded-lg">
                            
                                <div className="flex flex-row mt-4 font-body justify-between px-3">
                                    <div className='flex flex-row'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                        </svg>
                                        <p className="text-sm">Ver servicios</p>
                                    </div>
                                    <div><p className='text-sm text-gray-500 relative'># de Orden: {idOrder}</p></div>
                                </div>
                            


                            <div className="p-8">

                                <div className="mt-5">
                                <ul className="divide-y space-y-3 divide-gray-200">
                    {report.report.map((order, id) => (
                        <li key={order.id}>
                                <div className="flex items-center px-4  sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 py-4">
                                        <div>
                                            <p className="text-sm font-title  truncate">{order.service}</p>
                                                <p className="text-xs font-title text-gray-900">{order.comment} </p>
                                            

                                          <div className="font-body text-sm text-gray-500">
                                    
                                            
                                         
                                            
                                            <>
                                            {
                                                order.photos.length > 0 ?

                                                <div className="flex flex-row space-x-4 mt-3 overflow-scroll">

                                                {
                                                    order.photos.map((file ) => {
                                                    return <img className="h-20 w-20 rounded-md" src={file} alt="" />
                                                    })
                                                    
                                                }
                                                </div>

                                                :
                                                null
                                            }
                                           
                                         

                                            </>

                                            
                                          
                                        </div>


                                        </div>
                                    </div>
                                </div>
                               
                                </div>
                                

                            
                           
                            
                            
                        </li>
                    ))}
                </ul>
                                </div>


                            </div>
                        </div>
                    </div>



            }
      
    </div>
  )
}
