import { useContext, useEffect, useState } from "react"
import ModalContext from "../../Context/Modal/ModalContext"
import ProductContext from "../../Context/Products/ProductsContext"
import LogCard from "./LogCard"


export default function CtaCart({ subtotal, items, delivery , idComp}) {
    
    
    const { openCardOverlay } = useContext(ModalContext)
    const { order } = useContext(ProductContext)
    const [orderOrProduct, setorderOrProduct] = useState()
    const [deliveryPrice, setDeliveryPrice] = useState({
        delivery: false,
        price: 0
    })

    

    useEffect(() => {
        if (order.length === 0) {
            setorderOrProduct("product")
            setDeliveryPrice({
                delivery: true,
                price: delivery
            })
        } else if (order.length > 0) {
            setorderOrProduct("service")
        }
    }, [])



    return (
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-4">
                    <div className="flex items-center">
                        {
                            deliveryPrice.delivery ?
                                <div className="font-bold">
                                    <p className="text-sm leading-6 font-title text-gray-900">
                                        <span className="font-medium">Subtotal : {subtotal.toLocaleString()} </span><span className="text-xs font-medium">mxn</span>
                                    </p>
                                    <p className="text-sm leading-6 font-title text-gray-900">
                                        <span className="font-medium">Envio :{deliveryPrice.price.toLocaleString()}</span>  <span className="text-xs font-medium">mxn</span>
                                    </p>
                                    <h3 className="text-lg leading-6 font-title text-gray-900">
                                        <span className="font-medium">Total :</span> {(subtotal + deliveryPrice.price).toLocaleString()} <span className="text-xs font-medium">mxn</span>
                                    </h3>
                                    <p className="text-xs font-light">Precios incluyen IVA , algunos productos pueden incluir tarifas de envío</p>
                                </div>
                                :
                        <div className="font-bold">
                            <h3 className="text-lg leading-6 font-title text-gray-900"><span className="font-medium">Subtotal :</span> {subtotal.toLocaleString()} <span className="text-xs font-medium">mxn</span></h3>
                            <p className="text-xs font-light">Precios incluyen IVA , algunos productos pueden incluir tarifas de envío</p>
                        </div>

                        }

                    </div>
                </div>
                <div className="ml-4 mt-4 flex-shrink-0 flex">
                    <button
                        type="button"
                        onClick={openCardOverlay}
                        className="relative inline-flex items-center px-4 py-2 border shadow-sm text-sm font-body rounded-md  bg-yellow-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                    >

                        <p>
                            Proceder al pago
                            <span className="text-xs font-medium ml-1">( {items} articulos )</span>

                        </p>
                        {
                            !idComp.company ? 
                        <LogCard total={subtotal} type={orderOrProduct} delivery={{ "deliveryPrice": deliveryPrice.price }} deleteCart={true} idCompany={idComp}></LogCard>
                            : 

            <LogCard total={subtotal} type={orderOrProduct} delivery={{ "deliveryPrice": deliveryPrice.price }} idCompany={idComp}></LogCard>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}
