import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react"

import SwiperCore, {
    Keyboard,
    Mousewheel,
    Scrollbar,

} from 'swiper/core';
import { useQuery } from '@apollo/client';
import { GET_LIST_THREE } from '../../GraphQL/Queries';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

SwiperCore.use([Scrollbar, Mousewheel, Keyboard]);



export default function Slider4Products({ items }) {

    const { data, loading } = useQuery(GET_LIST_THREE)

    const [product, setProducts] = useState([
        {
            product: {
                sub_category: {
                    subcat: " "
                },
                images: [{ url: "" }],
                name: " ",
                id: " ",
                subCategory: " ",
                price: " ",
            timeDelivery: {
                quantity: " ",
                unit: " "
            }
            }
        }
    ])

    useEffect(() => {
        if (data !== undefined) {
            setProducts(data.listThrees)
        }
    }, [loading, data])

    return (
        <div className="bg-white">
            <div className="max-w-7xl font-body mx-auto py-24 px-4 overflow-hidden sm:pt-2 sm:pb-24 sm:px-6 lg:px-8">
                <div className="">
                    <div className='py-4'>
                        <h1 className='font-title text-xl font-bold '>Nuestra selección de productos</h1>
                        <p className='font-body text-gray-600'>Para la casa y la oficina</p>
                    </div>
                    {
                        !loading ?

                            <Swiper
                        scrollbar={true}
                        loop={true}
                        spaceBetween={50}
                                slidesPerView={1.5 + items}
                                mousewheel={true}
                                keyboard={true}
                    >
                                {product.map((product, id) => (
                            <SwiperSlide key={id}>
                                        <Link to={`product/${product.product.name}/${product.product.sub_category.subcat}/${product.product.id}`}>
                                    <div className="group text-sm cursor-pointer	">
                                    <div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden bg-gray-100 group-hover:opacity-75">
                                        <img
                                                        src={product.product.images[0].url}
                                                        alt={product.product.name}
                                                        className="w-full h-full object-center object-cover"
                                            />
                                    </div>
                                                <h3 className="mt-4 font-medium text-gray-900 truncate">{product.product.name}</h3>
                                        <div className='flex flex-row justify-start'>
                                                    <p className="font-medium text-lg text-gray-900 ">{product.product.price}  </p>
                                            <p className='text-xs ml-1 leading-loose'>mxn </p>
                                            <ChevronRightIcon className='w-6 h-6 text-blue-400 group-hover:text-blue-500' />
                                        </div>
                                                <p className="text-gray-500 text-xs italic">{product.product.timeDelivery.quantity} {product.product.timeDelivery.unit}</p>
                                    </div>
                                        </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                            :

                            null
                    }


                </div>
            </div>

        </div>
    )
}




