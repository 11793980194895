import { useMutation, useQuery } from '@apollo/client'
import { CreditCardIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../Context/Auth/AuthContext'
import { NAME_USER, ROL_TO_OFFICE } from '../../../GraphQL/Mutations'
import Alert from '../../microComponents/Alert'
import { useHistory } from 'react-router-dom'
import Loader from '../../microComponents/Loader'
import { GET_USER } from '../../../GraphQL/Queries'


export default function RegisterForm() {


    const [updateRoleOffice] = useMutation(ROL_TO_OFFICE,
        {
            onCompleted: () => {
                history.go(-2)
            }
        })
    const [updateNameUser] = useMutation(NAME_USER,
        {
            onCompleted: () => {
                history.go(-2)
            }
        })

    const { HoldPreUser, preUser, address, user, logged, newUserTrue, getIdUser } = useContext(AuthContext)


    const { refetch } = useQuery(GET_USER);

    const history = useHistory()
    const [company, SetCompany] = useState(preUser.company)
    const [credit, SetCredit] = useState(preUser.credit)
    const [dataForm, SetDataForm] = useState(preUser)
    const [idRetrieved, SetIdRetrieved] = useState(null)
    const [sendingData, setSendingData] = useState(false)
    const [alert, setAlert] = useState({
        open: false,
        color:"red",
        error: "Ingresa una dirección"
    })

    useEffect(() => {
        const createUser = async () => {
            if (user !== null && logged) {
                try {
                    const data = await refetch({ username: user })
                    getIdUser(data.data)
                    // console.log("data: ", data.data)
                    // console.log("id: ", data.data.users[0].id)
                    SetIdRetrieved(data.data.users[0].id)
                } catch (err) {
                    console.log(err)
                    // si no agarro el usuario habra error poner que refresque la apgina o algo
                }
            }
        }
        createUser()

    }, [user, logged])

    const handleForm = (data) => {
        SetDataForm({
            ...dataForm,
            [data.target.name]: data.target.value
        })
    }

    useEffect(() => {
        if (credit === true) {
            SetDataForm({
                ...dataForm,
                credit: true
            })
        } else {
            SetDataForm({
                ...dataForm,
                credit: false
            })
            SetCredit(false)
        }
    }, [credit])


    useEffect(() => {
        if (company === true) {
            SetDataForm({
                ...dataForm,
                company: true
            })
        } else {
            SetDataForm({
                ...dataForm,
                company: false,
                credit: false,
                companyName: "",
                rfc: ""
            })
            SetCredit(false)
        }

    }, [company])


    useEffect(() => {
        HoldPreUser(dataForm)
    }, [dataForm])


    const submitButton = async (event) => {
        setSendingData(true)
        event.preventDefault()
        if (address.name === undefined) {
            setAlert({
                open: true,
                color:"red",
                error: "Ingresa una dirección"
            })
        } else {
            if (company) {

                try {
                    await updateRoleOffice({
                        variables:
                        {
                            id: idRetrieved,
                            companyName: dataForm.companyName,
                            name: dataForm.name,
                            rfc: dataForm.rfc,
                            addressRegistered: true,
                            credit: credit,
                            street: address.street,
                            colonia: address.colonia,
                            postalCode: address.postalCode,
                            city: address.city,
                            fullAdress: address.street + " " + address.colonia + " " + address.postalCode + " " + address.city + " " + address.state,
                            nameAddress: address.name,
                            state: address.state,
                            extras: address.extras,
                            default: address.default,
                            phoneNumber: address.phoneNumber
                        }
                    })
                    newUserTrue(false)
                } catch (err) {
                    setSendingData(false)
                    console.log("error de register", err)
                }

            } else {

                try {
                    updateNameUser({
                        variables: {
                            id: idRetrieved,
                            name: dataForm.name,
                            street: address.street,
                            colonia: address.colonia,
                            addressRegistered: true,
                            postalCode: address.postalCode,
                            city: address.city,
                            fullAdress: address.street + " " + address.colonia + " " + address.postalCode + " " + address.city + " " + address.state,
                            nameAddress: address.name,
                            state: address.state,
                            extras: address.extras,
                            default: address.default,
                            phoneNumber: address.phoneNumber
                        }
                    })
                    newUserTrue(false)
                } catch (err) {
                    setSendingData(false)
                    console.log("error de register user normal", err)
                }

            }
        }

    }




    return (
        <div className="pb-12 flex flex-col justify-center font-body sm:px-6 lg:px-8">
            {
                user ?
                    <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white my-2 px-4 sm:rounded-lg sm:px-10">
                            <form className="space-y-2" onSubmit={submitButton}>
                                <div>
                                    <label htmlFor="name" className="block text-sm font-semibold text-gray-700">
                                        Nombre
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onChange={handleForm}
                                            id="name"
                                            value={dataForm.name}
                                            name="name"
                                            type="text"
                                            autoComplete="name"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            onChange={(ev) => SetCompany(ev.target.checked)}
                                            id="company"
                                            name="company"
                                            type="checkbox"
                                            checked={preUser.company}
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                        />
                                        <label htmlFor="company" className="ml-2 block text-md  text-gray-900">
                                            Soy empresa
                                        </label>
                                    </div>
                                </div>

                                {
                                    company ?
                                        <>
                                            <div>
                                                <label htmlFor="companyName" className="block text-sm font-semibold text-gray-700">
                                                    Nombre de la empresa
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={handleForm}
                                                        id="companyName"
                                                        name="companyName"
                                                        type="text"
                                                        value={dataForm.companyName}
                                                        autoComplete="current-companyName"
                                                        required
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="rfc" className="block text-sm font-semibold text-gray-700">
                                                    RFC
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={handleForm}
                                                        id="rfc"
                                                        name="rfc"
                                                        type="text"
                                                        maxLength={13}
                                                        value={dataForm.rfc.toLocaleUpperCase()}
                                                        autoComplete="current-RFC"
                                                        required
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center">
                                                    <input
                                                        onChange={(ev) => SetCredit(ev.target.checked)}
                                                        id="credit"
                                                        name="credit"
                                                        type="checkbox"
                                                        checked={credit}
                                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                    />
                                                    <label htmlFor="credit" className="ml-2 block text-sm text-gray-900">
                                                        Aplicar para crédito empresarial
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                }







                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">ubicaciones</span>
                                    </div>
                                </div>

                                <Alert status={alert}></Alert>

                                {
                                    address.name === undefined ?

                                        <div>

                                            <Link to={"/nueva/direccion"}>
                                                <button
                                                    type="button"
                                                    className="relative block w-full border-2 border-gray-200 border-dashed rounded-lg py-2 text-center hover:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                                >
                                                    <span className="flex justify-center">
                                                        <LocationMarkerIcon className="w-4"></LocationMarkerIcon>
                                                    </span>

                                                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                                                        <p>
                                                            Agregar nueva dirección
                                                        </p>
                                                    </span>
                                                </button>

                                            </Link>
                                        </div>
                                        :


                                        <div>
                                            {/* Poner el link para editar la dirección */}

                                            <button
                                                type="button"
                                                className="relative block w-full border-2 border-green-200 border-dashed rounded-lg py-2 text-center hover:border-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                            >
                                                <span className="flex justify-center">
                                                    <LocationMarkerIcon className="w-4 text-green-500"></LocationMarkerIcon>
                                                </span>

                                                <span className="mt-2 block text-sm font-semibold text-green-500">
                                                    <p>
                                                        {address.street + ", " + address.colonia}
                                                    </p>
                                                </span>
                                            </button>


                                        </div>


                                }


                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">opcional</span>
                                    </div>
                                </div>

                                <div >

                                    <Link to={"/nueva/tarjeta"}>
                                        <button
                                            className="relative block w-full border-2 border-gray-200 border-dashed rounded-lg py-2 text-center hover:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                        >
                                            <span className="flex justify-center">
                                                <CreditCardIcon className="w-4"></CreditCardIcon>
                                            </span>

                                            <span className="mt-2 block text-sm font-semibold text-gray-900">
                                                <p>
                                                    Agregar nueva tarjeta
                                                </p>
                                            </span>
                                        </button>

                                    </Link>
                                </div>

                                {
                                    !sendingData ?
                                        <div className="mt-2 ">

                                            <button
                                                type='submit'
                                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-body text-black bg-yellow-400 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                                            >
                                                Finalizar registro
                                            </button>

                                        </div>
                                        :

                                        <div className="mt-2 ">

                                            <button
                                                disabled
                                                className="w-full flex justify-center cursor-not-allowed py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-body text-black bg-yellow-200 hover:bg-yellow-400 "
                                            >
                                                <div className="inline-flex">
                                                    <div>

                                                        <svg className="animate-spin -ml-1 mx-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                    </div>
                                                    <p>
                                                        Juntando todo lo necesario
                                                    </p>
                                                </div>
                                    </button>


                                </div>
                                }

                            </form>

                        </div>
                    </div>

                    :

                    <div className="flex justify-center">
                        <div className="py-32">
                            <Loader></Loader>
                        </div>
                    </div>
            }

        </div>
    )
}
