import React from 'react'
import { Link } from 'react-router-dom'

export default function RelatedServices({ data }) {

    return (
        <>
            <div className="overflow-x-auto pb-4 flex flex-row">

                {
                    data !== undefined?
                    data.services.map((e, id) => {
                        return <div key={id}>
                            <Link to={`/servicio/${e.name}`}>

                                <div key={id}
                                    className={`rounded-lg w-44 p-4 mr-4 shadow-md cursor-pointer hover:shadow-lg ${e.categories[0].color}`}>
                                    <div>

                                        {
                                            e.image !== null ?
                                                <div className="flex items-center justify-center h-28">
                                                <img className="w-3/4" src={e.image.url} alt={e.name} />
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="break-words">
                                        <h1 className="font-bold leading-5 font-title text-md mt-3 mb-1" key={id}>{e.name}</h1>
                                        <p className="font-body text-sm relative bottom-1">{e.categories[0].category}</p>
                                    </div>
                                    
                                </div>
                            </Link>
                        </div>
                    })

                        :
                        null
                    
                    

                }
            </div>
        </>
    )
}
