import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../Context/Auth/AuthContext'
// import { Link } from 'react-router-dom'
import ModalContext from '../../Context/Modal/ModalContext'
import ProductContext from '../../Context/Products/ProductsContext'
import { SET_CART, UPDATE_CART } from '../../GraphQL/Mutations'
import { GET_CARTS } from '../../GraphQL/Queries'
import LogCard from '../buyProcess/LogCard'
import Alert from '../microComponents/Alert'
import RelatedProducts from './RelatedProducts'

function DirectService({ data, loading }) {


    const { products, addOrderToCart, addProductToCart, orderToFastPay , setCartNum , cartNum , killCarWhenBought , emptyTemporalOrder,emptyTemporalProducts} = useContext(ProductContext)
    const { openCardOverlay } = useContext(ModalContext)
    const {id} = useContext(AuthContext)
    const [buttonClicked, setButtonClicked] = useState(" ")
    const [activeButtons, setActiveButtons] = useState(true)
    const [dataForm, setdataForm] = useState([])
    const [dataText, setDataText] = useState([])
    const [dataRadio, setDataRadio] = useState([])
    const [dataCheck, setdataCheck] = useState([])
    const [helpymatic, setHelpymatic] = useState(data.services[0].helpymatic.filter((a) => a.boolDependent === null))
    const [total, setTotal] = useState(0)
    const [reload, setReload] = useState(0)
    const [newCart , setNewCart] = useState(true)
    const [cartDb , setCartDb] = useState()
    const [order, setOrder] = useState([{
        service: null,
        text: null,
        radio: null,
        check: null,
        number: null,
        price: 0,
        photo: null,
        description : null,
        limitations : null 
    }])
    const [alertOn , setAlertOn] = useState(false)


    const succesCart = () =>{
        if(!companyPro.company){
            setTimeout(() => history.push(`/compra/carrito/${cartNum}` ), 1000)
        }else{
            setCompanyPro({
                ...companyPro, 
                from : "cartCheckOut"
            })
            setTimeout(() => history.push(`/compra/carrito/${cartNum}` , {requestData : {...companyPro , from : "cartCheckOut" , id:dataCart.carts[0].id}}), 1000)
            
        }
    }
    const [setCart] = useMutation(SET_CART , {
        onCompleted: ()=> succesCart()
    })
    const [updateCart] = useMutation(UPDATE_CART, {
        onCompleted: ()=> succesCart()
    })
    const randum = Math.floor(Math.random() * 59595959)
    const [findOneCart , {data : dataCart , loading : loadingCart}] = useLazyQuery(GET_CARTS)
    
    const cartValidation = async () =>{
        setNewCart(false)
        // console.log(cartNum)
        if(cartNum !== 0){
            setCartNum(cartNum)
            setNewCart(false)

           const result = await  findOneCart({variables:{idCart:cartNum}})
           if(result.data.carts.length <= 0){
            setNewCart(true)
           }else{
            setNewCart(false)
           }

        }else{
            setCartNum(randum)
            setNewCart(true)
        }
    
    }
    


    useEffect(()=>{
        cartValidation()
    },[])

    useEffect(()=>{
        if(dataCart !== undefined && !loadingCart){
            setCartDb(dataCart)
        }
    },[dataCart , loadingCart])
    

    const [companyPro, setCompanyPro] = useState({
        id: null,
        company : false,
        from : buttonClicked === "cart" ? "cartCheckOut" : buttonClicked === "pay"?  "fastCheckOut" : " "
    })



    const history = useHistory()
    useEffect(()=>{
    if(id !== null){
        if(id.users[0].role.name === "CompanyPro"){
            setCompanyPro({
                id: id,
                company : true,
                from : buttonClicked === "cart" ? "cartCheckOut" : buttonClicked === "pay"?  "fastCheckOut" : " "
            })
        }
    }
    },[id , buttonClicked])

    const handleChanges = (ev) => {
        setDataRadio(
            {
                ...dataRadio,
                [ev.target.name]: Object.values(ev.target).map(e =>
                    e.value
                )[1]
            }
        )
    }

    const handleInputs = (ev, min, max, over) => {

        setdataForm(
            {
                ...dataForm,
                [ev.target.name]: {
                    "value": ev.target.value,
                    "regularPrice": max,
                    "offPrice": min,
                    "overThis": over
                }

            }
        )


    }

    const handleText = (ev) => {
        setDataText(
            {
                ...dataText,
                [ev.target.name]: ev.target.value
            }
        )
    }

    const handleCheck = (ev, id) => {

        if (ev.target.checked) {
            setdataCheck([
                ...dataCheck,
                {
                    id,
                    "question": ev.target.name,
                    "option": Object.values(ev.target).map(e =>
                        e.value
                    )[1].option,
                    "price": Object.values(ev.target).map(e =>
                        e.value
                    )[1].price
                }
            ])

        } else {
            setdataCheck(
                dataCheck.filter((option) => option.id !== id)
            )
        }



    }

    useEffect(() => {

        const subSequent = data.services[0].helpymatic.filter((a) => a.boolDependent !== null)
        const arrayRadios = Object.values(dataRadio)

        
        if (arrayRadios.length !== 0 && subSequent.length !== 0) {

            const indexArrayComplete = subSequent[0].boolDependent
            const questionToCompare = data.services[0].helpymatic[indexArrayComplete].question
            const indexInArray = arrayRadios.map(e => e.question).indexOf(questionToCompare)


            if (indexInArray !== -1) {
                const optionSelected = arrayRadios[indexInArray].option


                subSequent.map((question) => {

                    let questionIf = question.correctAnswerToShow


                    if (questionIf === optionSelected && !helpymatic.includes(question)) {
                        setHelpymatic([...helpymatic, question])
                        setReload(reload + 1)
                    } else if (questionIf !== optionSelected && helpymatic.includes(question)) {
                        setHelpymatic(helpymatic.filter((a) => a.question !== question.question))
                        const theQ = question.question
                        delete dataRadio[theQ]
                        setReload(reload - 1)
                    }


                })





            }


           


        }

    }, [data, dataRadio, reload])


    useEffect(() => {


        const checkTotal = dataCheck.reduce((a, b) => { return a + b.price }, 0)
        const radioSub = Object.values(dataRadio).map((e) => {
            if (e.IndexPriceDependent !== null && Object.values(dataForm).length !== 0) {
                return e.price * parseInt(Object.values(dataForm)[0].value)
            } else {
                return e.price
            }
        }, 0)

        

        const radioTotal = radioSub.reduce((a, b) => { return a + b }, 0)
        const arrInputs = Object.values(dataForm).map(e => e.value > e.overThis ? e.value * e.regularPrice : e.value * e.offPrice)
        const sumImputs = arrInputs.reduce((a, b) => { return a + b }, 0)
        setTotal(checkTotal + data.services[0].basePrice + radioTotal + sumImputs)
        setOrder({
            service: data.services[0].name,
            category: data.services[0].categories.map(e => e.category),
            text: dataText,
            radio: dataRadio,
            check: dataCheck,
            number: dataForm,
            price: total,
            photo: data.services[0].image.url,
            limitations: data.services[0].disclaimer,
            description : data.services[0].description
        })


    }, [data, dataCheck, dataRadio, dataForm, products, dataText, total])


    const whichButton =  (which) => {
        setButtonClicked(which)
    }

    

    const handlePayButton = async (e) => {
        e.preventDefault()

        if (buttonClicked === "pay") {
            openCardOverlay()
            orderToFastPay(order)
        } else if (buttonClicked === "cart") {
            
            addOrderToCart(order)
            setOrder([{
            service: null,
            text: null,
            radio: null,
            check: null,
            number: null,
            price: 0,
            photo: null,
            description : null,
            limitations : null 
        }])
            addProductToCart(products)
            setActiveButtons(false)

            try {
                if(newCart){
                     setCart({
                         variables:{cart: {order:[order] , products} , statusC:"sent" , idCart:cartNum  }
                     })
     
                 }else{
                      updateCart({
                         variables:
                         {
                             cart: {order:[...dataCart.carts[0].cartfull.order , order] , 
                             products:[...dataCart.carts[0].cartfull.products , ...products]} , 
                             id: dataCart.carts[0].id
                          }
     
                     })
                 }
            }catch(e){
                setActiveButtons(true)
                setAlertOn(true)
                emptyTemporalOrder()
                emptyTemporalProducts()
                setCartNum(0)
                console.log(e)
            }
            
            
        } 
    }



    return (
        <div>
     

        <form onSubmit={handlePayButton}>
    
            <div className="bg-white  max-w-xl   mx-auto overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6 ">
                    {
                        !loading ?

                            <div className="max-w-xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:px-8 ">
                        <Alert status={{"color":"red" , "open":alertOn , status:"Hubo un error , intentalo de nuevo"}}></Alert>
                                <div className="max-w-xl">
                                    <h3 className="text-2xl font-title text-gray-900 tracking-tight sm:text-3xl">
                                        {data.services[0].name}
                                    </h3>
                                    <p className="text-xs">{data.services[0].description}</p>
                                </div>
                                <div>
                                    <ul>
                                        {
                                            helpymatic.map((helpymaticEl, identif) => {
                                                return (
                                                    <div key={identif} className="mt-5">

                                                        <li className="font-medium my-2">{helpymaticEl.question}</li>
                                                        {
                                                            helpymaticEl.type === "number" ?
                                                                <div>
                                                                    <input onChange={ev => handleInputs(ev, helpymaticEl.priceInput, helpymaticEl.priceDiscountInput, helpymaticEl.overThisDiscount)}

                                                                        type={helpymaticEl.type}
                                                                        name={helpymaticEl.question}
                                                                        required
                                                                        min={1}
                                                                        onKeyPress={(e) => {
                                                                                        if (e.key === "e" || e.key === "-") {
                                                                                        e.preventDefault();
                                                                                        }
                                                                        }}
                                                                        placeholder={helpymaticEl.type}
                                                                        className="h-10 w-full rounded-md mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                                                                </div>
                                                                :
                                                                helpymaticEl.type === "text" ?
                                                                    <div>
                                                                        <input onChange={handleText}

                                                                            type={helpymaticEl.type}
                                                                            required
                                                                            name={helpymaticEl.question}
                                                                            placeholder={helpymaticEl.type}
                                                                            className="h-10 w-full rounded-md mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" />

                                                                    </div>
                                                                    :

                                                                    helpymaticEl.type === "checkbox" ?

                                                                        <div className={`${helpymaticEl.answers[0].image.length ? "grid grid-cols-2 " : " "}`}>

                                                                            {

                                                                                helpymaticEl.answers.map((element, id) => {

                                                                            return <fieldset key={id} >
                                                                                <div className="bg-red rounded-md -space-y-px">
                                                                                    <label className={`${element.image.length !== 0 ? " " : "border border-gray-200 rounded-md "}   relative  p-4 flex flex-col cursor-pointer`}>
                                                                                        {
                                                                                            element.image.length !== 0 ?
                                                                                                <div className='block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100'>
                                                                                                    <img className='object-cover rounded-lg' src={element.image[0].url} alt="" />
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        <div className='flex flex-row'>

                                                                                        <input onChange={(ev) => handleCheck(ev, id)}
                                                                                                type={helpymaticEl.type}
                                                                                                name={helpymaticEl.question}
                                                                                            value={element}
                                                                                            required={!dataCheck.length ? true : false}
                                                                                            className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description" />
                                                                                        <div className="ml-3 flex flex-col">
                                                                                            <span id="privacy-setting-0-label" className="text-gray-900 block text-sm font-medium">
                                                                                                {element.option}
                                                                                            </span>
                                                                                        </div>
                                                                                        </div>
                                                                                        {

                                                                                            element.description !== null ?
                                                                                                <p className='font-body text-gray-600  text-xs'>{element.description}</p>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </fieldset>


                                                                        })

                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className={`${helpymaticEl.answers[0].image.length ? "grid grid-cols-2 " : " "}`}>


                                                                            {

                                                                                helpymaticEl.answers.map((element, id) => {
                                                                                    return <fieldset key={id} >


                                                                                        <div className="rounded-md -space-y-px">
                                                                                            <label className={`${element.image.length !== 0 ? " " : "border border-gray-200 rounded-md "}   relative  p-4 flex flex-col cursor-pointer`}>

                                                                                                {
                                                                                                    element.image.length !== 0 ?
                                                                                                        <div className='block   rounded-lg bg-gray-100'>
                                                                                                            <img className='object-cover rounded-lg' src={element.image[0].url} alt="" />
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                }

                                                                                                <div className='flex flex-row'>

                                                                                                    <input onChange={handleChanges}
                                                                                                        key={element.id}
                                                                                                        type={helpymaticEl.type}
                                                                                                        name={helpymaticEl.question}
                                                                                                        value={{ "option": element.option, "price": element.price, "image": element.image, "boolDependent": helpymaticEl.boolDependent, "IndexNewQuestion": helpymaticEl.correctAnswerToShow, "IndexPriceDependent": helpymaticEl.priceDependent, "question": helpymaticEl.question }}
                                                                                                        required
                                                                                                        className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description" />


                                                                                                    <div className="ml-3">
                                                                                                        <span id="privacy-setting-0-label" className="text-gray-900 block text-sm font-medium">
                                                                                                            {element.option}
                                                                                                        </span>
                                                                                                    </div>


                                                                                                </div>
                                                                                                {

                                                                                                    element.description !== null ?
                                                                                                        <p className='font-body text-gray-600  text-xs'>{element.description}</p>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </label>
                                                                                        </div>
                                                                                    </fieldset>
                                                                                })}


                                                                        </div>


                                                        }
                                                    </div>
                                                )
                                            })}

                                    </ul>



                                </div>

                
                                <RelatedProducts name={data.services[0].name} />
                            </div>
                            :

                            <h1>
                                Cargando...
                            </h1>
                    }
                </div>

            </div>

            <div className="max-w-xl mx-auto">
            <div className="mt-4" >
                <p className="text-xxs text-gray-500">{data.services[0].disclaimer}</p>
                </div>
                <LogCard total={total + products.reduce((a, b) => { return a + b.price }, 0)} type={"service"} idCompany={companyPro}></LogCard>
                
               
                {
                    activeButtons ?
                        <>
                            <button
                    name="pay"
                                type='submit'
                                onClick={() => whichButton("pay")}
                    className=" items-center w-full text-center mt-2 py-2 border border-transparent text-base  rounded-md shadow-sm text-white bg-yellow-400 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
                            >
                   {companyPro.company ? <span>Reservar servicio</span>: <span>Pagar</span> }  {total + products.reduce((a, b) => { return a + b.price }, 0)} <span className="text-xs">mxn</span>
                            </button>
                    <button
                        name="cart"
                        type="submit"
                                onClick={() => whichButton("cart")}
                        className=" items-center w-full bg-white text-center mt-2 py-2 border border-transparent text-base rounded-md shadow-sm text-black   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 border-2 border-yellow-400"
                            >
                        Agregar al carrito
                    </button>
                        </>
                        :
                        <>
                            <button
                                name="pay"
                                disabled={true}
                                className=" items-center w-full cursor-not-allowed	 text-center mt-2 py-2 border text-black border-transparent text-base  rounded-md shadow-sm text-white bg-yellow-200 "
                            >
                                Cargando...
                            </button>
                            <button
                                name="cart"
                                disabled={true}
                                className=" items-center cursor-not-allowed	 w-full bg-white text-center mt-2 py-2 border border-transparent text-base rounded-md shadow-sm text-black   focus:outline-none border-yellow-100"
                            >
                                Cargando...
                            </button>
                        </>

                }

            </div>
            
        </form>
        
                                                            </div>
    )
}

export default DirectService



