import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { GET_RELATEDPRODUCTS } from '../../GraphQL/Queries'
import Loader from '../microComponents/Loader'
import { PlusIcon as PlusIconOutline } from '@heroicons/react/outline'
import { XIcon as XIconOutline } from '@heroicons/react/outline'
import ModalContext from '../../Context/Modal/ModalContext'
import Modal from '../microComponents/Modal'
import ProductContext from '../../Context/Products/ProductsContext'



export default function RelatedProducts({ name }) {

    const { loading, data } = useQuery(GET_RELATEDPRODUCTS, { variables: { name } })

    const { openModal } = useContext(ModalContext)
    const { addProdCurrentOrder, products, removeProdCurrentOrder } = useContext(ProductContext)

    const idArrayProducts = products.map(e => e.id)


    return (
        <>
        {
            products.length !== 0 ? 

            <h3 className="text-md mt-6 font-medium text-gray-900 tracking-tight">
                Te puede interesar
            </h3>
            :

            null
        }
            <Modal></Modal>

            <div className="overflow-x-auto pb-4 flex flex-row">

                {
                    !loading && data !== undefined ?

                        data.services[0].products.map((e, id) => {
                            return <div key={id}>
                                <div className="relative left-2 top-6">

                                    {
                                        idArrayProducts.includes(e.id) ?
                                            <button
                                                type="button"
                                                onClick={() => removeProdCurrentOrder(e)}
                                                className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red-500 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                            >
                                                <XIconOutline className="h-4 w-4" aria-hidden="true" />
                                            </button>
                                            :

                                            <button
                                                type="button"
                                                onClick={() => addProdCurrentOrder(e)}
                                                className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                            >
                                                <PlusIconOutline className="h-4 w-4" aria-hidden="true" />
                                            </button>
                                    }

                                </div>

                                <div key={id}
                                    onClick={() => openModal(e)}
                                    className="card w-40 px-2 pt-2 pb-4 flex-col justify-center ml-4  bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl">
                                    <div className="w-32">
                                        <img src={e.images[0].url} alt={e.name} />
                                    </div>
                                    <div className="grid gap-10">
                                        <p className="text-lg font-medium">
                                            {e.price} <span className="text-xs">mxn</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-900 ">
                                            {
                                                e.name.length > 20 ? e.name.substring(0, 20) + " ..." : e.name
                                            }

                                        </p>
                                    </div>
                                </div>
                                {
                                    idArrayProducts.includes(e.id) ?
                                        <div className="bg-green-400  rounded-b-lg flex-col relative bottom-1.5 justify-center w-40 ml-4 ">
                                            <p className="text-white text-sm text-center">Producto añadido </p>
                                        </div>
                                        :

                                        null
                                }


                            </div>
                        })

                        :
                        <Loader />
                }
            </div>
        </>


    )
}


