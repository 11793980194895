import { Fragment , useState , useEffect, useContext} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Order from '../buyProcess/Order'
import DateTime from '../buyProcess/DateTime'
import ProductContext from '../../Context/Products/ProductsContext'
import DropChangeStatus from './DropChangeStatus'
import { useMutation } from '@apollo/client'
import { UPDATE_DATE_ORDER } from '../../GraphQL/Mutations'
import { GET_ORDERS_TODO } from '../../GraphQL/Queries'
import Alert from './Alert'



export default function ModalChangeStatus({isOpen, setIsOpen, order , type , newid }) {
  
    const [dateF, setDateF] = useState([])
    const [hour, setHour] = useState([])
   
    const { date , time } = useContext(ProductContext)
    const dates = () => {
        const heute = new Date()
        const week = [];

        for (var i = 0; i < 8; i++) {

            if (heute.getDay() !== 0) {
                week.push(
                    new Date(heute)
                )
            }

            heute.setDate(heute.getDate() + 1)
        }

        const weekday = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
        const month = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

        const formatDate = week.map((day, id) => {

            return {
                id: id,
                day: weekday[day.getDay()] + " " + day.getDate() + " " + month[day.getMonth()],
                online: true,
                dayData: day
            }
        })


        if (heute.getHours() + (heute.getMinutes() / 100) >= 17) {
            const late = formatDate.filter(e => e.id !== 0)
            setDateF(late)
        } else {
            setDateF(formatDate)
        }



    }

    const times = () => {

        const heute = new Date()

        const hours = [
            { id: 0, day: "9:30", online: true },
            { id: 1, day: "10:00", online: true },
            { id: 2, day: "10:30", online: true },
            { id: 3, day: "11:00", online: true },
            { id: 4, day: "11:30", online: true },
            { id: 5, day: "12:00", online: true },
            { id: 6, day: "12:30", online: true },
            { id: 7, day: "13:00", online: true },
            { id: 8, day: "13:30", online: true },
            { id: 9, day: "14:00", online: true },
            { id: 10, day: "14:30", online: true },
            { id: 11, day: "15:00", online: true },
            { id: 12, day: "15:30", online: true },
            { id: 13, day: "16:00", online: true },
            { id: 14, day: "16:30", online: true },
        ]

        const saturday = [
            { id: 0, day: "9:30", online: true },
            { id: 1, day: "10:00", online: true },
            { id: 2, day: "10:30", online: true },
            { id: 3, day: "11:00", online: true },
            { id: 4, day: "11:30", online: true },
            { id: 5, day: "12:00", online: true },

        ]


        if (date !== null) {

            if (heute.getDate() === date.dayData.getDate() && heute.getDay() !== 6) {

                if (heute.getHours() + (heute.getMinutes() / 100) >= 17) {
                    const filtered = hours.filter(element => {
                        return parseInt(element.day) > heute.getHours()
                    })
                    setHour(filtered)
                } else {
                    const filtered = hours.filter(element => {
                        return parseInt(element.day) > heute.getHours() + .5
                    })
                    setHour(filtered)
                }
            } else if (date.dayData.getDay() === 6) {

                if (heute.getHours() + (heute.getMinutes() / 100) >= 12) {
                    const filtered = saturday.filter(element => {
                        return parseInt(element.day) > heute.getHours()
                    })
                    setHour(filtered)
                } else {
                    const filtered = saturday.filter(element => {
                        return parseInt(element.day) > heute.getHours() + .5
                    })
                    setHour(filtered)
                }
            } else {
                setHour(hours)
            }
        }
        else {
            const filtered = hours.filter(element => {
                return parseInt(element.day) > heute.getHours()
            })
            setHour(filtered)
        }

    }
    const [alert,setAlert] = useState({open: false, error: "null",color:"red"})

    const [updateDateOrder, {loading}] = useMutation(UPDATE_DATE_ORDER, {
        onCompleted: () => success(),
        refetchQueries:[
            {query:GET_ORDERS_TODO},
            'getSerToDo'
        ]
    })

  
    const submitDate = ()=>{
        if (date === null && type === "service") {
            setAlert({
                error: "Ingresa una fecha a realizar",
                color:"red",
                open: true,
            })
        } else if (time === null && type === "service") {
            setAlert({
                error: "Ingresa un horario ",
                color:"red",
                open: true,         
            })
        } else{
            const dateTime = new Date(date.dayData.getFullYear(), date.dayData.getMonth(), date.dayData.getDate(), parseInt(time.day, 10), parseInt(time.day.substr(-2)))
            

            updateDateOrder({variables:{
                id:newid,
                when : dateTime
            }})
        }

       
    }

    const success = ()=>{
        setAlert({open: true, error: "Oferta actualizada con éxito", color:"green",})
        setTimeout(()=>{
            setIsOpen(false)
        }, 800)
    }

    

    useEffect(() => {
        dates()
        times()
    }, [date])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={isOpen}
            onClose={setIsOpen}>
            <div
                className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Dialog.Overlay
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-3/4 md:w-1/2">


{
    type === "details"&&<div>

<div className="mt-3 font-title text-center sm:mt-5 ">
    <Dialog.Title
        as="h3"
        className="text-lg  leading-6 font-medium text-left text-gray-900">
        Detalles de orden :
    </Dialog.Title>

<div className="text-left p-24">

{

    order !== undefined&&<Order order={order.cartshop.order} products={order.cartshop.products} />
}
</div>


</div>

</div>
}

{
    type === "date"&&<div>

<div className="mt-3 font-title text-center sm:mt-5 pb-24">
    <Dialog.Title
        as="h3"
        className="text-lg  leading-6 font-medium text-left text-gray-900">
        Actualizar orden :
    </Dialog.Title>

            <DateTime people={dateF} title={"Día de servicio"} type={"date"} />
            <DateTime people={hour} title={"Horario de servicio"} type={"time"} />


</div>

<Alert status={alert}></Alert>

{
        loading? 
        <button
                                                        type="button"
                                                        disabled
                                                        className="cursor-not-allowed px-4 py-3 border border-gray-300 shadow-sm w-full text-base font-medium rounded-md text-gray-700 bg-yellow-300 hover:bg-yellow-200 focus:outline-none ">
                                                        enviando ...
                                                    </button>
        :
        <button
onClick={submitDate}
                                                    type="button"
                                                    className=" px-4 py-3 my-6 border border-gray-300 shadow-sm w-full text-base font-medium rounded-md text-gray-700 bg-yellow-300 hover:bg-yellow-200 focus:outline-none ">
                                                    Actualizar fecha y hora
                                                </button>

    }
</div>
}

{
    type === "status"&&<div>

<DropChangeStatus order={order} setIsOpen={setIsOpen}></DropChangeStatus>

</div>
}
                        

                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>
)
}
