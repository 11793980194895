import React, { useContext, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { StarIcon } from '@heroicons/react/solid'
import { Tab } from '@headlessui/react'
import Carousel from '../microComponents/Carousel'
import { GET_PROJECTS_DONE } from '../../GraphQL/Queries'
import { useQuery } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import ReactMarkdown from 'react-markdown'
import ModalContext from '../../Context/Modal/ModalContext'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function ProjectOverview({ data, loading, setInteresado, interesado }) {

    const projects = useQuery(GET_PROJECTS_DONE, { variables: { name: data.services[0].name } })
    const [open, setOpen] = useState(false)
    const [reviewsExist, setReviewsExist] = useState(false)
    const [photos, setPhotos] = useState()
    const { interesade } = useContext(ModalContext)

    const [reviews, SetReviews] = useState({
        average: 0,
        featured: [
            {
                id: 1,
                stars: " ",
                client: " ",
                gallery: [{
                    url: ' '
                }],
                date: ' ',
                datetime: ' ',
                author: ' ',
            }
        ],
    })

    useEffect(() => {
        if (interesado && interesade) {
            setInteresado(interesade)
        } 
    }, [interesade])



    useEffect(() => {


        if (projects.data !== undefined) {
            if (projects.data.services[0].projects_dones.length !== 0) {
                const sum = projects.data.services[0].projects_dones.reduce((pstars, nstars) => (pstars.stars + nstars.stars) / projects.data.services[0].projects_dones.length)
                SetReviews({
                    average: sum,
                    featured: projects.data.services[0].projects_dones
                })
                setReviewsExist(true)
            }
        } else {
            SetReviews({
                average: 0,
                featured: [
                    {
                        id: 1,
                        stars: " ",
                        client: " ",
                        gallery: [{
                            url: ' '
                        }],
                        date: ' ',
                        datetime: ' ',
                        author: ' ',
                    }
                ],
            })
        }
    }, [projects.data])

    const [slider, setSlider] = useState(false)

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                setSlider(true)
            }, 200);
            return () => clearTimeout(timer)
        } else if (!open) {
            setSlider(false)
        }
    }, [open])

    const openProjectsDone = (fotos) => {
        setPhotos(fotos)
        setOpen(true)
    }

    return (
        <>
            <div className="bg-white">
                <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                    {/* Product */}
                    <div className="lg:grid lg:grid-rows-1 lg:grid-cols-9 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                        {/* Product image */}
                        <div className="lg:row-end-2 lg:col-span-4">


                            <Carousel data={data.services[0].gallery} />
                        </div>

                        {/* Product details */}
                        <div className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-3 lg:row-span-2 lg:col-span-4">
                            <div className="flex flex-col-reverse">
                                <div className="mt-4 font-title">
                                    <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{data.services[0].name}</h1>

                                    <h2 id="information-heading" className="sr-only">
                                        Información de : {data.services[0].name}
                                    </h2>

                                    <p className="text-sm text-black mt-2">
                                        <span className='bg-blue-100 py-1 px-4 rounded-r-xl'>
                                            helpy PLUS
                                        </span>
                                    </p>

                                    <p className="text-sm text-black mt-3">
                                        desde : {data.services[0].startingBasePriceInfo} <span className='text-xs'> mxn</span>
                                    </p>
                                </div>


                            </div>


                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                                <button
                                    onClick={() => setInteresado(true)}
                                    type="button"
                                    className="w-full bg-yellow-400 border border-transparent rounded-md py-3 px-2 flex items-center justify-center text-base font-medium text-black hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-yellow-500"
                                >
                                    Calcular precio
                                </button>
                                <a href="https://api.whatsapp.com/send?phone=5218115000527" rel='noreferrer' target="_blank">
                                <button
                                    type="button"
                                    className="w-full bg-yellow-50 border border-transparent rounded-md py-3 px-2 flex items-center justify-center text-base font-medium text-yellow-700 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-yellow-500"
                                    >
                                        Quiero asesoría
                                </button>
                                </a>
                            </div>
                            <div className="text-gray-500 font-body mt-6 text-sm">
                                <ReactMarkdown>{data.services[0].description}</ReactMarkdown>
                            </div>
                        </div>

                        <div className="w-full max-w-2xl mx-auto mt-16 lg:max-w-none lg:mt-0 lg:col-span-4">
                            <Tab.Group as="div">
                                <div className="border-b border-gray-200">
                                    <Tab.List className="-mb-px flex space-x-8">

                                        <Tab
                                            className={({ selected }) =>
                                                classNames(
                                                    selected
                                                        ? 'border-indigo-600 text-indigo-600'
                                                        : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                                                    'whitespace-nowrap py-6 border-b-2 font-medium text-sm'
                                                )
                                            }
                                        >
                                            Proyectos realizados
                                        </Tab>
                                        <Tab
                                            className={({ selected }) =>
                                                classNames(
                                                    selected
                                                        ? 'border-indigo-600 text-indigo-600'
                                                        : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                                                    'whitespace-nowrap py-6 border-b-2 font-medium text-sm'
                                                )
                                            }
                                        >
                                            Preguntas
                                        </Tab>

                                    </Tab.List>
                                </div>
                                <Tab.Panels as={Fragment}>
                                    <Tab.Panel className="-mb-10">
                                        <h3 className="sr-only">Customer Reviews</h3>

                                        {


                                            reviewsExist ?
                                            reviews.featured.map((review, reviewIdx) => (
                                                <div key={reviewIdx} className="lg:flex text-sm text-gray-500 space-x-4">
                                                    <div
                                                        onClick={() => openProjectsDone(review.gallery)}
                                                        className="flex-none pt-5 lg:py-10 cursor-pointer ">
                                                        <img src={review.gallery[0].url} alt="" className="w-40 h-40 bg-gray-100 ml-4" />
                                                    </div>
                                                    <div className={classNames(reviewIdx === 0 ? '' : 'border-t border-gray-200', 'py-2 lg:py-10')}>
                                                        <h3 className="font-medium text-gray-900">{review.client}</h3>
                                                        <p>
                                                            <time dateTime={review.datetime}>{review.date}</time>
                                                        </p>

                                                        <div className="flex items-center mt-1">
                                                            {[0, 1, 2, 3, 4].map((rating) => (
                                                                <StarIcon
                                                                    key={rating}
                                                                    className={classNames(
                                                                        review.stars > rating ? 'text-yellow-400' : 'text-gray-300',
                                                                        'h-5 w-5 flex-shrink-0'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                            ))}
                                                        </div>
                                                        <p className="sr-only">{review.stars} out of 5 stars</p>

                                                        <div className="mt-4 prose prose-sm max-w-none text-gray-500">
                                                            {review.review}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))

                                                :
                                                null

                                        }
                                    </Tab.Panel>

                                    <Tab.Panel as="dl" className="text-sm text-gray-500">
                                        <h3 className="sr-only">Frequently Asked Questions</h3>

                                        {data.services[0].faq.map((faq) => (
                                            <Fragment key={faq.question}>
                                                <dt className="mt-10 font-medium text-gray-900">{faq.question}</dt>
                                                <dd className="mt-2 prose prose-sm max-w-none text-gray-500">
                                                    <p>{faq.answer}</p>
                                                </dd>
                                            </Fragment>
                                        ))}
                                    </Tab.Panel>


                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                    <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="w-full grid grid-cols-1  items-start mt-10">
                                        <div className="sm:col-span-4 lg:col-span-5">
                                            <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                                                {
                                                    slider ? 

                                                        <Carousel data={photos}></Carousel>
                                                        :

                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
